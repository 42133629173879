.studentsAnswer {
  border: none;
  font-size: 1.2rem;
  border-bottom: 1px solid #596A75;
  padding-bottom: 20px;
  width: 100%;
  max-height: 450px;
  resize: none;
  font-weight: 300;
  &::placeholder {
    color: #596A75;
    font-weight: 300;
  }
  &.notline {
    border-bottom: none;
  }
}

.evaluationAnswer {
  font-size: 1.0625rem;
  color: #0B2541;
  padding: 0 0 20px 20px;
  border: 0;
  font-weight: 300;
  border-left: 1px solid #D3D9DE;
}
