.NewAssignment {
  .AssignmentTitle {
    border-bottom: 1px solid #d3d9de;
    background-color: #E7ECEF;

    .assignmentTitleWrapper {
      padding: 20px;
      span {
        text-transform: uppercase;
        color: #e2017b;
      }

      .newAssignmentTitleInput {
        padding: 10px 0 0 0;
        font-size: 2.25rem;
        border: none;
        resize: none;
        outline: none;
        background: #E7ECEF;
        font-weight: 500;
        height: 58px;

        &::placeholder {
          color: #939FA7;
        }
      }

      .newAssignmentDescriptionInput {
        margin-top: 20px;
        border: none;
        resize: none;
        outline: none;
        background: #E7ECEF;
        padding: 0;

        &::placeholder {
          color: #939FA7;
        }
      }

      .withPlusIcon {
        padding-left: 20px;
        background: url(../../../../assets/images/plus.svg) no-repeat 0 6px;
      }

      .assignmentIsNotUnique {
        color: #FF0000;
        text-transform: none;
      }
    }
  }
}
