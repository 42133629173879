.cover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;  
  align-items: center;
  justify-content: center;
  display: flex;
  &__content {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0 ,0, 0.1);
    width: 750px;
    padding: 50px 70px;
  }

  .mainImage {
    width: 600px;
    height: 45%;
    max-height: 326px;
    align-self: center;
  }
  .authorInfo {
    text-align: center;
    margin-bottom: 20px;
    img {
      display: block;
      margin: auto;
      width: 48px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    h4 {
      margin: 0px;
      font-size: 17px;
    }
  }
  .metaInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
      width: 20px;
    }
    p {
      margin: 0px;
      font-size: 13px;
      line-height: 17px;
    }
    &__deadline {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    &__steps {
      display: flex;
      align-items: center;
    }
  }

  .infoTeacher {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      margin-bottom: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    .subject {
      font-size: 0.9375rem;
      font-weight: 300;
    }
  }

  .assignmentInfo {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;

    .extraInfo {
      display: flex;
      align-items: flex-end;
      font-size: 0.8125rem;
      margin: 0 16px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  .assignmentTitle {
    font-size: 2.5rem;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    display: block;
  }

  .assignmentDescription {
    font-size: 1.0625rem;
    margin-bottom: 30px;
    display: flex;
    align-self: center;
    padding: 10px 20px;
    // word-break: break-word;
    text-align: center;
    font-weight: 300;
    justify-content: center;
  }

  .startButton {
    min-width: 200px;
    align-self: center;
    text-align: center;
    margin-bottom: 20px;

    button {
      font-weight: 500;
      min-width: 200px;
    }
  }
}
  
  @media (max-width: 870px) {
    .cover .assignmentTitle {
      font-size: 1.5rem;
    }
  }