@import "style";
.TeachingPathDistributeList {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    width: auto;
    overflow: auto;
    height: 100%;
    margin-top: 10px;
    justify-content: start;
    padding-top: 5px;
    transition: 0.3s;
    .TeachingPathDistributeItemSkeleton {
        width: 280px;
        margin-right: 10px;
        height: 350px;
    }
    .TeachingPathDistributeItem {
        width: calc(20% - 15px);
        min-height: 265px;
        margin-bottom: 20px;
        margin-right: 14px;
        .InfoCard {
            width: 100%;
            min-height: auto;
            height: 265px;
            button {
                height: 175px !important;
            }
            .cardInfo {
                min-height: 76px;
            }
        }
    }
}

@media screen and (max-width: $screen-dimension-tablet) {
    .TeachingPathDistributeList {
        .TeachingPathDistributeItem {
            width: calc(33.33% - 15px);
        }
    }
}
@media screen and (max-width: $screen-dimension-mobile) {
    .TeachingPathDistributeList {
        .TeachingPathDistributeItem {
            width: 100%;
        }
    }
}
