@import "style";

.InfoCard {
  width: 282px;
  height: 374px;
  min-height: 374px;
  box-sizing: content-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.100661);
  border-radius: 5px;
  transition: 0.5s;
  button {
    padding: 0;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: cover;    
    position: relative;
    overflow: hidden;
    &:focus {
      outline: auto;
      outline-style: auto;
      outline-offset: 2px;
    }
  }
  .toggleReadDescription {
    margin-top: 5px;
    .toggleRead {
      display: flex;
      align-items: center;
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 3px;
      }
      img {
        width: 18px;
        margin-right: 5px;
        transform: rotate(-90deg);
      }
      p {
        font-size: 13px;
        font-weight: 700;
        margin: 0px;
      }
    }
  }
  .actionButtons {
    position: absolute;
    top: -13px;
    left: 107px; // TODO: 110px after adding DnD
    z-index: 2;
    button {
      padding: 0px;
      background: transparent;
      &:focus {
        outline: black;
        outline-style: auto;
      }
    }
    img {
      width: 26px;
      height: 26px;
      cursor: pointer;

      &:first-child {
        margin-right: 5px;
        // TODO: Remove after adding DnD
        // opacity: 0;
        // cursor: default;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
    .actionButtonsItem {
      &.refreshButtonsItem {
        width: 36px;
        text-align: center;
        button {
          background: white;
          border: solid 1px #728089;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .cardImage {
    width: 100%;
    height: 185px;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
  }

  .cardInfo {
    height: auto;
    position: relative;
    padding: 20px;
    .cardTitle {
      flex: 1 1;
      min-width: 0;
      .noTitle {
        color: #939FA7;
      }

      .isDraft {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: #596A75;
        margin-left: 6px;
      }

      p {
        @include text-shrink-multiline(2);
        margin: 0;
        font-size: 1.05rem;
        font-weight: 500;
        margin-bottom: 5px;
        overflow: hidden;
      }

      .levelIcon{
        margin: 0;
      }
    }

    .withTooltip {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      position: relative;
      align-items: center;
      &.AbsolutePosition {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-top: 0px;
      }
      .OptionMsj {
        position: relative;
        background: transparent;
        width: auto;
        &:focus {
          outline: black;
          outline-style: auto;
        }
        /*&:before {
          position: absolute;
          box-shadow: 0 2px 2px #00000021;
          margin-top: 20px;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          border-radius: 4px;
          border: 1px solid #596a75;
          padding: 20px;
          cursor: default;
          background: #596a75;
          content: attr(data-msj);
          right: -2px;
          top: 18px;
          opacity: 0;
          pointer-events: none;
          z-index: 1;
          color: white;
          font-size: 1.0625rem;
        }
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #596a75;
          right: 0px;
          -webkit-transform: translate(-50%,-50%) rotate(-45deg);
          transform: translate(-50%,-50%) rotate(-45deg);
          background-color: #596a75;
          border-right: 1px solid #596a75;
          border-top: 1px solid #596a75;
          top: 38px;
          z-index: 1;
          opacity: 0;
          pointer-events: none;
        }*/
        &:hover {
          &:before, &:after {
            opacity: 1;
          }
        }
      }
      .defaultIcons {
        margin: 0;
        align-items: center;
      }

      img {
        height: 23px;
        width: 23px;
      }

      .tooltip {
        z-index: 2;
        right: -15px;
        position: absolute;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
        margin-top: 20px;
        top: 16px;
        width: max-content;

        .left {
          border-radius: 4px;
          border: 1px solid #939FA7;
          padding: 15px;
          cursor: default;
          z-index: 2;
          background: #fff;

          ul {
            li {
              cursor: pointer;
              margin: 7px;
              a {
                &:focus {
                  outline: black;
                  outline-style: auto;
                }
              }
            }

            .disabled {
              color: #939FA7;
              cursor: default;
            }
          }
        }

        i {
          position: absolute;
          top: -6px;
          right: 20px;
          margin-top: -12px;
          width: 12px;
          height: 24px;
          // overflow: hidden;
          transform: rotate(-90deg);

          &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            background-color: #fff;
            border-right: 1px solid #939fa7;
            border-bottom: 1px solid #939fa7;
          }
        }
      }
    }

    .withoutTooltip {
      display: flex;
      height: fit-content;
      margin-left: 10px;
      margin-top: 0;
    }

    .defaultIcons {
      height: 23px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 20px;
        height: 20px;
      }

      .tpIcon {
        width: 23px;
        height: 23px;
        margin-right: 5px;
      }
      .flexBox {
        align-items: center;
        &:focus-within {
          outline: black;
          outline-style: auto;
          outline-offset: 2px;
        }
      }
      span {
        font-size: 14px;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        max-width: 220px;
      }
      .levels {
        margin-right: 10px;
        font-size: 0.9375rem;
        align-items: center;
        width: max-content;

        img {
          margin: 0 7px 0 0;
        }
      }
    }

    .numberOfQuestions {
      font-size: 0.8rem;
      color: #E2017B;
      font-weight: bold;
    }

    .cardDescription {
      @include text-shrink-multiline(3);
      margin-top: 5px;
      font-size: 0.9375rem;
      font-weight: 300;
    }
    
    .ContentcardDescription {
      margin-top: 5px;
      .cardDescription {
        margin-top: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: 0.3s;
        &.active {
          max-height: 70px;
          margin-bottom: 15px;
        }
      }
      .toggleReadDescription {
        .toggleRead {
          display: inline-flex;
          align-items: center;
          &:focus {
            outline: auto;
            outline-style: auto;
            outline-offset: 2px;
          }
          img {
            width: 18px;
            margin-right: 5px;
            transform: rotate(-90deg);
          }
          &.active img {
            transform: rotate(90deg);
          }
          p {
            font-size: 13px;
            font-weight: 700;
            margin: 0px;
          }
        }
      }
    }
    .buttonRead {
      margin-top: 25px;
      .btn {
        background: rgb(11, 37, 65);
        border-radius: 20px;
        color: white;
        padding: 10px;
        max-width: 170px;
        &.btnRead {
          background: #0a7b24;
        }
        &:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px;
        }
      }
    }

    .grades {
      flex-wrap: wrap;
      margin-top: 4px;

      & > div {
        max-width: 31%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px;
        margin: 0 5px;
        background: #D3D9DE;
        border-radius: 4px;
        font-size: 0.8125rem;
        margin-left: 0;
        font-weight: 300;
      }
    }
    .gradesLineInside div:last-child:after {
        display: none;
    }
    .gradesLineInside div:after {
        content: ', ';
        margin-right: 5px;
    }
    .gradesLineInside {
        align-items: center;
        margin-right: 5px;
    }
    .gradesLine p {
        margin: 0px;
    }
    .gradesLine {
        align-items: center;
        font-size: 14px;
    }
    .footerCardInfo {
      justify-content: space-between;
      align-items: center;
    }
  }
}
.tpIcon-DOMAIN {
  cursor: pointer;
}

@media screen and (max-width: $screen-dimension-desktop) {
  .InfoCard {
    .cardInfo {
      .withTooltip {
        .tooltip {
          .left {
            background: $color-text-primary;
            border-color: $color-text-primary;

            ul {
              li {
                color: $color-white;
              }
            }
          }

          i {
            &::after {
              background-color: $color-text-primary;
              border-color: $color-text-primary;
            }
          }
        }
      }
    }
  }
}
