.previewModalInfoSearch {
    position: fixed;
    top: 78px;
    width: 100%;
    height: calc(100% - 78px);
    z-index: 20;
    left: 0;
    &.hidden {
        display: none;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(54, 57, 71, 0.3);
        z-index: 1;
    }
    &__content {
        position: absolute;
        height: 100%;
        width: 780px;
        background: white;
        right: 0;
        z-index: 2;
        .headerButtons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 12px;
            .previewButtons {
                position: relative;
                margin-left: 15px;
            }
            .functionsButtons {
                position: relative;
                margin-left: 15px;
            }
            .DistributeButtons {
                margin-left: 15px;
            }
            .modalContent {
                border: 1px solid #D3D9DE;
                border-top: 0;
                border-radius: 5px;
                box-shadow: 0 2px 4px 0 #ffffff1c;
                position: absolute;
                z-index: 2;
                background: white;
                padding: 12px 20px;
                right: 0px;
                padding-top: 17px;
                min-width: 220px;
                button {
                    background: transparent;
                    display: block;
                    margin-bottom: 5px;
                    text-align: left;
                    white-space: nowrap;
                }
                .actionboldButton {
                    button {
                        font-size: 17px;
                        font-weight: 500;
                        display: block !important;
                        position: relative;
                        margin-top: 8px;
                        margin-bottom: 8px !important;
                        width: 100% !important;
                        padding: 0px;
                    }
                    &.actionAfter {
                        button {
                            &:after {
                                content: '';
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-bottom: solid 2px #0b2541;
                                border-right: solid 2px #0b2541;
                                transform: rotate(-45deg);
                                top: 5px;
                                right: 3px;
                            }
                        }
                    }
                }
                .linkOpenSite {
                    display: block;
                    position: relative;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-bottom: solid 2px #0b2541;
                        border-right: solid 2px #0b2541;
                        transform: rotate(-45deg);
                        top: 5px;
                        right: 0px;
                    }
                    &.LinkRollback {
                       &:after { display: none; }
                    }
                }
                .modalContentInside {
                    position: absolute;
                    top: 0;
                    border: 1px solid #D3D9DE;
                    border-top: 0;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px 0 #ffffff1c;
                    background: white;
                    padding: 12px 20px;
                    width: 100%;
                    left: 0;
                    min-height: 100%;
                    pointer-events: none;
                    opacity: 0;
                    transition: 0.3s;
                    z-index: 2;
                    &.active {
                        pointer-events: all;
                        opacity: 1;
                    }
                    h2 {
                        display: block;
                        margin: 0px;
                        font-size: 17px;
                        border-bottom: solid 1px #0000001c;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        a {
                            display: block;
                            width: 100%;
                            position: relative;
                            padding-left: 20px;
                            font-size: 17px;
                            &:before {
                                content: '';
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                border-bottom: solid 2px #0b2541;
                                border-left: solid 2px #0b2541;
                                transform: rotate(45deg);
                                top: 5px;
                                left: 0px;
                            }
                        }
                    }
                    ul {
                        li {
                            margin-bottom: 10px;
                            margin-top: 10px;
                            a {
                                display: block;
                                position: relative;
                            }
                            &.itemListFlex {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                p {
                                    margin: 0px;
                                }
                                div {
                                    display: flex;
                                    svg {
                                        display: block;
                                        border: solid 1px #979797;
                                        width: 26px;
                                        height: 26px;
                                        border-radius: 50%;
                                        padding: 5px;
                                        path {
                                            fill: #061626;
                                        }
                                    }
                                    a {
                                        margin-left: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .modalToggle {
                border: solid 2px #d3d9de;
                border-radius: 20px;
                padding: 7px 16px;
                display: block;
                min-width: 100px;
                text-align: center;
                color: #0b2541;
                &.active {
                    border: solid 2px #0b2541;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-left: solid 2px #0b2541;
                    border-bottom: solid 2px #0b2541;
                    transform: rotate(-45deg);
                    margin-left: 8px;
                    top: -3px;
                    position: relative;
                }
            }
        }
        .NewTitleHeader {
            background: #0b2541;
            padding-top: 26px;
            padding-bottom: 27px;
            h2 {
                margin: 0px;
                text-align: center;
                color: white;
                font-size: 36px;
                font-weight: 400;
            }
        }
        .entityInfo {
            height: 67px;
            border-bottom: 1px solid #D3D9DE;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .partsInfo {
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                font-size: 13px;
                align-items: center;
                min-width: 90px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 350px;
                &.partsInfoAutor {
                    p {
                        white-space: nowrap;
                        max-width: 180px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                &.partsInfoList {
                    ul {
                        display: flex;
                        li {
                            margin-right: 5px;
                            &:after {
                                content: ',';
                            }
                            &:last-child {
                                margin-right: 0;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                img {
                    margin-right: 10px;
                    max-width: 22px;
                }
            }
        }
        .entityDescription {
            /* height: 67px; */
            border-bottom: 1px solid #D3D9DE;
            padding: 20px;
            display: flex;
            align-items: left;
            justify-content: left;
            height: auto !important;
            .partsInfoDescription {
                margin-left: 15px;
                margin-right: 15px;
                display: flex;
                font-size: 17px;
            }
        }
        .summary {
            display: block;
            padding: 20px;
            background: white;
            overflow: auto;
            height: calc(100vh - 385px) !important;
            .entityInfoBlock {
                display: -webkit-inline-box;
                max-width: 46%;
                min-width: 46%;
                vertical-align: top;
                padding: 10px 0px;
                margin: 10px;
                .imageGrep {
                    margin-right: 10px;
                    .imgInfo {
                        width: 21px;
                        height: 21px;
                    }
                }
                .title {
                    color: #0b2541;
                    font-size: 16px;
                    font-weight: 500;
                    height: 20px;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    width: 100%;
                }
                .listItem .item {
                    margin: 0;
                    padding: 0;
                    text-decoration: none;
                    display: inline-block;
                    background-color: #d3d9de;
                    border-radius: 4px;
                    color: #0b2541;
                    font-size: 13px;
                    font-weight: 300;
                    padding: 4px;
                    margin: 5px 5px 5px 0px;
                }
            }
            .entityInfoBlockExpanded {
                display: -webkit-inline-box;
                max-width: 46%;
                min-width: 46%;
                vertical-align: top;
                padding: 10px 0px;
                margin: 10px;
                .imageGrep {
                    margin-right: 10px;
                    .imgInfo {
                        width: 21px;
                        height: 21px;
                    }
                }
                .title {
                    color: #0b2541;
                    font-size: 16px;
                    font-weight: 500;
                    height: 20px;
                    /* width: 56px; */
                    white-space: nowrap;
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            .flexContainerExpanded {
                .listItem {
                    margin: 0px 44px 0px 44px;
                    .goalData {
                        display: flex;
                        font-size: 16px;
                        border-bottom: 1px solid rgba(0,0,0,.0901960784);
                        padding: 10px 0;
                        color: #0a003d;
                        font-weight: 300;
                        &__grade {
                            margin-right: 20px;
                            min-width: 110px;
                            text-decoration: none;
                        }
                        &__subject {
                            margin-right: 20px;
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1001px) {
    .previewModalInfoSearch {
        &__content {
            width: calc(100% - 50px);
            .headerButtons {
                flex-wrap: wrap;
                .modalToggle {
                    min-width: 90px;
                    padding: 7px 10px;
                }
            }
            .NewTitleHeader {
                padding-top: 16px;
                padding-bottom: 17px;
                h2 {
                    font-size: 25px;
                }
            }
            .entityInfo {
                flex-wrap: wrap;
                height: auto;
                padding: 10px 0px;
                padding-top: 5px;
                justify-content: flex-start;
            }
            .entityDescription {
                padding: 10px 0px;
            }
            .summary {
                padding: 10px;
                .entityInfoBlock {
                    max-width: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0px;
                    overflow: hidden;
                    .title {
                        max-width: calc(100% - 44px);
                    }
                }
                .entityInfoBlockExpanded {
                    width: 100%;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 0px;
                    .title {
                        max-width: calc(100% - 44px);
                    }
                }
                .listItem {
                    min-height: auto !important;
                    max-width: calc(100% - 44px);
                }
                .flexContainerExpanded {
                    .listItem {
                        margin-left: 44px;
                        margin-right: 0px;
                        .goalData {
                            display: block;
                            .goalData__grade {
                                font-weight: 700;
                                margin-bottom: 5px;
                                margin-right: 0px;
                            }
                            .goalData__subject {
                                margin-right: 0px;
                                margin-bottom: 5px;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    } 
}