.numberAndActions {
  width: 100px;
  cursor: default;
  margin-bottom: -3px;
  z-index: 3;
  min-height: 31px;

  &.firstNumber {
    margin-bottom: 20px;
  }
  button {
    padding: 0px;
    &:focus {
      outline: black;
      outline-style: auto;
    }
  }

  .nestedOrderNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    min-height: 31px;
    background: #E2017B;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.0625rem;
    margin: 0 10px;
  }

  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
