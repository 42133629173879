.breadcrumb {
  display: flex;
  border-top: 1px solid #E7ECEF;
  padding: 25px 20px;
  overflow: auto;
  width: 100%;
  margin-right: 20px;
  height: 72px;
  background: #FFF;
  &.blocktype {
    pointer-events: none;
  }
  .breadcrumbs {
    display: flex;
    font-size: 0.9375rem;
    font-weight: 300;
    width: 100%;

    li {
      cursor: pointer;
      white-space: nowrap;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .activeStyle {
      font-weight: 500;
    }
  }

  .breadcrumbs > li + li:before {
    color: #cccccc;
    margin: 0 5px;
    content: url(../../../assets/images/arrow-right.svg);
    padding: 0 5px;
  }
}

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .breadcrumb {
    position: fixed;
    bottom: 0;
  }
}
