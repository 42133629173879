@import "../../../../style/index";

.Submit__title {
  margin-bottom: 32px;
  font-size: 2.25rem;
}

.Submit__delete {
  display: inline-block;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.Submit__button {
  display: flex;
  align-items: center;
  padding: 7.5px 27px 7.5px 9px;
  margin-top: 32px;
  color: $color-white;
  background: $color-green;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.0625rem;
}

.Submit__description {
  font-size: 1.0625rem;
  font-weight: 300;
}

.Submit__image {
  margin-right: 15px;
}

.Submit__defaultCursor {
  cursor: default;
}
.donwloadButton button img {
  width: 16px;
  margin-left: 5px;
}
.donwloadButton button {
  display: inline-flex;
  background: transparent;
  font-weight: 700;
  align-items: center;
  &.downloading {
    cursor: progress;

    img {
        visibility: hidden;
    }
  }
}
.donwloadButton {
  text-align: right;
  margin-top: 40px;
}
@media screen and (max-width: $screen-dimension-mobile-425) {
  .Submit__title {
    font-size: 1.5rem;
    margin-top: 60px;
    margin-bottom: 7px;
  }

  .Submit__button {
    margin-top: 60px;
    font-size: 0.875rem;
    padding-right: 25px;
  }
}
