@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
@import "../../style";

.test {
  border: 1px solid red;
}

* {
  outline: none;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #0b2541;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

img {
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
}

.z10 {
  z-index: 10;
}

select {
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 0 10px;
  appearance: none;

  & option {
    width: 100%;
  }
}

textarea {
  outline: none;
  font-size: inherit;

  &::placeholder {
    color: #939FA7;
  }
}

input, select, option {
  outline: none;
}

.flexBox {
  display: flex;
}

.spaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.dirColumn {
  flex-direction: column;
}

.dirRow {
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.h100 {
  height: 100%;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}

.roboto {
  font-family: 'Roboto Slab', serif;
}

.tc1 {
  color: $color-text-primary;
}

.tc2 {
  color: $color-text-secondary;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 500;
}

.fw500 {
  font-weight: 500;
}

.fw500 {
  font-weight: 500;
}

.fs12 {
  font-size: 0.75rem;
}

.fs15 {
  font-size: 0.9375rem;
}

.fs17 {
  font-size: 1.0625rem;
}

.fs22 {
  font-size: 1.375rem;
}

.fs36 {
  font-size: 2.25rem;
}

.mr20 {
  margin-right: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.ffRoboto400 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
}

.relative {
  position: relative;
}

.cursorPointer {
  cursor: pointer;
}

.w100 {
  width: 100%;
}
.upp {
  text-transform: uppercase;
}

.lightItem {
  background-color: white !important;
}

.fs13 {
  font-size: 0.8125rem;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  font-size: 1.0625rem;  
  &.zoom100 {
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
  }
  &.zoom150 {
    zoom: 1.1;
    -moz-transform: scale(1.1);
    -moz-transform-origin: 0 0;
  }
  &.zoom200 {
    zoom: 1.2;
    -moz-transform: scale(1.2);
    -moz-transform-origin: 0 0;
  }
}

.App__sidebar {
  flex-shrink: 0;
}

.App__view {
  height: calc(100% - #{$header-height});
  display: flex;
  &.isFirstTimeVisiting {
    height: calc(100% - 211px);
    .fixedsModal .FiltersModal {
      top: 211px;
      height: calc(100% - 211px);
    }
  }
}

.App__content {
  padding: 19px;
  background: #E7ECEF;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #d3d9de;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #adb9c2;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.App__notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  width: auto;
  top: 90px;
  right: 20px;
  z-index: 100;
}

.isMaintenance .ConfirmationPage_container {
  top: 105px;
}
.isMaintenance .StudentOverview__content {
  margin-top: 40px;
  height: calc(100% - 100px);
}
.isMaintenance .teachingPathsList .dark .evaluationInfo {
  height: calc(100% - 180px);
  top: 180px;
}

.isMaintenance .teachingPathsList .dark {
  top: 115px;
  height: calc(100% - 115px);
}
.isMaintenance .assignmentList .darkStudentList {
  top: 105px;
  height: calc(100% - 105px);
}
.isMaintenance .assignmentList .darkStudentList .evaluationInfo {
  top: 180px;
  height: calc(100% - 180px);
}
.isMaintenance .Submitted {
  top: 105px;
}
.isMaintenance .loading {
  top: 105px;
  height: calc(100vh - 105px);
}

@media screen and (max-height: $screen-dimension-desktop) {
  ::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .App__content {
    background: #E7ECEF;
    transition: margin-left 200ms ease-in-out, margin-right 200ms ease-in-out;
  }

  .App__content_shifted {
    margin-left: $sidebar-width;
    margin-right: $sidebar-width * -1;
  }
}

@media screen and (max-width: $screen-dimension-desktop) and (min-width: $screen-dimension-tablet + 1px) {
  .App__content {
    margin-left: $sidebar-width-tablet;
  }

  .App__content_shifted {
    margin-left: $sidebar-width;
    margin-right: $sidebar-width * -1;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .App__content {
    padding-top: 0;
  }
}

@media screen and (max-width: $screen-dimension-mobile-425) {
  .App__notifications {
    left: 20px;
  }
}

.fw300 {
  font-weight: 300;
}

.fw500 {
  font-weight: 500;
}

.fw500 {
  font-weight: 500;
}

.fs15 {
  font-size: 0.9375rem;
}

.fs17 {
  font-size: 1.0625rem;
}

.fs24 {
  font-size: 1.5rem;
}

.fs30 {
  font-size: 1.875rem;
}

.fs36 {
  font-size: 2.25rem;
}

.fs60 {
  font-size: 3.75rem;
}

.disabledArea {
  pointer-events: none;
  opacity: 0.5;
}

.overHidden {
  overflow: hidden;
}

.hidden {
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}
.AppHeader__submenuWrapper:focus-within {
  opacity: 1;
  pointer-events: all;
}
.generalTitle {
  font-size: 1.625rem;
  margin: 0px;
  margin-bottom: 20px;
}
@media screen and (max-width: 574px) {
  .generalTitle {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
}
//focus event
.AppHeader__block a:focus {
  outline: solid;
}
.MyAccountWindow__itemText:focus {
  text-decoration: underline;
}
.AppHeader__navigationItem a:focus {
  border-bottom: solid 1px black;
  display: block;
}
.SliderWidget__button:focus {
  outline: solid;
  outline-offset: 5px;
  outline-width: thin;
  outline-color: black;
  outline-style: auto;
}
.SliderWidget__switchButton:focus {
  outline: solid;
}
.Sidebar__listItem:focus {
  background: #f1f1f1;
  border-bottom: solid 2px black;
}
.TabNavigation ul li .sidebarLink:focus {
  outline: solid;
}
.CreateButton:focus {
  outline: solid;
  outline-color: black;
  outline-style: auto;
  outline-offset: 2px;
}
.SearchFilter__select:focus {
  outline: solid;
  outline-offset: 5px;
  outline-style: auto;
}
.ListWidget__text:focus {
  outline: solid;
}
.MyList a:focus img, .AssignmentDistributeList a:focus img, .StudentListItem:focus img, .StudentsList__list a:focus img, .MyList a.StudentAssignmentsListItem:focus {
  outline: solid;
  outline-color: white;
  outline-width: thin;
}
.MyList a:focus li.AssignmentListItem, .AssignmentDistributeList a:focus li, .StudentListItem:focus , .AssignmentDistributeList a:focus div, .StudentsList__list a:focus li, .MyList a.StudentAssignmentsListItem:focus {
  background: #e4e4e4 !important;
}
.SubmitTeachingPath__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px;
}
.Submitted__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px;
}
.AnswerCover__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px;
}
.AppHeader__dropdown:focus ~ .AppHeader__submenuWrapper {
  opacity: 1;
  pointer-events: all;
}
.passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 2px;
}
.AppHeader__dropdownItem__subMenu:focus-within {
  opacity: 1;
  pointer-events: all;
}
.addItemModal button:focus {
  outline: black !important;
  outline-style: auto !important;
  outline-offset: -4px;
}
.AssignmentsList .assignmentsListHeader button {
  background: transparent;
}
.AssignmentsList .assignmentsListHeader button:focus {
  outline: black !important;
  outline-style: auto !important;
  outline-offset: -4px;
}
.AssignmentsList .assignmentsListContainer .assignmentItem:focus {
  background: #d8d8d8;
  outline: auto !important;
  outline-offset: -1px;
}
.RelatedArticlesPreview .title button:focus {
  outline: auto;
  outline-style: auto !important;
  outline-offset: 2px;
}
.extra .extra-button:focus {
  outline: auto !important;
  outline-offset: 2px;
}
.AssignmentAnswer:focus {
  background: #d4d4d4;
}
.checking .controlPanel .save:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 2px;
}
.checking .mainContainer .comments .markItem:focus {
  outline: black;
  outline-style: auto;
}
.StudentAssignments__search:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 4px;
}
.StudentAssignments__select:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 4px;
}

.maintenance .maintenance__close img {
  border: solid 2px black;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  filter: brightness(0)invert(1);
  margin-right: 5px;
}
.maintenance .maintenance__close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}
.maintenance .maintenance__content {
  max-width: 450px;
  margin: auto;
  color: white;
  font-weight: 400;
}
.maintenance p {
  margin: 0px;
  color: white;
}
.maintenance {
  background: #e2017b;
  text-align: center;
  padding-top: 15px;
  position: relative;
  z-index: 2;
}
.App.isMaintenance {
  height: auto;
}
.css-2b097c-container:focus-within .css-1vlg5zl {
  outline: black;
  outline-style: auto;
  outline-offset: 4px;
}
.css-2b097c-container:focus-within .css-1dije2o-Control {
  outline: black;
  outline-style: auto;
  outline-offset: 4px;
}

.minimalLoading {
  display: flex;
  span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #898989;
    margin-right: 5px;
    animation: clouds 1.1s infinite ease-in-out;
    &:nth-child(2) {
      animation-delay: 0.16s;
    }
    &:nth-child(3) {
      animation-delay: 0.32s;
    }
  }
}

@keyframes clouds {
	0%,
	80%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

.messageContainer {
  width: 100%;
  background-color: #065f1b;
  display: flex;
  text-align: left;
  opacity: 0.95;
  padding: 18px;
  justify-content: center;
  z-index: 17;
}

.titleMessage {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.paragraphMessage {
  color: rgb(255, 255, 255);
  font-size: 15px;
  line-height: 20px;
  font-weight: 200;
}

.message .message__close img {
  border: solid 2px black;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  filter: brightness(0)invert(1);
  margin-right: 5px;
}
.message .message__close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  background: transparent;
  &:focus {
    outline: black;
    outline-style: auto;
    outline-offset: 2px;
  }
}

.message p {
  margin: 0px;
  color: white;
  min-width: 104px;
}
.message {
  background: none;
  text-align: center;
  padding-top: 15px;
  position: relative;
  z-index: 2;
  padding-left: 20px;
}

.closeText {
  min-width: 90px;
  font-size: 15px;
  font-weight: 200;
}

.margined {
  max-width: 600px;
  margin-right: 10%;
}

.linkElement {
  color: white;
  text-decoration: underline;
}