@import "style";

.teachingPathsList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .gradesChildrenItems {
    margin-top: 15px;
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;    
    flex-flow: row wrap;
    width: auto;
    overflow: auto;
    margin-top: 10px;
    justify-content: start;
    padding-top: 5px;
    transition: 0.3s;
    padding-left: 5px;
    .InfoCard {
      width: calc(100% / 7 - 30px);
      margin-bottom: 20px;
      margin-right: 30px;
      height: auto;
      min-height: auto;
      &:hover {
        box-shadow: 0 8px 10px #00000061;
      }
      .cardTitle {
        padding-right: 28px;
      }
      .cardDescription {
        @include text-shrink-multiline(3);
        margin-top: 10px;
      }
      .grades {
        height: 25px;
      }
    }
  }
  .noResults.emptyTeachingPaths {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
  }
  &.moveListBySearchFilter {
    transition: 0.3s;
    &.active {
      width: calc(100% - 290px);
      margin-left: 290px;
      .InfoCard {
        width: calc(100%/ 7 - 10px);
        margin-right: 10px;
      }
    }
  }
}

.css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  height: 300px;
  box-shadow: 0px 3px 4px #00000033;
}
.css-1y8mk30-SkeletonTheme {
  width: calc(25% - 20px);
  height: 300px;
  margin-right: 20px;
  span {
    width: 100% !important;
    height: 250px !important;
  }
}

.AssignmentsPage .assignmentList .css-1y8mk30-SkeletonTheme {
  width: 100%;
  height: 70px;
}
.AssignmentsPage .assignmentList .css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  height: 60px !important;
}


@media screen and (max-width: 2350px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 7 - 10px);
    margin-right: 10px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 6 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 2150px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 6 - 20px);
    margin-right: 20px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 5 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 2000px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 6 - 10px);
    margin-right: 10px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 5 - 10px);
    margin-right: 10px;
  }
}

@media screen and (max-width: 1800px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 5 - 20px);
    margin-right: 20px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 4 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 1600px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 4 - 10px);
    margin-right: 10px;
  }
}

@media screen and (max-width: 1500px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 4 - 10px);
    margin-right: 10px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 2 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 2 - 10px);
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .teachingPathsList.moveListBySearchFilter.active {
    width: 100%;
    margin-left: 0px;
  }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 720px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 2 - 20px);
    margin-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 2 - 10px);
    margin-right: 10px;
  }
}


@media screen and (max-width: 575px) {
  .teachingPathsList {
    height: auto;
    .cardList {
      flex-flow: nowrap;
      width: calc(100% + 19px);
      .InfoCard {
        width: calc(100% - 50px);
        min-height: auto;
        flex: none;
        margin-right: 10px;
        margin-bottom: 5px;
        height: auto;
        .cardImage {
          height: auto;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
  } 
  .teachingPathsList.moveListBySearchFilter.active .cardList .InfoCard {
    width: calc(100% - 50px);
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  /*.teachingPathsList .cardList .InfoCard {
    width: calc(50% - 15px);
  }
  .teachingPathsList.moveListBySearchFilter.active .cardList .InfoCard {
    width: calc(50% - 15px);
  }*/
}