.DistributionTitle {
  background-color: #ffffff;
  border-bottom: 1px solid #d3d9de;
  padding: 16px 19px;
    
  span {
    text-transform: uppercase;
    color: #e2017b;
  }

  .distributeTitle {
    padding-top: 10px;
    font-size: 2.25rem;
    font-weight: 500;
  }

  .distributeDescription {
    margin-top: 20px;
  }
}
