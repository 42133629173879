@import "style";

.MyList {
  margin-top: 20px;

  .listItemWrapper {

    .listItem {
      padding-right: 20px;

      .statBlock .questionsStudent {
        font-size: 0.8125rem;
      }
    }

    .assignmentTitle {
      margin-top: 0;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subjects {
      margin-left: 20px;

      .subject {
        background: #E7ECEF;
        height: 31px;
        border-radius: 17px;
        padding: 7px 16px 8px 17px;
        font-weight: 500;
        margin-right: 4px;
        border: 1px solid #D3D9DE;
        font-size: 0.8125rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .questions {
      font-size: 1rem;
      text-align: right;
      color: #E2017B;
      margin-right: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .passedDeadline {
      color: #E2017B;
    }

    .deadline {
      display: flex;
      font-size: 0.8125rem;
      align-items: center;
      margin-right: 20px;

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
    }

    .evaluated-status {
      display: flex;
      align-items: center;
      font-size: 0.8125rem;
      color: #939FA7;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      img {
        width: 20px;
        height: 20px;
        margin: 0 20px 0 10px;
      }

      .passed {
        color: #0A7B24;
      }

      .notPassed {
        color: #E2017B;
      }
    }
    .evaluated-true {
      color: #2D9A46;
    }
    .evaluated-false {
      color: #E2017B;
    }

    .answer-status {
      background: #D3D9DE;
      height: 31px;
      border: 1px solid #596A75;
      border-radius: 17px;
      padding: 7px 16px 7px 17px;
      margin-right: 4px;
      color: #0B2541;
      justify-content: center;
      font-size: 0.8125rem;
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }
    }

    .answered {
      background: #0A7B24;
      border: 1px solid #065F1B;
      color: #ffffff;
    }
  }
}

.darkStudentList {
  position: absolute;
  top: $header-height;
  left: 0;
  width: calc(100% - #{$sidebar-width});
  height: calc(100% - #{$header-height});
  z-index: 15;
  background: rgba(54, 57, 71, 0.3);
}

@media screen and (max-width: 1119px) {
  .MyList .listItemWrapper {
    margin-bottom: 60px;
    background: #fff;
    .deadline, .evaluated-status {
      margin-right: 0;
      font-size: 0.8125rem;
    }

  }
  .MyList .listItemWrapper .studentAssignments {
    justify-content: center;
    min-height: 265px;
  }

  //.listImage {
  //  width: 90px;
  //  height: 90px;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //}
  .mob-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .mob-info {
    position: relative;
    padding-left: 120px;
  }
  .mob-about {
    min-height: 90px;
  }
  .MyList .listItemWrapper .subjects {
    margin-left: 0;
    border-top: 1px solid #D3D9DE;
    margin-top: 15px;
    padding-top: 15px;
  }

  .MyList .listItemWrapper .answer-status {
    justify-content: center;
    margin-top: 15px;
    padding: 7px 0;

  }

  .subject {
    padding: 10px !important;
    border-radius: 20px;
  }

  .stats {
    display: flex;
    border-top: 1px solid #D3D3D3;
    margin-top: 10px;
  }

  .stats>* {
    margin-top: 20px;
  }

}
