@import "../../../../style/index";

.AnswerCover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;  
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
    top: 0;
    left: 0;
  &__content {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0 ,0, 0.1);
    width: 750px;
    padding: 50px 70px;
  }
  .authorInfo {
    text-align: center;
    margin-bottom: 20px;
    img {
      display: block;
      margin: auto;
      width: 48px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    h4 {
      margin: 0px;
      font-size: 17px;
    }
  }
  .metaInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
      width: 20px;
    }
    p {
      margin: 0px;
      font-size: 13px;
      line-height: 17px;
    }
    &__deadline {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    &__steps {
      display: flex;
      align-items: center;
    }
  }
}

.AnswerCover__imageWrapper {
  align-self: stretch;
  padding-top: 67%;
  position: relative;
  margin-bottom: 32px;
}

.AnswerCover__image {
  @include absolute-attach(none);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: -1;
}

.AnswerCover__teacherInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.AnswerCover__teacherLogo {
  @include circle(48px);
  background: $color-gray;
  margin-bottom: 10px;
}

.AnswerCover__teacherName {
  text-align: center;
  font-size: 1.0625rem;
  font-weight: 500;
}

.AnswerCover__subject {
  letter-spacing: -0.01em;
  font-size: 0.9375rem;
  font-weight: 300;
}

.AnswerCover__assignmentInfo {
  display: flex;
  margin-bottom: 20px;
}

.AnswerCover__blockText {
  margin-top: 2px;
  font-size: 0.8125rem;
  font-weight: 500;
}

.AnswerCover__infoBlock {
  display: flex;
  align-items: center;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
}

.AnswerCover__blockImage {
  margin-right: 10px;
}

.AnswerCover__title {
  font-size: 2.5rem;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  margin-top: 20px;
  display: block;
}

.AnswerCover__description {
  font-size: 1.0625rem;
  margin-bottom: 30px;
  text-align: center;
  display: block;
  font-weight: 300;
}
.AnswerCover__div_button {
  text-align: center;
}
.AnswerCover__button {
  background: $color-text-primary;
  color: $color-light-gray;
  font-weight: 500;
  font-size: 1.0625rem;
  padding: 9px 23px;
  border-radius: 20px;
}

.AnswerCover__questionCount {
  margin-top: 20px;
  margin-bottom: 20px;
}

.AnswerCover__questionCount_light {
  font-weight: 300;
}

@media screen and (max-width: $screen-dimension-desktop) and (min-width: $screen-dimension-tablet + 1px),
        screen and (device-width: 810px) and (device-height: 1080px) and (orientation:landscape) {
  .AnswerCover__imageWrapper {
    padding-top: 38%;
  }
}

@media screen and (max-width: $screen-dimension-mobile-425) {
  .AnswerCover__image {
    border-radius: 0;
  }

  .AnswerCover__imageWrapper {
    margin-bottom: 0;
    margin-right: -15px;
    margin-left: -15px;
  }

  .AnswerCover__teacherInfo {
    margin-top: -36px;
  }

  .AnswerCover__teacherLogo {
    @include circle(75px);
    border: 3px solid $color-white;
    margin-bottom: 15px;
  }

  .AnswerCover__assignmentInfo {
    align-self: stretch;
    margin-bottom: 24px;
  }

  .AnswerCover__infoBlock {
    margin-right: 0;
    padding-top: 13px;
    padding-bottom: 12px;
    border-top: 1px solid rgba($color-black, 0.0605878);

    &:last-child {
      border-bottom: 1px solid rgba($color-black, 0.0605878);
    }
  }

  .AnswerCover__title {
    font-size: 1.5rem;
  }

  .AnswerCover__assignmentInfo {
    flex-direction: column;
  }
}
