.attachments-list-container {
  flex: 1;
  width: 100%;
  background-color: white;
  z-index: 1;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .message {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.375rem;
    font-weight: 500;
  }

  .attachments-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;

    .attachments-tab:first-child {
      margin-right: 30px;
    }
    button {
      background: transparent;
      &:focus {
        outline: auto !important;
        padding: 0px;
      }
    }

    .wrapper-tabs {
      display: flex;
      padding-top: 20px;

      .attachments-tab {
        cursor: pointer;
        padding-bottom: 16px;
        border-bottom: solid #ffffff 4px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.9375rem;

        &:hover, &.selected {
          border-bottom: solid #e2017b 4px;
        }

        &.disabled {
          cursor: not-allowed;

          span {
            color: #596A75;
          }

          &:hover {
            border-bottom: none;
          }
        }

        span {
          font-size: 0.9375rem;
          font-weight: 500;
        }
      }
    }

    img {
      width: 28px;
      cursor: pointer;
    }
  }

  .contentWrapper {
    display: flex;
    background: #E7ECEF;
    flex-direction: column;
    height: calc(100% - 130px);
    padding: 20px;
    border-top: 1px solid #D3D9DE;

    .search-field-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px 18px;
      border: solid #596A75 1px;
      border-radius: 7px;
      background: white;
      box-shadow: 0 2px 4px 0 #d3d9de;
    }

    .search-input {
      width: 100%;
      border: none;
      font-weight: 300;
      font-size: 0.9375rem;
    }

    .attachments-list {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0 0 0;
      overflow: auto;
      height: 100%;

      &__img-wrap {
        margin: 17px 10px 0;
        width: calc(100% * (1 / 4) - 20px);
        background-color: rgba(0, 0, 0, 0.3);
        height: 200px;
        position: relative;
        button {
          padding: 0px;
          height: 100%;
          display: block;
          &:focus {
            outline: auto;
            outline-style: auto;
            outline-offset: 3px;
          }
        }

        &.disabled:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
        }

        &.selected {
          border: #03203f solid 5px;

          .videoDuration {
            margin: 10px 0 0 -5px;
            width: calc(100% + 10px);
          }
        }

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          object-fit: cover;
          background-size: cover;
        }

        .is-selected-icon {
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .videoWrapper {
        display: flex;
        flex-direction: column;
        width: calc(100% / 2 - 10px);
        margin: 10px;

        &:nth-child(even) {
          margin-right: 0;
        }

        &:nth-child(odd) {
          margin-left: 0;
        }

        .attachments-list__video-wrap {
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          position: relative;
          cursor: pointer;
          max-height: 100%;

          &.disabled:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
          }

          &.selected {
            border: #03203f solid 5px;

            .videoDuration {
              margin: 10px 0 0 -5px;
              width: calc(100% + 10px);
            }
          }

          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            object-fit: cover;
            background-size: cover;
          }

          .is-selected-icon {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
          }

          .playButton {
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 29px;
              width: 29px;
            }
          }
        }

        .videoDuration {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;

          .duration {
            align-self: flex-start;
            margin-left: 10px;

            .clockDuration {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }

          .videoDuration {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;

            .duration {
              align-self: flex-start;

              .clockDuration {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
          }
        }


      }
    }

    .skeleton-images-attachments-list {
      @extend .attachments-list;

      .css-1y8mk30-SkeletonTheme {
        width: calc(25% - 20px);
        margin: 17px 10px 0;

        .imageSkeletonLoader {
          @extend .attachments-list__img-wrap;
          margin: 0;
          width: 100%;
          background-color: #eee;
        }
      }
    }

    .skeleton-videos-attachments-list {
      @extend .attachments-list;

      .css-1y8mk30-SkeletonTheme {
        width: calc(50% - 10px);
        margin: 10px;

        &:nth-child(even) {
          margin-right: 0;
        }

        &:nth-child(odd) {
          margin-left: 0;
        }

        .videoSkeletonLoader {
          @extend .attachments-list__video-wrap;
          margin: 0;
          width: 100%;
          height: 100%;
          background-color: #eee;
        }
      }
    }
  }

  .attachment-info {
     display: flex;
     padding: 0 20px;
     height: 76px;
     align-items: center;
     justify-content: space-between;

     .images-count {
       width: fit-content;
       border: solid #939FA7 1px;
       border-radius: 5px;
       background: #E7ECEF;
       padding: 7px 10px;
       font-size: 0.9375rem;
       font-weight: 300;
     }
   }
}

.attachments-list__video-wrap button:focus {
  outline: black;
  outline-style: auto;
}