@import "../../../../style";

.Sidebar {
  width: $sidebar-width;
  position: relative;
  background: $color-white;
  overflow-y: auto;
}

.Sidebar__list {
  width: 100%;
}

.Sidebar__listItem {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $color-light-gray;
  padding-top: 26px;
  padding-bottom: 26px;
  transition: 0.3s;
  &:hover {
    background: #dedede;
  }
}

.Sidebar__listItem_active {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    bottom: 10px;
    width: 4px;
    background: $color-purple;
  }
}

.Sidebar__icon {
  margin-left: 21px;
}

.Sidebar__text {
  @include text-shrink;
  margin-left: 19px;
  align-self: center;
  font-size: 1.0625rem;
  font-weight: 500;
}

.Sidebar__toggleButton {
  display: none;
}

.Sidebar__overlay {
  content: " ";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

@media screen and (max-width: $screen-dimension-desktop) {
  .Sidebar {
    width: 70px;
  }

  .Sidebar__list {
    padding-top: 10px;
  }

  .Sidebar__text {
    display: none;
  }

  .Sidebar__listItem {
    padding: 15px 0px;
    border-bottom: 0;
  }

  .Sidebar__listItem_active {
    &::after {
      top: 7px;
      bottom: 7px;
    }
  }

  .Sidebar__icon {
    width: 25px;
    margin-left: 19px;
  }

  .Sidebar__text {
    margin-left: 20px;
  }

  .Sidebar_expanded {
    width: $sidebar-width;
    border-right: 0;
  }

  .Sidebar__toggleButton {
    align-self: flex-start;
    display: block;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $color-dark-gray;
    background: transparent;
    margin-left: 8.5px;
    margin-bottom: 19px;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {


  .Sidebar__burger {
    width: 22px;
    height: 22px;
    background: url(../../../../assets/images/sidebar.svg) center center no-repeat;
    background-size: 20px;
    position: absolute;
    top: 35px;
    left: 15px;
    z-index: 9999;
  }
}
