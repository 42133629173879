@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
.WidgetHeader {
  position: relative;
  padding: 16px 18px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .WidgetHeader .OptionMsj {
    position: relative;
    /*
    &:before {
      position: absolute;
      box-shadow: 0 2px 2px #00000021;
      margin-top: 20px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      border-radius: 4px;
      border: 1px solid #596a75;
      padding: 20px;
      cursor: default;
      background: #596a75;
      content: attr(data-msj);
      right: -2px;
      top: 18px;
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      color: white;
      font-size: 1.0625rem;
    }
    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #596a75;
      right: 1px;
      -webkit-transform: translate(-50%,-50%) rotate(-45deg);
      transform: translate(-50%,-50%) rotate(-45deg);
      background-color: #596a75;
      border-right: 1px solid #596a75;
      border-top: 1px solid #596a75;
      top: 38px;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      &:before, &:after {
        opacity: 1;
      }
    }
    */ }

.WidgetHeader__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1.0625rem; }

.WidgetHeader__image {
  width: 26px;
  height: 26px;
  cursor: pointer; }

.WidgetHeader__tooltip {
  z-index: 2;
  right: 5px;
  position: absolute;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
  margin-top: 20px;
  top: 34px;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 4px;
  border: 1px solid #596A75;
  padding: 20px;
  cursor: default;
  background: #fff; }
  .WidgetHeader__tooltip ul li {
    cursor: pointer; }
  .WidgetHeader__tooltip i {
    position: absolute;
    top: -6px;
    right: 20px;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    transform: rotate(-90deg); }
    .WidgetHeader__tooltip i::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #fff;
      border-right: 1px solid #596A75;
      border-bottom: 1px solid #596A75; }

.ContentWrapper_animated-enter, .ContentWrapper_animated-enter-active, .ContentWrapper_animated-exit, .ContentWrapper_animated-exit-active {
  transition: opacity 0.3s ease-in-out; }

.ContentWrapper_animated-enter {
  opacity: 0; }

.ContentWrapper_animated-enter-active {
  opacity: 1; }

.ContentWrapper_animated-exit {
  opacity: 1; }

.ContentWrapper_animated-exit-active {
  opacity: 0; }

.ListWidget__content {
  border-top: 1px solid #D3D9DE; }

.ListWidget__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 148px;
  flex-direction: column; }
  .ListWidget__loader .collapseTitle {
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer; }

.ListWidget__list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 21px 18px 7px 19px; }

.ListWidget__item {
  position: relative;
  height: 74px;
  margin-bottom: 20px;
  cursor: pointer; }
  .ListWidget__item:hover .ListWidget__text {
    color: #e2017b; }

.ListWidget__text {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 92px;
  width: calc(100% - 92px);
  font-size: 0.9375rem;
  background: transparent;
  text-align: left; }

.ListWidget__image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: flex;
  width: 74px;
  height: 100%;
  min-height: 100%;
  max-height: 74px;
  border-radius: 8px;
  object-fit: cover; }

.SliderWidget {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px; }
  .SliderWidget__contentWrapper {
    width: 100%;
    padding-top: min(56%, 550px); }
  .SliderWidget__infoWrapper_outside {
    display: none; }
  .SliderWidget__titleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: auto;
    z-index: 5; }
  .SliderWidget__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; }
  .SliderWidget__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0; }
  .SliderWidget__info {
    position: absolute;
    bottom: 24px;
    left: 21px;
    right: 21px;
    z-index: 2; }
  .SliderWidget__title {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-y: hidden;
    margin-bottom: 5px;
    color: #FFF;
    font-size: 1.875rem; }

.SliderWidget_collapsed {
  height: 122px; }

.SliderWidget__title_oneLine {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-y: hidden;
  padding-right: 26px; }

.SliderWidget__description {
  margin-bottom: 12px;
  font-size: 1.25rem;
  color: #FFF;
  width: 65%;
  font-weight: 300; }
  .SliderWidget__description * {
    font-size: 1.25rem;
    color: #FFF; }

.SliderWidget__description_oneLine {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-y: hidden;
  padding-right: 80px; }

.SliderWidget__button {
  padding: 9px 23px;
  border-radius: 20px;
  background-color: #E2017B;
  color: #FFF;
  font-size: 1.0625rem; }
  .SliderWidget__button:hover {
    background-color: #af0060; }

.SliderWidget__slideSwitcher {
  position: absolute;
  bottom: 24px;
  right: 55px;
  z-index: 3; }

.SliderWidget__slideSwitcher .SliderWidget__content__pause {
  bottom: 0;
  right: -35px;
  pointer-events: none; }

.SliderWidget__switchButton {
  width: 15px !important;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #FFF;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 100ms ease-in-out;
  margin-left: 12px;
  padding: 0.40em;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .SliderWidget__switchButton:first-child {
    margin-left: 0; }

.SliderWidget__switchButton_active {
  background-color: #FFF; }

.SliderWidget__content__pauseicon {
  position: absolute;
  width: 100%;
  pointer-events: none;
  height: 100%;
  background-size: cover;
  background-image: url(/static/media/button-pause.dcf29241.svg); }

.SliderWidget__content__pause {
  position: absolute;
  width: 26px;
  height: 26px;
  cursor: pointer;
  bottom: 24px;
  right: 20px;
  z-index: 4;
  border-radius: 50%;
  margin: 0px;
  padding: 0px; }
  .SliderWidget__content__pause:before {
    content: attr(data-pause);
    z-index: 1;
    position: absolute;
    box-shadow: 0 2px 2px #00000021;
    margin-top: 20px;
    width: -webkit-max-content;
    width: max-content;
    border-radius: 4px;
    border: 1px solid #596A75;
    padding: 20px;
    cursor: default;
    background: #596A75;
    right: -6px;
    top: -92px;
    pointer-events: none;
    opacity: 0;
    color: white;
    font-size: 1.0625rem; }
  .SliderWidget__content__pause:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #596A75;
    right: -2px;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #596A75;
    border-left: 1px solid #596A75;
    border-bottom: 1px solid #596A75;
    top: -8px;
    z-index: 1;
    pointer-events: none;
    opacity: 0; }
  .SliderWidget__content__pause:hover:before, .SliderWidget__content__pause:hover:after, .SliderWidget__content__pause:focus:before, .SliderWidget__content__pause:focus:after {
    opacity: 1; }
  .SliderWidget__content__pause.active:before {
    content: attr(data-play); }
  .SliderWidget__content__pause.active i {
    background-image: url(/static/media/button-play.fb78d7e6.svg); }

.SliderWidget__content__pause.opacity .SliderWidget__content__pauseicon {
  opacity: 0; }

.SliderWidget__content_animated-enter, .SliderWidget__content_animated-exit {
  transition: transform 0.5s ease-in-out; }

.SliderWidget__content_animated-enter {
  transform: translateX(100%); }

.SliderWidget__content_animated-enter-active {
  transform: translateX(0); }

.SliderWidget__content_animated-exit {
  transform: translateX(0); }

.SliderWidget__content_animated-exit-active {
  transform: translateX(-100%); }

.SliderWidget__image_animated {
  animation: zoomOut 7s linear; }

@keyframes zoomOut {
  from {
    transform: scale(1.2) translate(50px, 20px); }
  to {
    transform: scale(1) translate(0, 0); } }

.SliderWidget__image_animatedCollapsed {
  animation: zoomOut 7s linear; }

@keyframes zoomOut {
  from {
    transform: scale(1.2) translate(10px, 5px); }
  to {
    transform: scale(1) translate(0, 0); } }

@media screen and (max-width: 1280px) {
  .SliderWidget__title {
    font-size: 1.5rem;
    margin-bottom: 3px; }
  .SliderWidget__description {
    font-size: 1.0625rem;
    margin-bottom: 9px; } }

@media screen and (max-width: 640px) {
  .SliderWidget {
    border-radius: 0; }
  .SliderWidget_small {
    height: auto; }
  .SliderWidget__title {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-y: hidden;
    color: #0B2541;
    font-size: 1.5rem;
    line-height: 31px;
    margin-bottom: 5px; }
  .SliderWidget__description {
    margin-bottom: 18px;
    color: #0B2541; }
  .SliderWidget__button {
    width: 100%; }
  .SliderWidget__infoWrapper_outside {
    padding: 16px 16px 0 16px;
    background-color: #E7ECEF;
    display: block; }
  .SliderWidget__infoWrapper_inside {
    display: none; }
  .SliderWidget__info {
    position: static; }
  .SliderWidget__contentWrapper {
    position: relative;
    overflow: hidden; }
  .SliderWidget__slideSwitcher {
    left: 21px;
    display: flex;
    justify-content: center; }
  @keyframes zoomOut {
    from {
      transform: scale(1.2) translate(10px, 5px); }
    to {
      transform: scale(1) translate(0, 0); } } }

.StatisticBlock__info {
  display: flex;
  align-items: center;
  font-size: 3.75rem;
  font-weight: 500; }

.StatisticBlock__image {
  margin-right: 17.38px; }

.StatisticBlock__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 26px;
  font-size: 1.5rem;
  font-weight: 300; }

.StatisticBlock__button {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1.0625rem;
  font-weight: 500;
  padding: 9px 23px;
  border-radius: 20px;
  background-color: #0B2541;
  color: #E7ECEF; }

@media screen and (max-width: 1366px) {
  .StatisticBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    justify-content: space-between; }
  .StatisticBlock__title {
    flex: 1 1;
    margin-left: 47px;
    margin-bottom: 0; } }

@media screen and (max-width: 1280px) {
  .StatisticBlock__image {
    width: 40px;
    height: 40px; }
  .StatisticBlock__value {
    font-size: 48px; }
  .StatisticBlock__title {
    font-size: 1rem; }
  .StatisticBlock__button {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1rem; } }

@media screen and (max-width: 640px) {
  .StatisticBlock {
    flex-direction: column; }
  .StatisticBlock__title {
    margin-left: 0;
    margin-bottom: 14px; } }

.StatisticWidget {
  position: relative;
  min-height: 150px; }

.StatisticWidget__content {
  display: flex; }

.StatisticWidget__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.StatisticWidget__item {
  padding: 43px 30px 62px 30px;
  flex: 1 1;
  border-right: 1px solid #E7ECEF; }
  .StatisticWidget__item:last-child {
    border-right: 0; }

.StatisticWidget__titleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: auto; }

@media screen and (max-width: 1366px) and (min-width: 641px) {
  .StatisticWidget__content {
    flex-direction: column; }
  .StatisticWidget__item {
    border-right: 0;
    border-bottom: 1px solid #E7ECEF;
    padding: 20px 20px 20px 20px; }
  .StatisticWidget__titleWrapper {
    position: static;
    border-bottom: 1px solid #E7ECEF; } }

@media screen and (max-width: 1366px) and (min-width: 641px) {
  .StatisticWidget__item {
    padding: 20px 10px 20px 12px; } }

@media screen and (max-width: 640px) {
  .StatisticWidget__content {
    flex-direction: column; }
  .StatisticWidget__item {
    padding: 44px 13px 35px 13px;
    padding-bottom: 30px;
    padding-top: 30px;
    border-right: 0px;
    border-bottom: 1px solid #E7ECEF; } }

.RecentActivity__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px; }

.RecentActivity__header {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 19px 15px;
  border-bottom: 1px solid #D3D9DE; }
  .RecentActivity__header p {
    margin: 0; }
  .RecentActivity__header img {
    cursor: pointer; }

.RecentActivity__list {
  padding: 20px 29px 28px 20px;
  display: flex;
  flex-direction: column; }

.RecentActivity__collapseWrapper {
  display: flex;
  align-items: center;
  flex-direction: column; }

.RecentActivity__collapse {
  font-weight: 300;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer; }

.RecentActivity__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px; }
  .RecentActivity__item:last-child {
    margin: 0; }

.RecentActivity__block {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  min-width: 0;
  flex-shrink: 10; }

.RecentActivity__image {
  width: 36px;
  margin-right: 19px; }

.RecentActivity__text {
  flex-shrink: 10; }

.RecentActivity__link {
  text-decoration: underline;
  cursor: pointer; }

.RecentActivity__date {
  padding: 5px 10px 4px 11px;
  border-radius: 11px;
  background: #E7ECEF;
  font-weight: 300;
  font-size: 0.8125rem;
  margin-left: 20px;
  flex-shrink: 0; }

@media screen and (max-width: 870px) {
  .RecentActivity__list {
    padding: 20px 20px 20px 20px; }
  .RecentActivity__item {
    flex-direction: column; }
  .RecentActivity__date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 0;
    width: 100%; } }

.Popup {
  position: fixed;
  top: 78px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }

.Popup_animated-enter {
  transform: scale(0.9);
  opacity: 0; }

.Popup_animated-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out; }

.Popup_animated-exit {
  opacity: 1; }

.Popup_animated-exit-active {
  opacity: 0;
  transform: translateY(400px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out; }

.Popup__overlay {
  border-top: 1px solid #E4E9ED;
  padding-top: 75px;
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95); }

.Popup__content {
  width: 100%;
  height: 100%; }

.Popup__button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #E2017B;
  color: #FFF;
  border-radius: 20px;
  padding: 9px 47px 9px 18px;
  z-index: 1; }

.Popup__buttonImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  width: 24.38px;
  height: 24.38px; }

@media screen and (max-width: 640px) {
  .Popup__button {
    top: 0;
    right: 0; }
  .Popup_animated-exit-active {
    transform: translateY(100%); }
  .Popup__overlay {
    padding-top: 38px; } }

.ActivityPage__greeting {
  margin-top: -1px;
  margin-left: -2px;
  margin-bottom: 20px;
  font-size: 1.625rem; }
  .ActivityPage__greeting h1 {
    font-size: 1.625rem;
    margin: 0px; }

.recentActivityNewContent {
  position: relative; }
  .recentActivityNewContent__pause {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    top: 18px;
    right: 55px;
    background: white;
    z-index: 2;
    border-radius: 50%;
    border: solid 2px #0b2541; }
    .recentActivityNewContent__pause i {
      position: absolute;
      width: 100%;
      pointer-events: none;
      height: 100%; }
      .recentActivityNewContent__pause i:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 7px;
        border: solid 2px #0b2541;
        top: 5px;
        left: 5px; }
      .recentActivityNewContent__pause i:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 7px;
        border: solid 2px #0b2541;
        top: 5px;
        right: 6px; }
    .recentActivityNewContent__pause.active i:after {
      display: none; }
    .recentActivityNewContent__pause.active i:before {
      top: 6px;
      left: 8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 9px;
      border-color: transparent transparent transparent #0b2541; }

.ActivityPage__content {
  display: flex;
  margin-right: -2px;
  margin-left: -2px; }

.ActivityPage__main {
  flex: 1 1 auto;
  padding-right: 19px;
  z-index: 1; }

.ActivityPage__targetRoleTitle {
  display: flex;
  margin: 10px 0; }

.ActivityPage__aside {
  width: 280px;
  flex: 0 0 auto; }

.marginTop {
  margin-top: 42px; }

.ActivityPage__widget {
  border-radius: 8px;
  background-color: #FFF;
  border: 1px solid #D3D9DE;
  margin-bottom: 20px; }

@media screen and (max-width: 1280px) {
  .ActivityPage__greeting {
    display: none; }
  .ActivityPage__aside {
    width: 252px; } }

@media screen and (max-width: 1024px) {
  .ActivityPage__aside {
    width: 212px; } }

@media screen and (max-width: 960px) {
  .ActivityPage__content {
    flex-direction: column; }
  .ActivityPage__main {
    padding-right: 0; }
  .ActivityPage__aside {
    width: auto; }
  .marginTop {
    margin-top: 0; } }

@media screen and (max-width: 870px) {
  .ActivityPage {
    padding-top: 20px; }
  .marginTop {
    margin-top: 0; } }

@media screen and (max-width: 640px) {
  .ActivityPage {
    padding-top: 0; }
  .marginTop {
    margin-top: 0; }
  .ActivityPage__widgetSlider {
    margin-left: -18px;
    margin-right: -16px;
    border-radius: 0;
    border: 0;
    overflow: hidden; }
  .ActivityPage__content {
    margin-right: -3px; } }

.AssignmentsPage {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .AssignmentsPage .NewAssignment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 4; }
    .AssignmentsPage .NewAssignment .addAssignmentFlow {
      overflow: auto; }
    .AssignmentsPage .NewAssignment .newAssignmentPreview {
      background: #E7ECEF;
      border-left: 1px solid #d3d9de; }
      .AssignmentsPage .NewAssignment .newAssignmentPreview .nothingPhrase {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 1.375rem; }
      .AssignmentsPage .NewAssignment .newAssignmentPreview .previewPhrase {
        width: 350px;
        text-align: center; }
  .AssignmentsPage.moveListBySearchFilter {
    transition: 0.3s; }
    .AssignmentsPage.moveListBySearchFilter.active {
      width: calc(100% - 290px);
      margin-left: 290px; }
      .AssignmentsPage.moveListBySearchFilter.active .InfoCard {
        width: calc(20% - 15px); }
  .AssignmentsPage .notFoundPage .container-404 {
    left: 0;
    width: 100vw;
    height: 100vh; }
  .AssignmentsPage .assignmentList {
    height: 100%;
    overflow: auto; }

@media screen and (max-width: 870px) {
  .AssignmentsPage_noTabs {
    padding-top: 15px; }
  .assignmentList {
    height: 100%; }
    .assignmentList .MyListPagination {
      margin-bottom: 20px; } }

@media screen and (max-width: 575px) {
  .AssignmentsPage {
    height: auto; }
    .AssignmentsPage .assignmentList {
      flex-flow: nowrap;
      width: calc(100% + 19px); }
      .AssignmentsPage .assignmentList .MyList {
        display: flex;
        flex-wrap: nowrap; }
      .AssignmentsPage .assignmentList .AssignmentListItem__super {
        width: calc(100% - 10px);
        margin-right: 10px; } }

.TabNavigation {
  height: 61px;
  min-height: 61px;
  background: #fff;
  border-radius: 8px;
  padding: 0 20px;
  box-shadow: 0 2px 5px 0 #D3D9DE;
  border: 1px solid #D3D9DE;
  margin-bottom: 20px; }
  .TabNavigation ul {
    height: 60px; }
    .TabNavigation ul li {
      margin-right: 40px;
      height: 100%; }
      .TabNavigation ul li .sidebarLink {
        position: relative;
        height: 100%;
        text-align: center;
        transition: 0.3s;
        padding: 0px 5px; }
        .TabNavigation ul li .sidebarLink:hover {
          background: #dedede; }
  .TabNavigation .activeRoute::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid #E2017B;
    bottom: 0;
    left: 0;
    width: 100%; }

@media screen and (max-width: 870px) {
  header {
    min-height: auto; }
  .TabNavigation {
    background: none;
    box-shadow: none;
    flex-direction: column;
    margin-bottom: 80px;
    padding: 0; }
    .TabNavigation .Link {
      white-space: nowrap;
      font-size: 0.9375rem; }
  .menu-list {
    width: calc(100% + 40px);
    display: flex;
    justify-content: center;
    background: white;
    border-top: 1px solid #E4E9ED;
    min-height: 61px; }
  .nav-tabs {
    display: inline-flex;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
  .nav-tabs li:last-child {
    margin-right: 0; }
  .nav-tabs::-webkit-scrollbar {
    display: none; }
  .examnav-tabsple {
    -ms-overflow-style: none; }
  .TabNavigation_noMargin {
    margin-bottom: 20px; } }

.SearchFilter {
  display: flex;
  justify-content: space-between;
  background: #FFF;
  border-radius: 8px;
  padding: 14px 0 0 20px;
  box-shadow: 0 2px 2px 0 #D3D9DE;
  border: 1px solid #D3D9DE;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: padding-box;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content; }
  .SearchFilter .centerMin {
    text-align: left;
    font-size: 16px; }
  .SearchFilter select:focus > option:checked {
    font-weight: bold !important; }

.SearchFilter__link {
  margin-bottom: 14px; }
  .SearchFilter__link a {
    display: flex;
    background: #e7ecef;
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #939fa7;
    color: #0B2541;
    font-weight: 200;
    margin-right: 20px;
    font-size: 16px; }
    .SearchFilter__link a.openhandler {
      background: #0B2541;
      border: 1px solid #0B2541;
      color: white; }
    .SearchFilter__link a img {
      margin-right: 5px; }
    .SearchFilter__link a:focus {
      outline: black;
      outline-offset: 4px;
      outline-style: auto; }

.SearchFilter__select {
  margin-right: 20px;
  margin-bottom: 14px;
  position: relative;
  padding-right: 28px;
  height: 33px;
  border-radius: 5px;
  border: 1px solid #939fa7;
  color: #0B2541;
  font-weight: 300;
  font-size: 0.9375rem;
  min-width: 150px;
  background: url(/static/media/arrow-down.3bede566.svg) no-repeat right 10px center, #E7ECEF;
  transition: 0.3s;
  max-width: 320px;
  cursor: pointer; }
  .SearchFilter__select:hover {
    border-color: #0b2541; }

.SearchFilter__option {
  background: #E7ECEF;
  color: #0B2541; }

.SearchFilter__search__submit {
  background: url(/static/media/search.9aa844cf.svg) no-repeat;
  background-position: 7px 6px;
  cursor: pointer;
  width: 34px;
  height: 34px;
  background-color: #e7ecef;
  border-radius: 50%;
  border: solid 1px #0b25415c;
  transition: 0.3s; }
  .SearchFilter__search__submit:hover {
    border: solid 1px #0b2541; }

.SearchFilter__search__content {
  display: flex;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-right: 20px; }

.SearchFilter__search {
  display: block;
  padding: 5px 10px 6px;
  border: none;
  border-left: 1px solid;
  border-radius: unset;
  width: 175px;
  font-size: .9375rem;
  font-weight: 300; }
  .SearchFilter__search::placeholder {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: #0B2541;
    font-size: 0.9375rem;
    font-weight: 300; }
  .SearchFilter__search .large-search {
    width: 227px; }
  .SearchFilter__search .small-search {
    width: 137px; }

.SearchFilter__search__submit {
  background: url(/static/media/search.be502db2.svg) no-repeat right center;
  background-position: 7px 6px;
  border: none;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #e7ecef;
  pointer-events: none; }

.SearchFilter__space {
  display: flex;
  justify-content: space-between;
  flex-grow: 2; }

.SearchFilter__select_sorting {
  width: 232px; }

.FiltersModal {
  position: fixed;
  left: 0;
  width: 500px;
  padding: 20px 30px;
  padding-right: 18px;
  box-sizing: border-box;
  background: white;
  height: calc(100% - 80px);
  top: 80px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 8; }
  .FiltersModal__header {
    display: flex;
    justify-content: space-between; }
    .FiltersModal__header h5 {
      margin: 0px;
      font-size: 1.2rem;
      font-weight: 400;
      color: #e2017b; }
    .FiltersModal__header button {
      display: flex;
      background: transparent; }
      .FiltersModal__header button img {
        width: 20px;
        height: 20px;
        margin-right: 5px; }
      .FiltersModal__header button:focus {
        outline-offset: 3px !important; }
  .FiltersModal__body {
    padding: 10px 0px; }
    .FiltersModal__body__item {
      margin: 30px 0px; }
      .FiltersModal__body__item .itemFilter {
        display: flex; }
        .FiltersModal__body__item .itemFilter__left {
          margin-right: 10px; }
          .FiltersModal__body__item .itemFilter__left img {
            width: auto;
            height: 25px; }
        .FiltersModal__body__item .itemFilter__right h3 {
          margin-top: 2px;
          font-size: 16px;
          margin-bottom: 15px;
          font-weight: 500; }
        .FiltersModal__body__item .itemFilter__right .flexFilter {
          display: flex;
          flex-wrap: wrap; }
        .FiltersModal__body__item .itemFilter__right .itemFlexFilter {
          font-size: 14px;
          background: #e7ecef;
          padding: 3px 8px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-weight: 200;
          color: black; }
          .FiltersModal__body__item .itemFilter__right .itemFlexFilter.active {
            background: #0b2541;
            color: white; }
          .FiltersModal__body__item .itemFilter__right .itemFlexFilter:focus {
            outline-offset: 2px; }
        .FiltersModal__body__item .itemFilter__core {
          min-width: 300px;
          max-width: 420px; }

.FiltersModal__backgroundside {
  position: fixed;
  top: 0px;
  left: 500px;
  width: calc(100% - 500px);
  height: 100%;
  background: transparent;
  z-index: -1;
  cursor: pointer; }

.fixedsModal .FiltersModal {
  top: 78px;
  height: calc(100% - 78px); }

.expandfiltermodal .fixedsModal .FiltersModal {
  top: 190px;
  height: calc(100% - 192px); }

.filtersModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4; }

.listLenguagesComplete {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 15px; }
  .listLenguagesComplete .allSpark {
    margin-right: 15px; }
  .listLenguagesComplete .listLenguajesList {
    margin-left: 15px;
    position: relative; }
    .listLenguagesComplete .listLenguajesList.listLenguajesListHidden {
      display: none; }
  .listLenguagesComplete .listLenguajes .CreateButton {
    color: #0b2541;
    background: #E7ECEF;
    margin: 0px 5px;
    border: 1px solid #939fa7;
    font-weight: 200; }
    .listLenguagesComplete .listLenguajes .CreateButton.active {
      background: #0b2541;
      color: #E7ECEF; }
  .listLenguagesComplete .listLenguajesList a {
    display: flex;
    align-items: center;
    font-weight: 200; }
    .listLenguagesComplete .listLenguajesList a img {
      margin-right: 5px;
      width: 25px;
      height: 25px; }
  .listLenguagesComplete .listLenguajesList .CreateButton {
    color: #0b2541;
    background: #E7ECEF;
    margin: 0px 5px;
    border: 1px solid #939fa7;
    font-weight: 200; }
    .listLenguagesComplete .listLenguajesList .CreateButton.active {
      background: #0b2541;
      color: #E7ECEF; }
      .listLenguagesComplete .listLenguajesList .CreateButton.active img {
        filter: grayscale(1) invert(1); }
    .listLenguagesComplete .listLenguajesList .CreateButton:hover {
      background: #0b2541;
      color: #E7ECEF; }
      .listLenguagesComplete .listLenguajesList .CreateButton:hover img {
        filter: grayscale(1) invert(1); }

.SearchFilter__left {
  display: flex;
  align-items: center; }

.SearchFilter__right {
  display: flex;
  align-items: center; }

.absModalTinker {
  position: absolute;
  background-color: #FFF;
  border: 1px solid #D3D9DE;
  top: 45px;
  border-radius: 7px;
  overflow: hidden;
  right: 0;
  z-index: 2; }
  .absModalTinker button {
    display: block;
    width: 100%;
    background: white;
    border-bottom: solid 1px #d7d7d769;
    padding: 5px 10px;
    min-width: 150px;
    box-sizing: border-box; }
    .absModalTinker button:hover, .absModalTinker button.active {
      background: black;
      color: white; }
    .absModalTinker button:focus {
      background: #dbdbdb !important;
      color: #0b2541 !important; }

.resetButton {
  margin-bottom: 15px;
  margin-right: 10px;
  background: #E7ECEF !important;
  color: #0b2541 !important;
  display: flex;
  align-items: center;
  border: 1px solid #939fa7;
  min-height: 40px !important;
  height: 40px !important; }
  .resetButton img {
    margin-right: 5px;
    width: 18px;
    height: 18px; }
  .resetButton span {
    font-size: 16px;
    font-weight: 200; }

@media screen and (max-width: 870px) {
  .SearchFilter__select {
    flex: 1 1 100% !important; }
  .SearchFilter {
    padding: 15px !important;
    display: block; }
  .SearchFilter__right {
    display: block; }
  .SearchFilter__left {
    display: block; }
  .listLenguagesComplete {
    margin-right: 10px;
    margin-bottom: 0px;
    flex-wrap: wrap; }
  .listLenguagesComplete .allSpark {
    display: none; }
  .listLenguajes {
    display: flex; }
  .listLenguajes button {
    width: 110px !important;
    min-width: 80px;
    font-size: 14px; }
  .listLenguagesComplete .listLenguajesList {
    margin-left: 0px; }
  .listLenguagesComplete .listLenguajesList a {
    min-width: 110px !important;
    width: 110px !important;
    font-size: 14px;
    justify-content: center; }
  .SearchFilter__link.TpButton {
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 5px; }
  button.CreateButton.resetButton {
    min-width: 150px !important;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: center; }
  .SearchFilter__link a {
    justify-content: center;
    margin-right: 0px; } }

.ActionMenu {
  position: relative;
  box-shadow: 0 2px 2px rgba(255, 255, 255, 0.129753);
  margin-top: 17px;
  margin-bottom: 17px;
  border-radius: 4px;
  border: 1px solid #939fa7;
  padding: 13px 20px 13px 20px;
  cursor: default;
  background: #FFF; }

.ActionMenu__list {
  display: flex;
  flex-direction: column; }

.ActionMenu__item {
  display: flex; }

.ActionMenu__item__disabled {
  color: #939FA7;
  cursor: default !important;
  margin: 7px;
  font-size: 0.9375rem; }
  .ActionMenu__item__disabled button {
    margin: 0; }

.ActionMenu__actionTarget {
  margin: 7px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  font-size: 0.9375rem;
  font-weight: 500;
  text-align: left;
  white-space: nowrap; }
  .ActionMenu__actionTarget:focus {
    outline: black;
    outline-style: auto; }

.ActionMenu__caret {
  position: absolute;
  top: -6px;
  right: 8px;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  transform: rotate(-90deg); }
  .ActionMenu__caret::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #fff;
    border-right: 1px solid #939fa7;
    border-bottom: 1px solid #939fa7; }

.ActionMenu__caret_bottom {
  top: auto;
  bottom: -6px;
  transform: rotate(90deg); }
  .ActionMenu__caret_bottom:after {
    transform: translate(50%, -50%) rotate(-45deg); }

@media screen and (max-width: 1024px) {
  .ActionMenu {
    background: #0B2541;
    border-color: #0B2541;
    padding: 16px; }
  .ActionMenu__item__disabled {
    margin: 7px 0; }
  .ActionMenu__actionTarget {
    margin: 7px 0 7px 0;
    color: #FFF;
    font-size: 0.9375rem; }
  .ActionMenu__caret:after {
    background-color: #0B2541;
    border-color: #0B2541; } }

.Notification_container, .Notification_container__confirm, .Notification_container__success, .Notification_container__error {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 340px;
  margin-bottom: 5px;
  padding: 24px 47px 25px 40px;
  z-index: 100;
  opacity: 1;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.168898);
  border: 1px solid;
  cursor: default; }

.Notification_container__confirm {
  border-color: #0B2541; }

.Notification_container__success {
  border-color: #0A7B24; }

.Notification_container__error {
  border-color: #E2017B; }

.Notification_content {
  display: flex;
  align-items: center; }

.Notification_icon {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px; }

.Notification_title {
  margin: 0 0 0 20px;
  font-size: 1.0625rem; }

.Notification_close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer; }

.Notification_footer {
  margin-top: 30px; }

.Notification_button, .Notification_submit, .Notification_cancel {
  height: 40px;
  padding: 9px 23px;
  border-radius: 20px;
  font-size: 1.0625rem;
  color: white; }

.Notification_submit {
  background: #0A7B24; }

.Notification_cancel {
  margin-left: 17px;
  background: #E2017B; }

@media screen and (max-width: 640px) {
  .Notification_container, .Notification_container__confirm, .Notification_container__success, .Notification_container__error {
    width: auto; } }

.Notification_submit:focus, .Notification_cancel:focus {
  outline: black !important;
  outline-offset: 2px;
  outline-style: auto !important; }

.previewModalInfoSearch {
  position: fixed;
  top: 78px;
  width: 100%;
  height: calc(100% - 78px);
  z-index: 20;
  left: 0; }
  .previewModalInfoSearch.hidden {
    display: none; }
  .previewModalInfoSearch__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(54, 57, 71, 0.3);
    z-index: 1; }
  .previewModalInfoSearch__content {
    position: absolute;
    height: 100%;
    width: 780px;
    background: white;
    right: 0;
    z-index: 2; }
    .previewModalInfoSearch__content .headerButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 12px; }
      .previewModalInfoSearch__content .headerButtons .previewButtons {
        position: relative;
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .functionsButtons {
        position: relative;
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .DistributeButtons {
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .modalContent {
        border: 1px solid #D3D9DE;
        border-top: 0;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 #ffffff1c;
        position: absolute;
        z-index: 2;
        background: white;
        padding: 12px 20px;
        right: 0px;
        padding-top: 17px;
        min-width: 220px; }
        .previewModalInfoSearch__content .headerButtons .modalContent button {
          background: transparent;
          display: block;
          margin-bottom: 5px;
          text-align: left;
          white-space: nowrap; }
        .previewModalInfoSearch__content .headerButtons .modalContent .actionboldButton button {
          font-size: 17px;
          font-weight: 500;
          display: block !important;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px !important;
          width: 100% !important;
          padding: 0px; }
        .previewModalInfoSearch__content .headerButtons .modalContent .actionboldButton.actionAfter button:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: solid 2px #0b2541;
          border-right: solid 2px #0b2541;
          transform: rotate(-45deg);
          top: 5px;
          right: 3px; }
        .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite {
          display: block;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-bottom: solid 2px #0b2541;
            border-right: solid 2px #0b2541;
            transform: rotate(-45deg);
            top: 5px;
            right: 0px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite.LinkRollback:after {
            display: none; }
        .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside {
          position: absolute;
          top: 0;
          border: 1px solid #D3D9DE;
          border-top: 0;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #ffffff1c;
          background: white;
          padding: 12px 20px;
          width: 100%;
          left: 0;
          min-height: 100%;
          pointer-events: none;
          opacity: 0;
          transition: 0.3s;
          z-index: 2; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside.active {
            pointer-events: all;
            opacity: 1; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 {
            display: block;
            margin: 0px;
            font-size: 17px;
            border-bottom: solid 1px #0000001c;
            padding-bottom: 5px;
            margin-bottom: 5px; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 a {
              display: block;
              width: 100%;
              position: relative;
              padding-left: 20px;
              font-size: 17px; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 a:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-bottom: solid 2px #0b2541;
                border-left: solid 2px #0b2541;
                transform: rotate(45deg);
                top: 5px;
                left: 0px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li {
            margin-bottom: 10px;
            margin-top: 10px; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li a {
              display: block;
              position: relative; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex p {
                margin: 0px; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div {
                display: flex; }
                .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div svg {
                  display: block;
                  border: solid 1px #979797;
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  padding: 5px; }
                  .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div svg path {
                    fill: #061626; }
                .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div a {
                  margin-left: 7px; }
      .previewModalInfoSearch__content .headerButtons .modalToggle {
        border: solid 2px #d3d9de;
        border-radius: 20px;
        padding: 7px 16px;
        display: block;
        min-width: 100px;
        text-align: center;
        color: #0b2541; }
        .previewModalInfoSearch__content .headerButtons .modalToggle.active {
          border: solid 2px #0b2541; }
        .previewModalInfoSearch__content .headerButtons .modalToggle:after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-left: solid 2px #0b2541;
          border-bottom: solid 2px #0b2541;
          transform: rotate(-45deg);
          margin-left: 8px;
          top: -3px;
          position: relative; }
    .previewModalInfoSearch__content .NewTitleHeader {
      background: #0b2541;
      padding-top: 26px;
      padding-bottom: 27px; }
      .previewModalInfoSearch__content .NewTitleHeader h2 {
        margin: 0px;
        text-align: center;
        color: white;
        font-size: 36px;
        font-weight: 400; }
    .previewModalInfoSearch__content .entityInfo {
      height: 67px;
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .previewModalInfoSearch__content .entityInfo .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px; }
        .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoAutor p {
          white-space: nowrap;
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden; }
        .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul {
          display: flex; }
          .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li {
            margin-right: 5px; }
            .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:after {
              content: ','; }
            .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:last-child {
              margin-right: 0; }
              .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:last-child:after {
                display: none; }
        .previewModalInfoSearch__content .entityInfo .partsInfo img {
          margin-right: 10px;
          max-width: 22px; }
    .previewModalInfoSearch__content .entityDescription {
      /* height: 67px; */
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: left;
      justify-content: left;
      height: auto !important; }
      .previewModalInfoSearch__content .entityDescription .partsInfoDescription {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        font-size: 17px; }
    .previewModalInfoSearch__content .summary {
      display: block;
      padding: 20px;
      background: white;
      overflow: auto;
      height: calc(100vh - 385px) !important; }
      .previewModalInfoSearch__content .summary .entityInfoBlock {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .imageGrep {
          margin-right: 10px; }
          .previewModalInfoSearch__content .summary .entityInfoBlock .imageGrep .imgInfo {
            width: 21px;
            height: 21px; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .title {
          color: #0b2541;
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .listItem .item {
          margin: 0;
          padding: 0;
          text-decoration: none;
          display: inline-block;
          background-color: #d3d9de;
          border-radius: 4px;
          color: #0b2541;
          font-size: 13px;
          font-weight: 300;
          padding: 4px;
          margin: 5px 5px 5px 0px; }
      .previewModalInfoSearch__content .summary .entityInfoBlockExpanded {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .imageGrep {
          margin-right: 10px; }
          .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .imageGrep .imgInfo {
            width: 21px;
            height: 21px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .title {
          color: #0b2541;
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          /* width: 56px; */
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%; }
      .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem {
        margin: 0px 44px 0px 44px; }
        .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData {
          display: flex;
          font-size: 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.0901961);
          padding: 10px 0;
          color: #0a003d;
          font-weight: 300; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData__grade {
            margin-right: 20px;
            min-width: 110px;
            text-decoration: none; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData__subject {
            margin-right: 20px;
            min-width: 150px; }

@media screen and (max-width: 1000px) {
  .previewModalInfoSearch__content {
    width: calc(100% - 50px); }
    .previewModalInfoSearch__content .headerButtons {
      flex-wrap: wrap; }
      .previewModalInfoSearch__content .headerButtons .modalToggle {
        min-width: 90px;
        padding: 7px 10px; }
    .previewModalInfoSearch__content .NewTitleHeader {
      padding-top: 16px;
      padding-bottom: 17px; }
      .previewModalInfoSearch__content .NewTitleHeader h2 {
        font-size: 25px; }
    .previewModalInfoSearch__content .entityInfo {
      flex-wrap: wrap;
      height: auto;
      padding: 10px 0px;
      padding-top: 5px;
      justify-content: flex-start; }
    .previewModalInfoSearch__content .entityDescription {
      padding: 10px 0px; }
    .previewModalInfoSearch__content .summary {
      padding: 10px; }
      .previewModalInfoSearch__content .summary .entityInfoBlock {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
        overflow: hidden; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .title {
          max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .entityInfoBlockExpanded {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .title {
          max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .listItem {
        min-height: auto !important;
        max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem {
        margin-left: 44px;
        margin-right: 0px; }
        .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData {
          display: block; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData .goalData__grade {
            font-weight: 700;
            margin-bottom: 5px;
            margin-right: 0px; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData .goalData__subject {
            margin-right: 0px;
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 14px; } }

.AssignmentListItem {
  min-height: 60px;
  position: relative;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.13);
  padding: 10px 20px 10px 110px;
  margin-bottom: 10px;
  border: 1px solid #D3D9DE;
  display: flex;
  justify-content: space-between; }
  .AssignmentListItem:hover {
    background: #D3D9DE; }

.AssignmentListItem__super {
  position: relative; }
  .AssignmentListItem__super a {
    display: block; }
    .AssignmentListItem__super a li.AssignmentListItem {
      padding-right: 48px; }
  .AssignmentListItem__super:last-child .AssignmentListItem__more:before, .AssignmentListItem__super:last-child .AssignmentListItem__more:after {
    display: none !important; }

.AssignmentListItem__block {
  display: flex;
  align-items: center;
  min-width: 0; }

.AssignmentListItem__blockMain {
  flex-shrink: 3; }

.AssignmentListItem__image {
  /* @include absolute-attach(left, -1px);
  width: $image-width;
  height: $image-height;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  object-fit: cover; */
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  bottom: 0;
  right: auto;
  width: 90px;
  height: 58px;
  border-top-left-radius: 4.1px;
  border-bottom-left-radius: 4.1px;
  object-fit: cover; }

.AssignmentListItem__contentCreatorImage {
  margin-right: 20px;
  flex-shrink: 0; }

.AssignmentListItem__likes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px 8px 8px;
  border-radius: 5px;
  background: #E7ECEF;
  margin-right: 20px;
  min-width: 0; }

.AssignmentListItem__likes_mobile {
  display: none; }

.AssignmentListItem__likesImage {
  margin-right: 7px; }

.AssignmentListItem__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-shrink: 3;
  margin-right: 5px; }

.AssignmentListItem__noTitle {
  color: #939FA7; }

.AssignmentListItem__status {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #596A75;
  margin-left: 6px; }

.AssignmentListItem__subjects {
  display: flex;
  margin-right: 20px;
  min-width: 0; }

.AssignmentListItem__subjects_mobile {
  display: none; }

.AssignmentListItem__subject {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.9375rem;
  padding: 7px 18px 8px 21px;
  background: #E7ECEF;
  border: 1px solid #D3D9DE;
  border-radius: 17px;
  margin-right: 5px;
  font-weight: 300; }
  .AssignmentListItem__subject:last-child {
    margin-right: 0; }

.AssignmentListItem__subject_mobile {
  display: none; }

.AssignmentListItem__questions {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #E2017B;
  font-size: 0.9375rem;
  min-width: 0; }

.AssignmentListItem__questions_mobile {
  display: none; }

.AssignmentListItem__level {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.8125rem; }

.AssignmentListItem__levelImage {
  margin-right: 10px; }

.AssignmentListItem__moreWrapper {
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 10px; }

.AssignmentListItem__more {
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: url(/static/media/hovered-more.f10d3a5d.svg) no-repeat;
  position: relative;
  cursor: pointer; }
  .AssignmentListItem__more:focus {
    outline: black;
    outline-style: auto; }
  .AssignmentListItem__more:hover {
    background: url(/static/media/more.ff457045.svg) no-repeat; }
    .AssignmentListItem__more:hover:before, .AssignmentListItem__more:hover:after {
      opacity: 1; }

.AssignmentListItem__more_clicked {
  background: url(/static/media/more.ff457045.svg) no-repeat; }

.AssignmentListItem__actionMenu {
  position: absolute;
  right: -1px;
  z-index: 10;
  top: 64%; }

.AssignmentListItem__actionMenu_top {
  top: auto;
  bottom: 64%; }

@media screen and (max-width: 870px) {
  .AssignmentListItem {
    flex-direction: column;
    padding: 15px; }
    .AssignmentListItem:hover {
      background-color: #FFF; }
  .AssignmentListItem__blockMain {
    box-sizing: content-box;
    display: block;
    position: relative;
    padding-left: 109px;
    min-height: 90px;
    border-bottom: 1px solid rgba(89, 106, 117, 0.18);
    padding-bottom: 20px;
    margin-bottom: 63px; }
  .AssignmentListItem__blockSecondary {
    justify-content: space-between; }
  .AssignmentListItem__title {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-y: hidden;
    margin-bottom: 7px;
    font-size: 0.9375rem; }
  .AssignmentListItem__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
    border-radius: 2px; }
  .AssignmentListItem__contentCreatorImage {
    display: none; }
  .AssignmentListItem__likes {
    display: none; }
  .AssignmentListItem__likes_mobile {
    display: flex;
    margin-right: 0; }
  .AssignmentListItem__questions {
    display: none; }
  .AssignmentListItem__questions_mobile {
    display: block; }
  .AssignmentListItem__level {
    display: none; }
  .AssignmentListItem__subjects {
    display: none; }
  .AssignmentListItem__subjects_mobile {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    display: flex;
    margin-top: 11px;
    margin-right: 0;
    justify-content: stretch; }
  .AssignmentListItem__subject {
    text-align: center;
    flex: 1 1;
    padding: 7px 15px 8px 15px; }
  .AssignmentListItem__subject_mobile {
    display: block; }
  .AssignmentListItem__subject_desktop {
    display: none; }
  .AssignmentListItem__more {
    margin-left: 0; }
  .AssignmentListItem__actionMenu {
    top: calc(100% - 24px);
    right: auto;
    left: -1px; }
  .AssignmentListItem__actionMenu_top {
    top: auto;
    bottom: 44px; } }

.MyList {
  margin-top: 20px; }
  .MyList .listItemWrapper .listItem {
    height: 61px;
    border: 1px solid #D3D9DE;
    border-left: none;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 9px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    padding-right: 18px;
    cursor: pointer; }
    .MyList .listItemWrapper .listItem:hover {
      background: #D3D9DE; }
      .MyList .listItemWrapper .listItem:hover .more {
        background: url(/static/media/more.ff457045.svg); }
    .MyList .listItemWrapper .listItem.isAssignmentPreviewVisible {
      background: #d3d9de; }
    .MyList .listItemWrapper .listItem .listImage {
      width: 90px;
      height: 60px;
      margin-right: 20px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      object-fit: cover; }
    .MyList .listItemWrapper .listItem .like {
      padding: 0 8px;
      width: 60px;
      height: 34px;
      background: #E7ECEF;
      border-radius: 8px;
      font-size: 0.75rem;
      margin-right: 20px;
      cursor: pointer; }
      .MyList .listItemWrapper .listItem .like img {
        width: 20px;
        height: 20px; }
    .MyList .listItemWrapper .listItem .assignmentTitle {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin: 0;
      flex-shrink: 10;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .MyList .listItemWrapper .listItem .assignmentStatus {
      color: #596A75;
      margin-left: 4px; }
    .MyList .listItemWrapper .listItem .contentCreatorImg {
      width: 24px;
      margin-right: 20px; }
    .MyList .listItemWrapper .listItem .subject-list {
      display: flex;
      margin-right: 20px; }
      .MyList .listItemWrapper .listItem .subject-list .subject {
        background: #E7ECEF;
        height: 34px;
        border-radius: 17px;
        padding: 7px 18px 8px 21px;
        font-weight: lighter;
        margin-right: 4px;
        border: 1px solid #D3D9DE;
        font-size: 0.9375rem; }
        .MyList .listItemWrapper .listItem .subject-list .subject:last-child {
          margin-right: 0; }
    .MyList .listItemWrapper .listItem .questions {
      text-align: right;
      color: #e2017b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 20px;
      font-size: 0.9375rem; }
    .MyList .listItemWrapper .listItem .levels {
      font-size: 0.8125rem; }
      .MyList .listItemWrapper .listItem .levels img {
        margin-right: 10px; }
  .MyList .listItemWrapper .tooltip-container {
    position: relative; }
  .MyList .listItemWrapper .more {
    cursor: pointer;
    width: 27px;
    height: 27px;
    margin-left: 20px;
    background: url(/static/media/hovered-more.f10d3a5d.svg); }
    .MyList .listItemWrapper .more:hover {
      background: url(/static/media/more.ff457045.svg); }
  .MyList .listItemWrapper .tooltip-block {
    position: relative; }
  .MyList .listItemWrapper .tooltip {
    z-index: 2;
    right: 20px;
    position: absolute;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
    margin-top: 10px; }
  .MyList .listItemWrapper .tooltip .left {
    border-radius: 4px;
    border: 1px solid #596A75;
    padding: 20px;
    cursor: default;
    z-index: 2;
    background: #fff; }
    .MyList .listItemWrapper .tooltip .left ul a {
      padding: 7px;
      white-space: nowrap; }
      .MyList .listItemWrapper .tooltip .left ul a:first-child {
        padding-top: 0; }
      .MyList .listItemWrapper .tooltip .left ul a:last-child {
        padding-bottom: 0; }
      .MyList .listItemWrapper .tooltip .left ul a li {
        justify-content: flex-end;
        cursor: pointer; }
        .MyList .listItemWrapper .tooltip .left ul a li img {
          margin-left: 10px; }
        .MyList .listItemWrapper .tooltip .left ul a li:last-child {
          margin-bottom: 0; }
  .MyList .listItemWrapper i {
    position: absolute;
    top: -6px;
    right: 28px;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    transform: rotate(-90deg); }
    .MyList .listItemWrapper i::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #fff;
      border-right: 1px solid #596A75;
      border-bottom: 1px solid #596A75; }
  .MyList .listItemWrapper .dark {
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(54, 57, 71, 0.3); }
  .MyList .listItemWrapper:hover .more {
    background: url(/static/media/hovered-more.f10d3a5d.svg); }

.noResults.emptyTeachingPaths {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
  height: 100%; }

@media screen and (max-width: 1024px) {
  .mob-container {
    font-size: 0.9375rem;
    display: flex;
    flex-direction: column;
    width: 100%; }
  .MyList .listItemWrapper {
    margin-bottom: 20px !important; }
    .MyList .listItemWrapper .listItem:hover {
      background: none; }
  .MyList .listItemWrapper .more {
    background: url(/static/media/hovered-more.f10d3a5d.svg); }
  .MyList .listItemWrapper .listItem .assignmentTitle {
    margin-bottom: 10px;
    display: block; }
  .MyList .listItemWrapper .listItem .questions {
    margin-top: 10px;
    margin-bottom: 10px; }
  .MyList .listItemWrapper .listItem .subject-list {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0; }
    .MyList .listItemWrapper .listItem .subject-list .subject {
      padding: 0;
      background: #E7ECEF;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .listItem {
    min-height: 195px;
    padding-right: 0px !important; }
  .info {
    display: flex;
    position: relative; }
  .questions {
    text-align: left !important; }
  .stats {
    justify-content: space-between;
    display: flex; }
    .stats .like {
      margin-right: 15px !important; }
    .stats .more {
      margin-left: 15px; }
  .MyList .listItemWrapper .listItem .listImage {
    width: 90px;
    height: 90px;
    object-fit: cover; }
  .MyList .listItemWrapper .listItem {
    height: auto; }
  .MyList .listItemWrapper .tooltip {
    left: 4px;
    right: auto;
    background: #08203F;
    color: #fff !important;
    position: absolute;
    border-radius: 5px; }
  .tooltip-menu {
    align-items: flex-start; }
  .MyList .listItemWrapper .tooltip .left {
    color: #fff !important;
    background: #08203F;
    font-size: 0.8125rem; }
  .MyList .listItemWrapper i {
    left: 20px; }
  .MyList .listItemWrapper .tooltip .left ul a li {
    color: #fff; }
  .MyList .listItemWrapper i::after {
    background: #08203F; } }

.StudentAssignmentsListItem {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #D3D9DE;
  border-radius: 5px;
  position: relative;
  padding: 13px 20px 12px 90px;
  background: #FFF;
  margin-bottom: 10px;
  cursor: pointer; }

.StudentAssignmentsListItem__block {
  display: flex;
  align-items: center;
  min-width: 0; }

.StudentAssignmentsListItem__blockMain {
  flex-shrink: 10; }

.StudentAssignmentsListItem__image {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  right: auto;
  width: 90px;
  height: 60px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.StudentAssignmentsListItem__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-shrink: 10;
  font-size: 0.9375rem;
  margin-left: 20px;
  margin-right: 20px; }

.StudentAssignmentsListItem__subjects {
  display: flex;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-right: 10px; }

.StudentAssignmentsListItem__subject {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-right: 5px;
  font-size: 0.8125rem;
  background: #E7ECEF;
  border: 1px solid #D3D9DE;
  border-radius: 17px;
  padding: 7px 16px 6px 17px; }
  .StudentAssignmentsListItem__subject:last-child {
    margin-right: 0; }

.StudentAssignmentsListItem__questions {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  color: #E2017B;
  margin-right: 20px; }

.StudentAssignmentsListItem__questions_mobile {
  display: none; }

.StudentAssignmentsListItem__deadline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  min-width: 0; }

.StudentAssignmentsListItem__deadlineText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem; }

.StudentAssignmentsListItem__deadlineImage {
  margin-left: 9px; }

.StudentAssignmentsListItem__deadlineText_passed {
  color: #E2017B; }

.StudentAssignmentsListItem__evaluationStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  min-width: 0; }

.StudentAssignmentsListItem__evaluationStatusText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  color: #596A75; }

.StudentAssignmentsListItem__evaluationStatusImage {
  margin-left: 10px; }

.StudentAssignmentsListItem__evaluationStatusText_evaluated {
  color: #0A7B24; }

.StudentAssignmentsListItem__evaluationStatusText_failed {
  color: #E2017B; }

.StudentAssignmentsListItem__answerStatus {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  border-radius: 17px;
  padding: 7px 16px 7px 17px;
  font-size: 0.8125rem;
  background: #D3D9DE;
  border: 1px solid #596A75; }

.StudentAssignmentsListItem__answerStatus_answered {
  color: #FFF;
  background: #0A7B24;
  border: 1px solid #065F1B; }

.StudentAssignmentsListItem__answerStatus_tablet {
  display: none; }

@media screen and (max-width: 1024px) and (min-width: 871px) {
  .StudentAssignmentsListItem {
    padding-left: 160px;
    flex-direction: column;
    padding-bottom: 17px;
    justify-content: center;
    padding-top: 10px; }
  .StudentAssignmentsListItem__imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    right: auto;
    width: 130px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .StudentAssignmentsListItem__blockMain {
    border-bottom: 1px solid rgba(89, 106, 117, 0.2);
    padding-bottom: 11px; }
  .StudentAssignmentsListItem__blockSecondary {
    justify-content: flex-end;
    padding-top: 15px; }
  .StudentAssignmentsListItem__image {
    border-radius: 2px;
    width: 100%;
    height: 100%; }
  .StudentAssignmentsListItem__title {
    margin-left: 0; }
  .StudentAssignmentsListItem__subjects {
    flex-grow: 1; }
  .StudentAssignmentsListItem__answerStatus {
    display: none; }
  .StudentAssignmentsListItem__answerStatus_tablet {
    display: block; }
  .StudentAssignmentsListItem__evaluationStatus {
    margin-right: 0; } }

@media screen and (max-width: 870px) {
  .StudentAssignmentsListItem {
    flex-direction: column;
    padding: 15px;
    height: auto;
    margin-right: 10px; }
  .StudentAssignmentsListItem__blockMain {
    display: block;
    position: relative;
    padding-left: 109px;
    height: 105px;
    border-bottom: 1px solid rgba(89, 106, 117, 0.18); }
  .StudentAssignmentsListItem__blockSecondary {
    position: relative;
    padding-top: 109px; }
  .StudentAssignmentsListItem__questions {
    display: none; }
  .StudentAssignmentsListItem__title {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-y: hidden;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 7px; }
  .StudentAssignmentsListItem__questions_mobile {
    display: block;
    font-size: 0.9375rem; }
  .StudentAssignmentsListItem__image {
    width: 90px;
    height: 90px;
    border-radius: 2px; }
  .StudentAssignmentsListItem__subjects {
    position: absolute;
    right: 0;
    left: 0;
    top: calc(100% + 15px);
    margin-right: 0; }
  .StudentAssignmentsListItem__subject {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    font-size: 0.875rem;
    font-weight: 300; }
  .StudentAssignmentsListItem__answerStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 61px; }
  .StudentAssignmentsListItem__answerStatus_tablet {
    display: none; }
  .StudentAssignmentsListItem__deadline {
    margin-right: 15px; }
  .StudentAssignmentsListItem__evaluationStatus {
    margin-right: 0; } }

.CreateButton {
  height: 40px;
  min-height: 40px;
  background: #0b2541;
  color: #E7ECEF;
  border-radius: 20px;
  padding: 0 23px;
  font-size: 1.0625rem;
  font-weight: 500;
  transition: 0.3s; }
  .CreateButton:hover {
    background: #000;
    color: white; }
  .CreateButton.copyInFlex {
    display: flex;
    align-items: center; }
    .CreateButton.copyInFlex img {
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    .CreateButton.copyInFlex.copyNotInvert img {
      filter: invert(0); }
  .CreateButton.light {
    background: #939FA7;
    color: #0B2541; }
  .CreateButton.green {
    background: #0A7B24;
    color: white; }
  .CreateButton.backButton {
    padding: 0 17px 0 8px;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .CreateButton.backButton div {
      display: flex;
      align-items: center; }
      .CreateButton.backButton div img {
        margin-right: 13px; }
      .CreateButton.backButton div span {
        color: white; }
  .CreateButton:disabled {
    color: #596A75;
    background: #E7ECEF;
    cursor: default; }

@media screen and (max-width: 870px) {
  .CreateButton {
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    min-width: 290px; } }

.statusInfo {
  border-radius: 8px;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  min-height: 50px;
  align-items: center;
  margin: 20px 0; }
  .statusInfo .statusLabel {
    padding: 14px 22px; }
  .statusInfo .mark {
    height: 100%;
    padding: 10px 19px 10px 17px;
    color: white;
    font-size: 1.4375rem;
    display: flex;
    align-items: center; }
  .statusInfo .mark-notPassed {
    background: #E2017B; }
  .statusInfo .mark-passed {
    background: #0A7B24; }

.status-passed {
  border: 1px solid #0A7B24; }

.status-notPassed {
  border: 1px solid #E2017B; }

.status-notEvaluated {
  border: 1px solid #0B2541; }

.evaluationInfo {
  display: flex;
  flex-direction: column;
  width: 380px;
  background: white;
  height: calc(100% - 78px);
  right: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden; }
  .evaluationInfo .contentContainer {
    position: relative;
    flex: 1 1;
    overflow-y: auto;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; }
    .evaluationInfo .contentContainer .close-panel {
      display: none; }
    .evaluationInfo .contentContainer .headerPanel {
      width: 100%;
      height: calc(100vh / 3);
      overflow-x: hidden; }
      .evaluationInfo .contentContainer .headerPanel .imagePlaceholder {
        object-fit: cover;
        width: 100%;
        height: 100%; }
      .evaluationInfo .contentContainer .headerPanel .imageBlock {
        width: 100%;
        height: 100%; }
        .evaluationInfo .contentContainer .headerPanel .imageBlock .shadow {
          background: #0B2541;
          opacity: 0.8;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: calc(100vh / 3); }
      .evaluationInfo .contentContainer .headerPanel .subjectInfo {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        height: calc(100vh / 3);
        padding: 19px;
        overflow: hidden; }
        .evaluationInfo .contentContainer .headerPanel .subjectInfo .subject {
          background: #071E37;
          border-radius: 20px;
          padding: 7px 17px;
          font-size: 0.8125rem;
          color: white;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin-left: auto;
          margin-right: auto; }
        .evaluationInfo .contentContainer .headerPanel .subjectInfo .entityTitle {
          margin: 40px 20px;
          color: white;
          height: 65px;
          font-size: 1.5rem;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .evaluationInfo .contentContainer .headerPanel .subjectInfo .infoTeacher {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .evaluationInfo .contentContainer .headerPanel .subjectInfo .infoTeacher .userPhoto {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-bottom: 10px; }
          .evaluationInfo .contentContainer .headerPanel .subjectInfo .infoTeacher .name {
            color: white;
            font-size: 1.0625rem;
            text-align: center; }
          .evaluationInfo .contentContainer .headerPanel .subjectInfo .infoTeacher .lesson {
            color: white;
            font-size: 0.9375rem;
            font-weight: 300;
            font-style: italic; }
    .evaluationInfo .contentContainer .entityInfo {
      height: 67px;
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .evaluationInfo .contentContainer .entityInfo .deadline {
        display: flex;
        font-size: 0.9375rem;
        align-items: center; }
        .evaluationInfo .contentContainer .entityInfo .deadline img {
          margin-right: 10px; }
      .evaluationInfo .contentContainer .entityInfo .passed {
        color: #E2017B; }
      .evaluationInfo .contentContainer .entityInfo .partsInfo {
        display: flex;
        font-size: 0.9375rem; }
        .evaluationInfo .contentContainer .entityInfo .partsInfo img {
          margin-right: 10px; }
    .evaluationInfo .contentContainer .summary {
      display: flex;
      padding: 20px;
      background: white;
      height: calc(100% - (calc(100% / 3)) - 120px); }
      .evaluationInfo .contentContainer .summary .commentToEntity {
        font-weight: 300;
        overflow: auto;
        max-height: 90px;
        font-size: 1rem; }
      .evaluationInfo .contentContainer .summary .view {
        margin-top: 20px;
        height: auto; }
  .evaluationInfo .answerButton {
    flex: 0 1;
    padding: 20px;
    border-top: 1px solid #D3D9DE;
    width: 100%;
    background-color: #FFF; }

@media screen and (max-width: 1024px) {
  .evaluationInfo .contentContainer .summary .commentToEntity {
    max-height: none; } }

@media screen and (max-width: 870px) {
  .evaluationInfo {
    left: 0;
    right: 0;
    top: 92px;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    height: auto;
    display: block; }
    .evaluationInfo .contentContainer .close-panel {
      display: block;
      height: 35px; }
      .evaluationInfo .contentContainer .close-panel .close-button {
        position: absolute;
        right: 16.41px;
        top: 11.41px;
        width: 12.19px;
        height: 12.19px; }
    .evaluationInfo .contentContainer .headerPanel {
      height: auto;
      position: relative; }
      .evaluationInfo .contentContainer .headerPanel .imageBlock {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
        .evaluationInfo .contentContainer .headerPanel .imageBlock .shadow {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: auto; }
      .evaluationInfo .contentContainer .headerPanel .imagePlaceholder {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .evaluationInfo .contentContainer .headerPanel .subjectInfo {
        position: relative;
        height: auto;
        z-index: 1; }
    .evaluationInfo .contentContainer .entityInfo {
      height: auto; }
    .evaluationInfo .contentContainer .summary {
      height: auto; }
    .evaluationInfo .answerButton {
      position: static; } }

.MyList {
  margin-top: 20px; }
  .MyList .listItemWrapper .listItem {
    padding-right: 20px; }
    .MyList .listItemWrapper .listItem .statBlock .questionsStudent {
      font-size: 0.8125rem; }
  .MyList .listItemWrapper .assignmentTitle {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .MyList .listItemWrapper .subjects {
    margin-left: 20px; }
    .MyList .listItemWrapper .subjects .subject {
      background: #E7ECEF;
      height: 31px;
      border-radius: 17px;
      padding: 7px 16px 8px 17px;
      font-weight: 500;
      margin-right: 4px;
      border: 1px solid #D3D9DE;
      font-size: 0.8125rem; }
      .MyList .listItemWrapper .subjects .subject:last-child {
        margin-right: 0; }
  .MyList .listItemWrapper .questions {
    font-size: 1rem;
    text-align: right;
    color: #E2017B;
    margin-right: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .MyList .listItemWrapper .passedDeadline {
    color: #E2017B; }
  .MyList .listItemWrapper .deadline {
    display: flex;
    font-size: 0.8125rem;
    align-items: center;
    margin-right: 20px; }
    .MyList .listItemWrapper .deadline img {
      width: 20px;
      height: 20px;
      margin-left: 10px; }
  .MyList .listItemWrapper .evaluated-status {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    color: #939FA7;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .MyList .listItemWrapper .evaluated-status img {
      width: 20px;
      height: 20px;
      margin: 0 20px 0 10px; }
    .MyList .listItemWrapper .evaluated-status .passed {
      color: #0A7B24; }
    .MyList .listItemWrapper .evaluated-status .notPassed {
      color: #E2017B; }
  .MyList .listItemWrapper .evaluated-true {
    color: #2D9A46; }
  .MyList .listItemWrapper .evaluated-false {
    color: #E2017B; }
  .MyList .listItemWrapper .answer-status {
    background: #D3D9DE;
    height: 31px;
    border: 1px solid #596A75;
    border-radius: 17px;
    padding: 7px 16px 7px 17px;
    margin-right: 4px;
    color: #0B2541;
    justify-content: center;
    font-size: 0.8125rem;
    font-weight: 500; }
    .MyList .listItemWrapper .answer-status:last-child {
      margin-right: 0; }
  .MyList .listItemWrapper .answered {
    background: #0A7B24;
    border: 1px solid #065F1B;
    color: #ffffff; }

.darkStudentList {
  position: absolute;
  top: 78px;
  left: 0;
  width: calc(100% - 211px);
  height: calc(100% - 78px);
  z-index: 15;
  background: rgba(54, 57, 71, 0.3); }

@media screen and (max-width: 1119px) {
  .MyList .listItemWrapper {
    margin-bottom: 60px;
    background: #fff; }
    .MyList .listItemWrapper .deadline, .MyList .listItemWrapper .evaluated-status {
      margin-right: 0;
      font-size: 0.8125rem; }
  .MyList .listItemWrapper .studentAssignments {
    justify-content: center;
    min-height: 265px; }
  .mob-container {
    display: flex;
    flex-direction: column;
    padding: 15px; }
  .mob-info {
    position: relative;
    padding-left: 120px; }
  .mob-about {
    min-height: 90px; }
  .MyList .listItemWrapper .subjects {
    margin-left: 0;
    border-top: 1px solid #D3D9DE;
    margin-top: 15px;
    padding-top: 15px; }
  .MyList .listItemWrapper .answer-status {
    justify-content: center;
    margin-top: 15px;
    padding: 7px 0; }
  .subject {
    padding: 10px !important;
    border-radius: 20px; }
  .stats {
    display: flex;
    border-top: 1px solid #D3D3D3;
    margin-top: 10px; }
  .stats > * {
    margin-top: 20px; } }

.studentsAnswer {
  border: none;
  font-size: 1.2rem;
  border-bottom: 1px solid #596A75;
  padding-bottom: 20px;
  width: 100%;
  max-height: 450px;
  resize: none;
  font-weight: 300; }
  .studentsAnswer::placeholder {
    color: #596A75;
    font-weight: 300; }
  .studentsAnswer.notline {
    border-bottom: none; }

.evaluationAnswer {
  font-size: 1.0625rem;
  color: #0B2541;
  padding: 0 0 20px 20px;
  border: 0;
  font-weight: 300;
  border-left: 1px solid #D3D9DE; }

.Option {
  transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #596A75;
  border-radius: 5px;
  background: #E7ECEF;
  padding: 6.5px 19px 6.5px 19px;
  margin-bottom: 10px; }
  .Option:focus {
    outline: black;
    outline-style: auto;
    background: white;
    outline-offset: -1px; }

.Option__text {
  word-break: break-all;
  -webkit-hyphens: auto;
          hyphens: auto;
  flex-shrink: 1;
  border: 0;
  background: transparent;
  padding-left: 0;
  min-width: 0;
  padding-right: 10px;
  width: 100%; }

.Option__status {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  border: 1px solid #596A75;
  background: #FFF;
  flex-shrink: 0;
  cursor: pointer; }

.Option__defaultCursor {
  cursor: default; }

.Option.studentViewIsRight {
  background: #D3D9DE;
  border: 1px solid #0B2541; }

.Option.hasError {
  border: 1px solid #E2017B; }

.Option__image {
  margin-top: 1px; }

.Option_selected {
  background: #D3D9DE;
  border-color: #0B2541; }

.light {
  background: #E7ECEF; }

.Option__status_selected {
  background: #0B2541;
  border-color: #0B2541; }

.Option__input_right {
  color: #0A7B24; }

.Option__input_wrong {
  color: #E2017B; }

.ImageChoiceQuestionPreview {
  display: grid;
  grid-template-columns: repeat(auto-fill, 189px);
  grid-gap: 16px;
  justify-content: center; }

.ImageOption {
  transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  position: relative;
  border: 1px solid #D3D9DE;
  border-radius: 4px;
  margin-bottom: 50px; }
  .ImageOption .light {
    background: #D3D9DE; }

.ImageOption_cursorDefault {
  cursor: default !important; }
  .ImageOption_cursorDefault .ImageOption__shape:hover {
    opacity: 0; }

.ImageOption__image {
  width: 100%;
  height: 186px;
  object-fit: contain; }

.ImageOption__shape {
  transition: opacity 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 186px;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 1px;
  cursor: zoom-in;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: rgba(11, 37, 65, 0.2);
  opacity: 0; }
  .ImageOption__shape:hover {
    opacity: 1; }

.ImageOption__title {
  margin: 13px 10px 10px 10px;
  line-height: 17px;
  word-break: break-all; }

.ImageOption__button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 31px !important;
  height: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  padding: 0;
  border: 1px solid #596A75;
  background: #FFF;
  bottom: -51px; }

.ImageOption__button:focus {
  outline: black;
  outline-style: auto; }

.ImageOption_selected {
  background: #D3D9DE; }

.ImageOption__button_selected {
  border-color: #0B2541;
  background: #0B2541; }

.Images .imagesWrapper {
  justify-content: normal;
  margin-bottom: 10px;
  width: 100%;
  overflow: scroll;
  /* height: 200px; */
  height: auto; }
  .Images .imagesWrapper img {
    margin-right: 15px;
    margin-bottom: 10px;
    /* width: calc(33.333% - 10px); */
    width: 100%;
    height: 170px;
    object-fit: cover;
    cursor: zoom-in; }

.Images .centered {
  justify-content: center; }

.DescriptionEditor {
  border: none;
  margin-bottom: 20px;
  width: calc(100% - 110px);
  font-weight: 300; }
  .DescriptionEditor .ql-tooltip {
    border-radius: 5px; }
  .DescriptionEditor .ql-editor {
    padding: 0;
    font-size: 16px; }
  .DescriptionEditor .ql-blank {
    padding: 0; }
    .DescriptionEditor .ql-blank::before {
      font-style: normal;
      font-size: 1.0625rem;
      color: #6c6d7e; }
  .DescriptionEditor .ql-tooltip {
    top: -60px !important; }
  .DescriptionEditor .ql-tooltip-arrow {
    top: 40px !important;
    transform: rotate(180deg); }

.addTextFooter strong {
  margin-right: 10px;
  color: #e2017b; }

.QuestionPreview {
  overflow-y: auto;
  /* width: 60%;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: fit-content; */ }
  .QuestionPreview .largeCounter {
    text-transform: capitalize;
    color: #596A75;
    display: flex;
    width: 100%;
    margin-bottom: 15px; }
  .QuestionPreview .evaluationTitle {
    font-size: 1.0625rem !important;
    font-weight: 500 !important; }
  .QuestionPreview .title {
    display: flex;
    font-size: 2.25rem;
    overflow-wrap: anywhere;
    padding: 20px 20px 20px 0; }
    .QuestionPreview .title .counter {
      border: 2px solid #596A75;
      border-radius: 50%;
      width: 20px;
      min-width: 20px;
      height: 20px;
      font-size: 0.75rem;
      transform: translate(0, 80%);
      margin-right: 20px;
      margin-left: 20px; }
      .QuestionPreview .title .counter span {
        color: #596A75;
        font-weight: 500; }
  .QuestionPreview .mainContent {
    overflow: scroll; }
    .QuestionPreview .mainContent .withoutTopLine {
      margin-top: 0 !important; }
      .QuestionPreview .mainContent .withoutTopLine .questionComment {
        border-top: 1px solid #D3D9DE; }
    .QuestionPreview .mainContent .questionCommentWrapper {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      border-left: 1px solid #D3D9DE; }
      .QuestionPreview .mainContent .questionCommentWrapper .questionComment {
        display: flex;
        flex-direction: column;
        padding-top: 20px; }
        .QuestionPreview .mainContent .questionCommentWrapper .questionComment button {
          color: #E7ECEF;
          min-width: auto;
          padding: 0 23px; }
        .QuestionPreview .mainContent .questionCommentWrapper .questionComment .commentTitle {
          font-weight: 500;
          font-size: 1.0625rem;
          padding-bottom: 10px; }
        .QuestionPreview .mainContent .questionCommentWrapper .questionComment .comment {
          resize: none;
          width: 100%;
          border: none;
          font-weight: 300;
          background: white; }
    .QuestionPreview .mainContent .commentButton {
      margin-top: 24px;
      min-width: auto;
      padding: 0 23px; }
  .QuestionPreview .evaluationContent button {
    margin-top: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.0625rem;
    font-weight: 500; }
  .QuestionPreview .withQuestionCounter {
    padding: 0 60px; }
  .QuestionPreview .videoWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px; }
    .QuestionPreview .videoWrapper .playButton {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center; }
      .QuestionPreview .videoWrapper .playButton img {
        height: 55px;
        width: 55px; }
    .QuestionPreview .videoWrapper .videoView {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column; }
      .QuestionPreview .videoWrapper .videoView video {
        object-fit: cover; }
      .QuestionPreview .videoWrapper .videoView .videoDuration {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0; }
        .QuestionPreview .videoWrapper .videoView .videoDuration .duration {
          align-self: flex-start; }
          .QuestionPreview .videoWrapper .videoView .videoDuration .duration .clockDuration {
            width: 20px;
            height: 20px;
            margin-right: 10px; }

.isEvaluationStyle {
  overflow: unset;
  margin-bottom: 40px; }
  .isEvaluationStyle .title {
    padding-top: 0; }
  .isEvaluationStyle:last-child {
    margin-bottom: 0; }

.attachments-list-container {
  flex: 1 1;
  width: 100%;
  background-color: white;
  z-index: 1;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .attachments-list-container .message {
    flex: 1 1;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.375rem;
    font-weight: 500; }
  .attachments-list-container .attachments-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center; }
    .attachments-list-container .attachments-tabs .attachments-tab:first-child {
      margin-right: 30px; }
    .attachments-list-container .attachments-tabs button {
      background: transparent; }
      .attachments-list-container .attachments-tabs button:focus {
        outline: auto !important;
        padding: 0px; }
    .attachments-list-container .attachments-tabs .wrapper-tabs {
      display: flex;
      padding-top: 20px; }
      .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab {
        cursor: pointer;
        padding-bottom: 16px;
        border-bottom: solid #ffffff 4px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.9375rem; }
        .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab:hover, .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab.selected {
          border-bottom: solid #e2017b 4px; }
        .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab.disabled {
          cursor: not-allowed; }
          .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab.disabled span {
            color: #596A75; }
          .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab.disabled:hover {
            border-bottom: none; }
        .attachments-list-container .attachments-tabs .wrapper-tabs .attachments-tab span {
          font-size: 0.9375rem;
          font-weight: 500; }
    .attachments-list-container .attachments-tabs img {
      width: 28px;
      cursor: pointer; }
  .attachments-list-container .contentWrapper {
    display: flex;
    background: #E7ECEF;
    flex-direction: column;
    height: calc(100% - 130px);
    padding: 20px;
    border-top: 1px solid #D3D9DE; }
    .attachments-list-container .contentWrapper .search-field-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px 18px;
      border: solid #596A75 1px;
      border-radius: 7px;
      background: white;
      box-shadow: 0 2px 4px 0 #d3d9de; }
    .attachments-list-container .contentWrapper .search-input {
      width: 100%;
      border: none;
      font-weight: 300;
      font-size: 0.9375rem; }
    .attachments-list-container .contentWrapper .attachments-list, .attachments-list-container .contentWrapper .skeleton-images-attachments-list, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0 0 0;
      overflow: auto;
      height: 100%; }
      .attachments-list-container .contentWrapper .attachments-list__img-wrap, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader {
        margin: 17px 10px 0;
        width: calc(100% * (1 / 4) - 20px);
        background-color: rgba(0, 0, 0, 0.3);
        height: 200px;
        position: relative; }
        .attachments-list-container .contentWrapper .attachments-list__img-wrap button, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader button {
          padding: 0px;
          height: 100%;
          display: block; }
          .attachments-list-container .contentWrapper .attachments-list__img-wrap button:focus, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader button:focus {
            outline: auto;
            outline-style: auto;
            outline-offset: 3px; }
        .attachments-list-container .contentWrapper .attachments-list__img-wrap.disabled:before, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .disabled.imageSkeletonLoader:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3); }
        .attachments-list-container .contentWrapper .attachments-list__img-wrap.selected, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .selected.imageSkeletonLoader {
          border: #03203f solid 5px; }
          .attachments-list-container .contentWrapper .attachments-list__img-wrap.selected .videoDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .selected.imageSkeletonLoader .videoDuration {
            margin: 10px 0 0 -5px;
            width: calc(100% + 10px); }
        .attachments-list-container .contentWrapper .attachments-list__img-wrap img, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          object-fit: cover;
          background-size: cover; }
        .attachments-list-container .contentWrapper .attachments-list__img-wrap .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader .is-selected-icon {
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
      .attachments-list-container .contentWrapper .attachments-list .videoWrapper, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper {
        display: flex;
        flex-direction: column;
        width: calc(100% / 2 - 10px);
        margin: 10px; }
        .attachments-list-container .contentWrapper .attachments-list .videoWrapper:nth-child(even), .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper:nth-child(even), .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper:nth-child(even) {
          margin-right: 0; }
        .attachments-list-container .contentWrapper .attachments-list .videoWrapper:nth-child(odd), .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper:nth-child(odd), .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper:nth-child(odd) {
          margin-left: 0; }
        .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .videoSkeletonLoader {
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          position: relative;
          cursor: pointer;
          max-height: 100%; }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap.disabled:before, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap.disabled:before, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap.disabled:before, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .disabled.videoSkeletonLoader:before, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .disabled.videoSkeletonLoader:before, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .disabled.videoSkeletonLoader:before, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .disabled.videoSkeletonLoader:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3); }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap.selected, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap.selected, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap.selected, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .selected.videoSkeletonLoader {
            border: #03203f solid 5px; }
            .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap.selected .videoDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap.selected .videoDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap.selected .videoDuration, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader .videoDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader .videoDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .selected.videoSkeletonLoader .videoDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .selected.videoSkeletonLoader .videoDuration {
              margin: 10px 0 0 -5px;
              width: calc(100% + 10px); }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap img, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap img, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader img, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .videoSkeletonLoader img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .videoSkeletonLoader img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            object-fit: cover;
            background-size: cover; }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap .is-selected-icon, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .is-selected-icon, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .videoSkeletonLoader .is-selected-icon {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2; }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap .playButton, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap .playButton, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap .playButton, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .videoSkeletonLoader .playButton {
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center; }
            .attachments-list-container .contentWrapper .attachments-list .videoWrapper .attachments-list__video-wrap .playButton img, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .attachments-list__video-wrap .playButton img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .attachments-list__video-wrap .playButton img, .attachments-list-container .contentWrapper .attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton img, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .css-1y8mk30-SkeletonTheme .videoSkeletonLoader .playButton img, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoWrapper .videoSkeletonLoader .playButton img {
              height: 29px;
              width: 29px; }
        .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px; }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration .duration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration .duration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration .duration {
            align-self: flex-start;
            margin-left: 10px; }
            .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration .duration .clockDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration .duration .clockDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration .duration .clockDuration {
              width: 20px;
              height: 20px;
              margin-right: 10px; }
          .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration .videoDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration .videoDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration .videoDuration {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px; }
            .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration .videoDuration .duration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration .videoDuration .duration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration .videoDuration .duration {
              align-self: flex-start; }
              .attachments-list-container .contentWrapper .attachments-list .videoWrapper .videoDuration .videoDuration .duration .clockDuration, .attachments-list-container .contentWrapper .skeleton-images-attachments-list .videoWrapper .videoDuration .videoDuration .duration .clockDuration, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .videoWrapper .videoDuration .videoDuration .duration .clockDuration {
                width: 20px;
                height: 20px;
                margin-right: 10px; }
    .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme {
      width: calc(25% - 20px);
      margin: 17px 10px 0; }
      .attachments-list-container .contentWrapper .skeleton-images-attachments-list .css-1y8mk30-SkeletonTheme .imageSkeletonLoader {
        margin: 0;
        width: 100%;
        background-color: #eee; }
    .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme {
      width: calc(50% - 10px);
      margin: 10px; }
      .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme:nth-child(even) {
        margin-right: 0; }
      .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme:nth-child(odd) {
        margin-left: 0; }
      .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader {
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: #eee; }
  .attachments-list-container .attachment-info {
    display: flex;
    padding: 0 20px;
    height: 76px;
    align-items: center;
    justify-content: space-between; }
    .attachments-list-container .attachment-info .images-count {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border: solid #939FA7 1px;
      border-radius: 5px;
      background: #E7ECEF;
      padding: 7px 10px;
      font-size: 0.9375rem;
      font-weight: 300; }

.attachments-list__video-wrap button:focus, .attachments-list-container .contentWrapper .skeleton-videos-attachments-list .css-1y8mk30-SkeletonTheme .videoSkeletonLoader button:focus {
  outline: black;
  outline-style: auto; }

.checking {
  display: flex;
  background: white;
  margin-top: -5px;
  cursor: default;
  flex-direction: column;
  border-top: 1px solid #D3D9DE;
  box-shadow: inset 0 1px 1px -1px #D3D9DE; }
  .checking .mainContainer .answerWrapper {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: auto;
    flex: 2 1;
    border-right: 1px solid #D3D9DE; }
    .checking .mainContainer .answerWrapper .readArticlesContainer {
      display: flex;
      flex-direction: column;
      padding: 20px; }
      .checking .mainContainer .answerWrapper .readArticlesContainer .readArticlesContainer__header {
        margin: 0 0 20px 0; }
    .checking .mainContainer .answerWrapper .answers {
      display: flex;
      flex: 2 1;
      padding: 20px;
      flex-direction: column;
      max-height: 528px; }
  .checking .mainContainer .loader {
    height: auto;
    flex: 2 1;
    border-right: 1px solid #D3D9DE; }
  .checking .mainContainer .teachingPathAnswersWrapper {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: auto;
    flex: 2 1;
    border-right: 1px solid #D3D9DE;
    max-height: 528px; }
    .checking .mainContainer .teachingPathAnswersWrapper .teachingPathContainer {
      padding: 20px;
      flex-direction: column;
      width: 100%;
      display: flex;
      flex: 2 1;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content; }
      .checking .mainContainer .teachingPathAnswersWrapper .teachingPathContainer .stepCounterWrapper {
        margin-bottom: 16px;
        display: flex; }
        .checking .mainContainer .teachingPathAnswersWrapper .teachingPathContainer .stepCounterWrapper .stepCounter {
          display: flex;
          color: #FFF;
          justify-content: center;
          align-items: center;
          width: 31px;
          height: 31px;
          background: #E2017B;
          border-radius: 100%; }
        .checking .mainContainer .teachingPathAnswersWrapper .teachingPathContainer .stepCounterWrapper .stepCounterTitle {
          margin-left: 13px; }
      .checking .mainContainer .teachingPathAnswersWrapper .teachingPathContainer .teachingPathNodeQuestions {
        display: flex;
        flex: 2 1;
        padding: 20px 0;
        flex-direction: column; }
  .checking .mainContainer .comments {
    display: flex;
    flex-direction: column;
    min-width: 451px;
    flex: 1 1; }
    .checking .mainContainer .comments .comment {
      display: flex;
      border-bottom: 1px solid #D3D9DE;
      width: 100%;
      flex: 2 1;
      padding: 20px;
      flex-direction: column;
      min-height: 217px; }
      .checking .mainContainer .comments .comment .text {
        resize: none;
        width: 100%;
        border: none;
        font-weight: 300;
        background: white;
        overflow: hidden; }
    .checking .mainContainer .comments .evaluation {
      display: flex;
      flex: 1 1;
      padding: 20px;
      flex-direction: column;
      min-height: 170px; }
      .checking .mainContainer .comments .evaluation .mark {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
    .checking .mainContainer .comments .headline {
      color: #0B2541;
      font-size: 1.0625rem;
      margin-bottom: 21px; }
    .checking .mainContainer .comments .markItem {
      background: #E7ECEF;
      border: 1px solid #939FA7;
      border-radius: 5px;
      height: 32px;
      min-width: 32px;
      padding: 0 12px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-right: 10px;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 0.9375rem;
      margin-bottom: 10px;
      cursor: pointer; }
    .checking .mainContainer .comments .activeMark {
      background: #0A7B24;
      border: 1px solid #065F1B;
      color: white; }
    .checking .mainContainer .comments .failed {
      background: #E2017B;
      border: 1px solid #E2017B; }
  .checking .controlPanel {
    display: flex;
    background: white;
    padding: 20px 10px;
    border-top: 1px solid #D3D9DE;
    box-shadow: 0 2px 5px 0 #d3d9de;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 8px;
    align-items: center;
    justify-content: space-between; }
    .checking .controlPanel .save {
      cursor: pointer;
      background: #E2017B;
      height: 40px;
      border-radius: 40px;
      color: #E7ECEF;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 20px; }

@media screen and (max-width: 1280px) {
  .checking .mainContainer {
    flex-direction: column; }
    .checking .mainContainer .comments .markItem {
      padding: 5px 10px !important;
      margin-right: 7px  !important;
      background: #E7ECEF; }
    .checking .mainContainer .comments .activeMark {
      background: #0A7B24;
      border: 1px solid #065F1B;
      color: white; }
    .checking .mainContainer .comments .failed {
      background: #E2017B;
      border: 1px solid #E2017B; }
    .checking .mainContainer .answerWrapper {
      background: #fff;
      border-radius: 0; } }

@media screen and (max-width: 870px) {
  .checking .controlPanel {
    flex-direction: column-reverse;
    align-items: stretch;
    padding: 15px 15px 20.5px; }
  .checking .mainContainer .comments .evaluation .mark {
    flex-direction: column; }
  .checking .mainContainer .comments .evaluation .markItem {
    width: auto;
    flex: 1 1; }
  .checking .saved {
    align-self: center;
    margin-bottom: 15px; }
  .checking .mainContainer .comments {
    min-width: auto; }
  .checking .mainContainer .answerWrapper {
    border-right: 0; }
    .checking .mainContainer .answerWrapper .answers {
      max-height: auto; } }

.relatedCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  min-height: 60px;
  margin-bottom: 10px;
  padding: 0 25px 0 0;
  border: 1px solid #D3D9DE;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.13);
  cursor: pointer;
  min-width: 0; }
  .relatedCard div {
    display: flex;
    align-items: center;
    min-width: 0; }
  .relatedCard .relatedCard__featuredImage {
    height: 58px;
    min-height: 58px;
    width: 90px;
    object-fit: cover;
    border-radius: 5px 0 0 5px; }
  .relatedCard .relatedCard__title {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    margin-left: 30px; }
  .relatedCard .isChosenTitle {
    color: #0A7B24; }
  .relatedCard .relatedCard__subject {
    margin-right: 10px;
    padding: 7px 18px 8px 21px;
    border-radius: 17px;
    border: 1px solid #D3D9DE;
    background: #E7ECEF;
    font-size: 0.9375rem;
    font-weight: 300; }
    .relatedCard .relatedCard__subject:last-child {
      margin-right: 20px; }
  .relatedCard .relatedCard_questionCounter {
    display: flex;
    align-items: center;
    margin-right: 20px; }
    .relatedCard .relatedCard_questionCounter span {
      color: #E2017B;
      font-size: 0.9375rem; }
  .relatedCard .relatedCard__level {
    font-size: 0.8125rem; }
    .relatedCard .relatedCard__level img {
      margin-right: 10px; }

.withoutCursor {
  cursor: default; }

.isChosen {
  border: 1px solid #0A7B24; }

@media screen and (max-width: 870px) {
  .relatedCard {
    position: relative;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    padding: 15px; }
    .relatedCard .relatedCard__blockMain {
      box-sizing: content-box;
      position: relative;
      padding-left: 100px;
      min-height: 58px;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 15px;
      border-bottom: 1px solid #D3D9DE; }
    .relatedCard .relatedCard_questionCounter {
      margin-bottom: 10px; }
    .relatedCard .relatedCard__blockSecondary {
      flex-direction: column;
      padding-top: 15px;
      align-items: flex-start; }
    .relatedCard .relatedCard__featuredImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 90px;
      height: 58px; }
    .relatedCard .relatedCard__title {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow-y: hidden;
      margin: 0; }
    .relatedCard .relatedCard__subjects {
      align-self: stretch; } }

.MyListPagination {
  margin-top: 10px;
  min-height: 60px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px 0 #d3d9de;
  padding: 0 20px;
  border: 1px solid #D3D9DE; }
  .MyListPagination .controls {
    justify-content: center;
    min-width: 0;
    flex-shrink: 10;
    margin-right: 10px; }
    .MyListPagination .controls:last-child {
      margin-right: 0; }
    .MyListPagination .controls a {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      background: #E7ECEF;
      border-radius: 5px;
      padding: 7px 10px;
      cursor: pointer;
      border: 1px solid #939FA7;
      font-size: 0.9375rem;
      font-weight: 300; }
      .MyListPagination .controls a:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 2px; }
    .MyListPagination .controls:hover {
      background: #d3d9de; }
    .MyListPagination .controls.disabled {
      color: #939FA7;
      cursor: default;
      border: 1px solid #E7ECEF; }
  .MyListPagination .hide {
    display: none; }
  .MyListPagination .linkPageWrapper {
    width: 33px;
    height: 33px;
    margin-right: 10px;
    background: #E7ECEF;
    border: 1px solid #939FA7;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 300; }
    .MyListPagination .linkPageWrapper.activePage {
      background: #0B2541;
      color: white; }
    .MyListPagination .linkPageWrapper:hover {
      background: #d3d9de; }
  .MyListPagination .break {
    background: #E7ECEF;
    border: 1px solid #D3D9DE;
    color: #939FA7; }
  .MyListPagination .linkPage {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    justify-content: center; }
    .MyListPagination .linkPage:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }

@media screen and (max-width: 870px) {
  .MyListPagination {
    margin-bottom: 20px; }
    .MyListPagination .controls {
      width: 33px;
      height: 33px; }
      .MyListPagination .controls a {
        visibility: hidden;
        position: relative; }
        .MyListPagination .controls a::after {
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          visibility: visible;
          position: absolute;
          content: "<";
          font-weight: 600; }
    .MyListPagination .control-next a::after {
      content: ">"; } }

.AssignmentAnswer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px;
  height: 60px;
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 #D3D9DE; }
  .AssignmentAnswer .answeredStatus {
    display: flex;
    margin-right: 20px; }

.AssignmentAnswer__student {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1.0625rem;
  margin-right: 10px; }

.AssignmentAnswer__statusBar {
  display: flex;
  align-items: center;
  justify-content: center; }

.AssignmentAnswer__answeredStatus {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 7px 16px 7px 17px;
  font-size: 0.8125rem;
  border-radius: 50px;
  border: 1px solid #596A75;
  background: #D3D9DE;
  margin-right: 20px; }

.AssignmentAnswer__answeredStatus_answered {
  background: #0A7B24;
  color: #FFF;
  border-color: #0A7B24; }

.AssignmentAnswer__deadline {
  display: flex;
  align-items: center;
  margin-right: 20px; }

.AssignmentAnswer__deadlineText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem; }

.AssignmentAnswer__deadlineText_passed {
  color: #E2017B; }

.AssignmentAnswer__deadlineImage {
  margin-left: 10px; }

.AssignmentAnswer__evaluationStatus {
  display: flex;
  align-items: center; }

.AssignmentAnswer__evaluationStatusText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem; }

.AssignmentAnswer__evaluationStatusText_evaluate {
  color: #939fa7; }

.AssignmentAnswer__evaluationStatusText_passed {
  color: #2D9A46; }

.AssignmentAnswer__evaluationStatusText_failed {
  color: #E2017B; }

.AssignmentAnswer__evaluationStatusImage {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 10px; }

@media screen and (max-width: 870px) {
  .AssignmentAnswer {
    align-items: stretch;
    justify-content: flex-start;
    height: auto;
    padding: 15px 15px 19px 15px;
    flex-direction: column; }
  .AssignmentAnswer__student {
    margin-right: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #D3D9DE; }
  .AssignmentAnswer__statusBar {
    padding-top: 10px; }
  .AssignmentAnswer__answeredStatus {
    display: none; }
  .AssignmentAnswer__deadline {
    display: none; }
  .AssignmentAnswer__evaluationStatus {
    justify-content: space-between;
    width: 100%; } }

.AnswerList {
  width: 100%;
  min-height: 100%;
  background: #E7ECEF;
  z-index: 2;
  padding-top: 1px;
  padding-right: 2px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

.AnswerList__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; }

.AnswerList__pagination {
  margin-bottom: 10px; }

.AnswerList__title {
  color: #E2017B;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1rem; }

.AnswerList__breadcrumbs ul a {
  color: #e2017b;
  text-transform: capitalize; }

.AnswerList__breadcrumbs ul p {
  margin: 0px;
  color: #868686; }

.AnswerList__breadcrumbs ul li.separator {
  color: #868686;
  margin: 0px 10px; }

.AnswerList__breadcrumbs ul {
  display: flex;
  align-items: center;
  font-size: 18px; }

.AnswerList__entityTitle {
  font-size: 2.25rem;
  margin-bottom: 20px; }

.AnswerList__item {
  margin-bottom: 20px; }

@media screen and (max-width: 870px) {
  .AnswerList__entityTitle {
    font-size: 1.5rem; } }

@media screen and (max-width: 640px) {
  .AnswerList {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px; }
  .AnswerList__entityTitle {
    margin-bottom: 48px; } }

.AssignmentAnswerList {
  width: 100%;
  min-height: 100%;
  background: #E7ECEF;
  z-index: 2;
  padding-top: 1px;
  padding-right: 2px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

.AssignmentAnswerList__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; }

.AssignmentAnswerList__pagination {
  margin-bottom: 10px; }

.AssignmentAnswerList__title {
  color: #E2017B;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1rem; }

.AssignmentAnswerList__assignmentTitle {
  font-size: 2.25rem;
  margin-bottom: 20px; }

.AssignmentAnswerList__item {
  margin-bottom: 20px; }

@media screen and (max-width: 870px) {
  .AssignmentAnswerList__assignmentTitle {
    font-size: 1.5rem; } }

@media screen and (max-width: 640px) {
  .AssignmentAnswerList {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px; }
  .AssignmentAnswerList__assignmentTitle {
    margin-bottom: 48px; } }

.leftside {
  flex: 3 1;
  margin-bottom: 20px; }
  .leftside .titleEvaluation {
    margin: 40px 0 10px;
    color: #E2017B;
    text-transform: uppercase;
    font-size: 1rem; }
  .leftside .titleAssignment {
    font-size: 2.25rem;
    margin-bottom: 20px; }
  .leftside .contentQuestions {
    background: white;
    padding: 19px 27px 19px 19px;
    border-radius: 4px;
    border: 1px solid #D3D9DE; }

.rightside {
  flex: 2 1;
  background: white;
  margin-left: 20px;
  box-shadow: 0 2px 5px 0 #d3d9de;
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .rightside .comments {
    display: flex;
    flex-direction: column; }
    .rightside .comments .comment {
      display: flex;
      border-bottom: 1px solid #D3D9DE;
      width: 100%;
      padding: 20px 20px 25px 20px;
      flex-direction: column; }
    .rightside .comments .evaluation {
      display: flex;
      padding: 22px 20px 0;
      flex-direction: column; }
    .rightside .comments .headline {
      color: #0B2541;
      font-weight: bold;
      font-size: 1.125rem; }
    .rightside .comments .withPadding {
      padding-bottom: 21px; }

.container-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #E7ECEF;
  width: calc(100vw - 211px);
  height: calc(100vh - 78px);
  left: 211px;
  top: 0;
  position: absolute; }
  .container-404 .notFountTitle {
    margin-top: 30px;
    font-size: 1.375rem; }
  .container-404 .notFoundDescription {
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center; }
  .container-404 img {
    width: 93px;
    height: 93px; }
  .container-404 .home {
    background: #0B2541;
    color: #fff;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 1.0625rem; }

.previewModalInfoSearch {
  position: fixed;
  top: 78px;
  width: 100%;
  height: calc(100% - 78px);
  z-index: 20;
  left: 0; }
  .previewModalInfoSearch.hidden {
    display: none; }
  .previewModalInfoSearch__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(54, 57, 71, 0.3);
    z-index: 1; }
  .previewModalInfoSearch__content {
    position: absolute;
    height: 100%;
    width: 780px;
    background: white;
    right: 0;
    z-index: 2; }
    .previewModalInfoSearch__content .headerButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 12px; }
      .previewModalInfoSearch__content .headerButtons .previewButtons {
        position: relative;
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .functionsButtons {
        position: relative;
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .DistributeButtons {
        margin-left: 15px; }
      .previewModalInfoSearch__content .headerButtons .modalContent {
        border: 1px solid #D3D9DE;
        border-top: 0;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 #ffffff1c;
        position: absolute;
        z-index: 2;
        background: white;
        padding: 12px 20px;
        right: 0px;
        padding-top: 17px;
        min-width: 220px; }
        .previewModalInfoSearch__content .headerButtons .modalContent button {
          background: transparent;
          display: block;
          margin-bottom: 5px;
          text-align: left;
          white-space: nowrap; }
        .previewModalInfoSearch__content .headerButtons .modalContent .actionboldButton button {
          font-size: 17px;
          font-weight: 500;
          display: block !important;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px !important;
          width: 100% !important;
          padding: 0px; }
        .previewModalInfoSearch__content .headerButtons .modalContent .actionboldButton.actionAfter button:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: solid 2px #0b2541;
          border-right: solid 2px #0b2541;
          transform: rotate(-45deg);
          top: 5px;
          right: 3px; }
        .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite {
          display: block;
          position: relative;
          margin-top: 8px;
          margin-bottom: 8px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-bottom: solid 2px #0b2541;
            border-right: solid 2px #0b2541;
            transform: rotate(-45deg);
            top: 5px;
            right: 0px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .linkOpenSite.LinkRollback:after {
            display: none; }
        .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside {
          position: absolute;
          top: 0;
          border: 1px solid #D3D9DE;
          border-top: 0;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #ffffff1c;
          background: white;
          padding: 12px 20px;
          width: 100%;
          left: 0;
          min-height: 100%;
          pointer-events: none;
          opacity: 0;
          transition: 0.3s;
          z-index: 2; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside.active {
            pointer-events: all;
            opacity: 1; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 {
            display: block;
            margin: 0px;
            font-size: 17px;
            border-bottom: solid 1px #0000001c;
            padding-bottom: 5px;
            margin-bottom: 5px; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 a {
              display: block;
              width: 100%;
              position: relative;
              padding-left: 20px;
              font-size: 17px; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside h2 a:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-bottom: solid 2px #0b2541;
                border-left: solid 2px #0b2541;
                transform: rotate(45deg);
                top: 5px;
                left: 0px; }
          .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li {
            margin-bottom: 10px;
            margin-top: 10px; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li a {
              display: block;
              position: relative; }
            .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex p {
                margin: 0px; }
              .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div {
                display: flex; }
                .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div svg {
                  display: block;
                  border: solid 1px #979797;
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  padding: 5px; }
                  .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div svg path {
                    fill: #061626; }
                .previewModalInfoSearch__content .headerButtons .modalContent .modalContentInside ul li.itemListFlex div a {
                  margin-left: 7px; }
      .previewModalInfoSearch__content .headerButtons .modalToggle {
        border: solid 2px #d3d9de;
        border-radius: 20px;
        padding: 7px 16px;
        display: block;
        min-width: 100px;
        text-align: center;
        color: #0b2541; }
        .previewModalInfoSearch__content .headerButtons .modalToggle.active {
          border: solid 2px #0b2541; }
        .previewModalInfoSearch__content .headerButtons .modalToggle:after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-left: solid 2px #0b2541;
          border-bottom: solid 2px #0b2541;
          transform: rotate(-45deg);
          margin-left: 8px;
          top: -3px;
          position: relative; }
    .previewModalInfoSearch__content .NewTitleHeader {
      background: #0b2541;
      padding-top: 26px;
      padding-bottom: 27px; }
      .previewModalInfoSearch__content .NewTitleHeader h2 {
        margin: 0px;
        text-align: center;
        color: white;
        font-size: 36px;
        font-weight: 400; }
    .previewModalInfoSearch__content .entityInfo {
      height: 67px;
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .previewModalInfoSearch__content .entityInfo .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px; }
        .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoAutor p {
          white-space: nowrap;
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden; }
        .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul {
          display: flex; }
          .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li {
            margin-right: 5px; }
            .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:after {
              content: ','; }
            .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:last-child {
              margin-right: 0; }
              .previewModalInfoSearch__content .entityInfo .partsInfo.partsInfoList ul li:last-child:after {
                display: none; }
        .previewModalInfoSearch__content .entityInfo .partsInfo img {
          margin-right: 10px;
          max-width: 22px; }
    .previewModalInfoSearch__content .entityDescription {
      /* height: 67px; */
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: left;
      justify-content: left;
      height: auto !important; }
      .previewModalInfoSearch__content .entityDescription .partsInfoDescription {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        font-size: 17px; }
    .previewModalInfoSearch__content .summary {
      display: block;
      padding: 20px;
      background: white;
      overflow: auto;
      height: calc(100vh - 385px) !important; }
      .previewModalInfoSearch__content .summary .entityInfoBlock {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .imageGrep {
          margin-right: 10px; }
          .previewModalInfoSearch__content .summary .entityInfoBlock .imageGrep .imgInfo {
            width: 21px;
            height: 21px; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .title {
          color: #0b2541;
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .listItem .item {
          margin: 0;
          padding: 0;
          text-decoration: none;
          display: inline-block;
          background-color: #d3d9de;
          border-radius: 4px;
          color: #0b2541;
          font-size: 13px;
          font-weight: 300;
          padding: 4px;
          margin: 5px 5px 5px 0px; }
      .previewModalInfoSearch__content .summary .entityInfoBlockExpanded {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .imageGrep {
          margin-right: 10px; }
          .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .imageGrep .imgInfo {
            width: 21px;
            height: 21px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .title {
          color: #0b2541;
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          /* width: 56px; */
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%; }
      .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem {
        margin: 0px 44px 0px 44px; }
        .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData {
          display: flex;
          font-size: 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.0901961);
          padding: 10px 0;
          color: #0a003d;
          font-weight: 300; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData__grade {
            margin-right: 20px;
            min-width: 110px;
            text-decoration: none; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData__subject {
            margin-right: 20px;
            min-width: 150px; }

@media screen and (max-width: 1001px) {
  .previewModalInfoSearch__content {
    width: calc(100% - 50px); }
    .previewModalInfoSearch__content .headerButtons {
      flex-wrap: wrap; }
      .previewModalInfoSearch__content .headerButtons .modalToggle {
        min-width: 90px;
        padding: 7px 10px; }
    .previewModalInfoSearch__content .NewTitleHeader {
      padding-top: 16px;
      padding-bottom: 17px; }
      .previewModalInfoSearch__content .NewTitleHeader h2 {
        font-size: 25px; }
    .previewModalInfoSearch__content .entityInfo {
      flex-wrap: wrap;
      height: auto;
      padding: 10px 0px;
      padding-top: 5px;
      justify-content: flex-start; }
    .previewModalInfoSearch__content .entityDescription {
      padding: 10px 0px; }
    .previewModalInfoSearch__content .summary {
      padding: 10px; }
      .previewModalInfoSearch__content .summary .entityInfoBlock {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
        overflow: hidden; }
        .previewModalInfoSearch__content .summary .entityInfoBlock .title {
          max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .entityInfoBlockExpanded {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0px; }
        .previewModalInfoSearch__content .summary .entityInfoBlockExpanded .title {
          max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .listItem {
        min-height: auto !important;
        max-width: calc(100% - 44px); }
      .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem {
        margin-left: 44px;
        margin-right: 0px; }
        .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData {
          display: block; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData .goalData__grade {
            font-weight: 700;
            margin-bottom: 5px;
            margin-right: 0px; }
          .previewModalInfoSearch__content .summary .flexContainerExpanded .listItem .goalData .goalData__subject {
            margin-right: 0px;
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 14px; } }

.InfoCard {
  width: 282px;
  height: 374px;
  min-height: 374px;
  box-sizing: content-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.100661);
  border-radius: 5px;
  transition: 0.5s; }
  .InfoCard button {
    padding: 0;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    position: relative;
    overflow: hidden; }
    .InfoCard button:focus {
      outline: auto;
      outline-style: auto;
      outline-offset: 2px; }
  .InfoCard .toggleReadDescription {
    margin-top: 5px; }
    .InfoCard .toggleReadDescription .toggleRead {
      display: flex;
      align-items: center; }
      .InfoCard .toggleReadDescription .toggleRead:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 3px; }
      .InfoCard .toggleReadDescription .toggleRead img {
        width: 18px;
        margin-right: 5px;
        transform: rotate(-90deg); }
      .InfoCard .toggleReadDescription .toggleRead p {
        font-size: 13px;
        font-weight: 700;
        margin: 0px; }
  .InfoCard .actionButtons {
    position: absolute;
    top: -13px;
    left: 107px;
    z-index: 2; }
    .InfoCard .actionButtons button {
      padding: 0px;
      background: transparent; }
      .InfoCard .actionButtons button:focus {
        outline: black;
        outline-style: auto; }
    .InfoCard .actionButtons img {
      width: 26px;
      height: 26px;
      cursor: pointer; }
      .InfoCard .actionButtons img:first-child {
        margin-right: 5px; }
      .InfoCard .actionButtons img:last-child {
        margin-left: 5px; }
    .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem {
      width: 36px;
      text-align: center; }
      .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem button {
        background: white;
        border: solid 1px #728089;
        border-radius: 50%;
        width: 26px;
        height: 26px; }
        .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem button img {
          width: 15px;
          height: 15px; }
  .InfoCard .cardImage {
    width: 100%;
    height: 185px;
    border-radius: 5px 5px 0 0;
    object-fit: cover; }
  .InfoCard .cardInfo {
    height: auto;
    position: relative;
    padding: 20px; }
    .InfoCard .cardInfo .cardTitle {
      flex: 1 1;
      min-width: 0; }
      .InfoCard .cardInfo .cardTitle .noTitle {
        color: #939FA7; }
      .InfoCard .cardInfo .cardTitle .isDraft {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: #596A75;
        margin-left: 6px; }
      .InfoCard .cardInfo .cardTitle p {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow-y: hidden;
        margin: 0;
        font-size: 1.05rem;
        font-weight: 500;
        margin-bottom: 5px;
        overflow: hidden; }
      .InfoCard .cardInfo .cardTitle .levelIcon {
        margin: 0; }
    .InfoCard .cardInfo .withTooltip {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      position: relative;
      align-items: center; }
      .InfoCard .cardInfo .withTooltip.AbsolutePosition {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-top: 0px; }
      .InfoCard .cardInfo .withTooltip .OptionMsj {
        position: relative;
        background: transparent;
        width: auto;
        /*&:before {
          position: absolute;
          box-shadow: 0 2px 2px #00000021;
          margin-top: 20px;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          border-radius: 4px;
          border: 1px solid #596a75;
          padding: 20px;
          cursor: default;
          background: #596a75;
          content: attr(data-msj);
          right: -2px;
          top: 18px;
          opacity: 0;
          pointer-events: none;
          z-index: 1;
          color: white;
          font-size: 1.0625rem;
        }
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #596a75;
          right: 0px;
          -webkit-transform: translate(-50%,-50%) rotate(-45deg);
          transform: translate(-50%,-50%) rotate(-45deg);
          background-color: #596a75;
          border-right: 1px solid #596a75;
          border-top: 1px solid #596a75;
          top: 38px;
          z-index: 1;
          opacity: 0;
          pointer-events: none;
        }*/ }
        .InfoCard .cardInfo .withTooltip .OptionMsj:focus {
          outline: black;
          outline-style: auto; }
        .InfoCard .cardInfo .withTooltip .OptionMsj:hover:before, .InfoCard .cardInfo .withTooltip .OptionMsj:hover:after {
          opacity: 1; }
      .InfoCard .cardInfo .withTooltip .defaultIcons {
        margin: 0;
        align-items: center; }
      .InfoCard .cardInfo .withTooltip img {
        height: 23px;
        width: 23px; }
      .InfoCard .cardInfo .withTooltip .tooltip {
        z-index: 2;
        right: -15px;
        position: absolute;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
        margin-top: 20px;
        top: 16px;
        width: -webkit-max-content;
        width: max-content; }
        .InfoCard .cardInfo .withTooltip .tooltip .left {
          border-radius: 4px;
          border: 1px solid #939FA7;
          padding: 15px;
          cursor: default;
          z-index: 2;
          background: #fff; }
          .InfoCard .cardInfo .withTooltip .tooltip .left ul li {
            cursor: pointer;
            margin: 7px; }
            .InfoCard .cardInfo .withTooltip .tooltip .left ul li a:focus {
              outline: black;
              outline-style: auto; }
          .InfoCard .cardInfo .withTooltip .tooltip .left ul .disabled {
            color: #939FA7;
            cursor: default; }
        .InfoCard .cardInfo .withTooltip .tooltip i {
          position: absolute;
          top: -6px;
          right: 20px;
          margin-top: -12px;
          width: 12px;
          height: 24px;
          transform: rotate(-90deg); }
          .InfoCard .cardInfo .withTooltip .tooltip i::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            background-color: #fff;
            border-right: 1px solid #939fa7;
            border-bottom: 1px solid #939fa7; }
    .InfoCard .cardInfo .withoutTooltip {
      display: flex;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-left: 10px;
      margin-top: 0; }
    .InfoCard .cardInfo .defaultIcons {
      height: 23px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px; }
      .InfoCard .cardInfo .defaultIcons img {
        width: 20px;
        height: 20px; }
      .InfoCard .cardInfo .defaultIcons .tpIcon {
        width: 23px;
        height: 23px;
        margin-right: 5px; }
      .InfoCard .cardInfo .defaultIcons .flexBox {
        align-items: center; }
        .InfoCard .cardInfo .defaultIcons .flexBox:focus-within {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
      .InfoCard .cardInfo .defaultIcons span {
        font-size: 14px;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        max-width: 220px; }
      .InfoCard .cardInfo .defaultIcons .levels {
        margin-right: 10px;
        font-size: 0.9375rem;
        align-items: center;
        width: -webkit-max-content;
        width: max-content; }
        .InfoCard .cardInfo .defaultIcons .levels img {
          margin: 0 7px 0 0; }
    .InfoCard .cardInfo .numberOfQuestions {
      font-size: 0.8rem;
      color: #E2017B;
      font-weight: bold; }
    .InfoCard .cardInfo .cardDescription {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow-y: hidden;
      margin-top: 5px;
      font-size: 0.9375rem;
      font-weight: 300; }
    .InfoCard .cardInfo .ContentcardDescription {
      margin-top: 5px; }
      .InfoCard .cardInfo .ContentcardDescription .cardDescription {
        margin-top: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: 0.3s; }
        .InfoCard .cardInfo .ContentcardDescription .cardDescription.active {
          max-height: 70px;
          margin-bottom: 15px; }
      .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead {
        display: inline-flex;
        align-items: center; }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead:focus {
          outline: auto;
          outline-style: auto;
          outline-offset: 2px; }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead img {
          width: 18px;
          margin-right: 5px;
          transform: rotate(-90deg); }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead.active img {
          transform: rotate(90deg); }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead p {
          font-size: 13px;
          font-weight: 700;
          margin: 0px; }
    .InfoCard .cardInfo .buttonRead {
      margin-top: 25px; }
      .InfoCard .cardInfo .buttonRead .btn {
        background: #0b2541;
        border-radius: 20px;
        color: white;
        padding: 10px;
        max-width: 170px; }
        .InfoCard .cardInfo .buttonRead .btn.btnRead {
          background: #0a7b24; }
        .InfoCard .cardInfo .buttonRead .btn:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
    .InfoCard .cardInfo .grades {
      flex-wrap: wrap;
      margin-top: 4px; }
      .InfoCard .cardInfo .grades > div {
        max-width: 31%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px;
        margin: 0 5px;
        background: #D3D9DE;
        border-radius: 4px;
        font-size: 0.8125rem;
        margin-left: 0;
        font-weight: 300; }
    .InfoCard .cardInfo .gradesLineInside div:last-child:after {
      display: none; }
    .InfoCard .cardInfo .gradesLineInside div:after {
      content: ', ';
      margin-right: 5px; }
    .InfoCard .cardInfo .gradesLineInside {
      align-items: center;
      margin-right: 5px; }
    .InfoCard .cardInfo .gradesLine p {
      margin: 0px; }
    .InfoCard .cardInfo .gradesLine {
      align-items: center;
      font-size: 14px; }
    .InfoCard .cardInfo .footerCardInfo {
      justify-content: space-between;
      align-items: center; }

.tpIcon-DOMAIN {
  cursor: pointer; }

@media screen and (max-width: 1024px) {
  .InfoCard .cardInfo .withTooltip .tooltip .left {
    background: #0B2541;
    border-color: #0B2541; }
    .InfoCard .cardInfo .withTooltip .tooltip .left ul li {
      color: #FFF; }
  .InfoCard .cardInfo .withTooltip .tooltip i::after {
    background-color: #0B2541;
    border-color: #0B2541; } }

.teachingPathsList {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .teachingPathsList .gradesChildrenItems {
    margin-top: 15px; }
  .teachingPathsList .cardList {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    width: auto;
    overflow: auto;
    margin-top: 10px;
    justify-content: start;
    padding-top: 5px;
    transition: 0.3s;
    padding-left: 5px; }
    .teachingPathsList .cardList .InfoCard {
      width: calc(100% / 7 - 30px);
      margin-bottom: 20px;
      margin-right: 30px;
      height: auto;
      min-height: auto; }
      .teachingPathsList .cardList .InfoCard:hover {
        box-shadow: 0 8px 10px #00000061; }
      .teachingPathsList .cardList .InfoCard .cardTitle {
        padding-right: 28px; }
      .teachingPathsList .cardList .InfoCard .cardDescription {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow-y: hidden;
        margin-top: 10px; }
      .teachingPathsList .cardList .InfoCard .grades {
        height: 25px; }
  .teachingPathsList .noResults.emptyTeachingPaths {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300; }
  .teachingPathsList.moveListBySearchFilter {
    transition: 0.3s; }
    .teachingPathsList.moveListBySearchFilter.active {
      width: calc(100% - 290px);
      margin-left: 290px; }
      .teachingPathsList.moveListBySearchFilter.active .InfoCard {
        width: calc(100%/ 7 - 10px);
        margin-right: 10px; }

.css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  height: 300px;
  box-shadow: 0px 3px 4px #00000033; }

.css-1y8mk30-SkeletonTheme {
  width: calc(25% - 20px);
  height: 300px;
  margin-right: 20px; }
  .css-1y8mk30-SkeletonTheme span {
    width: 100% !important;
    height: 250px !important; }

.AssignmentsPage .assignmentList .css-1y8mk30-SkeletonTheme {
  width: 100%;
  height: 70px; }

.AssignmentsPage .assignmentList .css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  height: 60px !important; }

@media screen and (max-width: 2350px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 7 - 10px);
    margin-right: 10px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 6 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 2150px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 6 - 20px);
    margin-right: 20px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 5 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 2000px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 6 - 10px);
    margin-right: 10px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 5 - 10px);
    margin-right: 10px; } }

@media screen and (max-width: 1800px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 5 - 20px);
    margin-right: 20px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100%/ 4 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 1600px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 4 - 20px);
    margin-right: 20px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 4 - 10px);
    margin-right: 10px; } }

@media screen and (max-width: 1500px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 4 - 10px);
    margin-right: 10px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 1200px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 2 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 1100px) {
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 2 - 10px);
    margin-right: 10px; } }

@media screen and (max-width: 1024px) {
  .teachingPathsList.moveListBySearchFilter.active {
    width: 100%;
    margin-left: 0px; }
  .teachingPathsList.moveListBySearchFilter.active .InfoCard {
    width: calc(100% / 3 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 720px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 2 - 20px);
    margin-right: 20px; } }

@media screen and (max-width: 600px) {
  .teachingPathsList .cardList .InfoCard {
    width: calc(100% / 2 - 10px);
    margin-right: 10px; } }

@media screen and (max-width: 575px) {
  .teachingPathsList {
    height: auto; }
    .teachingPathsList .cardList {
      flex-flow: nowrap;
      width: calc(100% + 19px); }
      .teachingPathsList .cardList .InfoCard {
        width: calc(100% - 50px);
        min-height: auto;
        flex: none;
        margin-right: 10px;
        margin-bottom: 5px;
        height: auto; }
        .teachingPathsList .cardList .InfoCard .cardImage {
          height: auto; }
        .teachingPathsList .cardList .InfoCard:hover {
          box-shadow: none; }
  .teachingPathsList.moveListBySearchFilter.active .cardList .InfoCard {
    width: calc(100% - 50px); } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  /*.teachingPathsList .cardList .InfoCard {
    width: calc(50% - 15px);
  }
  .teachingPathsList.moveListBySearchFilter.active .cardList .InfoCard {
    width: calc(50% - 15px);
  }*/ }


.StudentEvaluationPage__container {
  display: flex; }

.StudentEvaluationPage__leftSide {
  flex: 3 1;
  display: flex;
  flex-direction: column; }

.StudentEvaluationPage__evaluation {
  margin: 40px 0 10px;
  color: #E2017B;
  text-transform: uppercase;
  font-size: 1rem; }

.StudentEvaluationPage__teachingPathTitle {
  font-size: 2.25rem;
  margin-bottom: 20px; }

.loader {
  height: auto;
  flex: 2 1;
  border-right: 1px solid #D3D9DE;
  background-color: #FFF; }

.StudentEvaluationPage__leftLoader {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center; }

.StudentEvaluationPage__rightLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2 1;
  height: 100%;
  background: white;
  box-shadow: 0 2px 5px 0 #d3d9de;
  border-radius: 5px; }

.StudentEvaluationPage__steps {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px 0 #d3d9de;
  border-radius: 5px;
  background-color: #FFF; }

.StudentEvaluationPage_stepInfo {
  width: 100%;
  padding: 20px;
  flex-direction: column;
  display: flex;
  flex: 2 1;
  background-color: #FFF; }
  .StudentEvaluationPage_stepInfo:first-child {
    border-radius: 5px 5px 0 0; }
  .StudentEvaluationPage_stepInfo:last-child {
    border-radius: 0 0 5px 5px; }
  .StudentEvaluationPage_stepInfo .stepCounterWrapper {
    margin-bottom: 16px;
    display: flex; }
    .StudentEvaluationPage_stepInfo .stepCounterWrapper .stepCounter {
      display: flex;
      color: #FFF;
      justify-content: center;
      align-items: center;
      width: 31px;
      height: 31px;
      background: #E2017B;
      border-radius: 100%; }
    .StudentEvaluationPage_stepInfo .stepCounterWrapper .stepCounterTitle {
      margin-left: 13px; }
  .StudentEvaluationPage_stepInfo .teachingPathNodeQuestions {
    display: flex;
    flex: 2 1;
    padding: 20px 0;
    flex-direction: column; }

.comments {
  display: flex;
  flex-direction: column;
  min-width: 451px;
  flex: 1 1; }
  .comments .comment {
    display: flex;
    border-bottom: 1px solid #D3D9DE;
    width: 100%;
    flex: 2 1;
    padding: 20px;
    flex-direction: column; }
    .comments .comment .text {
      resize: none;
      width: 100%;
      border: none;
      font-weight: 300;
      background: white;
      overflow: hidden; }
  .comments .evaluation {
    display: flex;
    flex: 1 1;
    padding: 20px;
    flex-direction: column; }
    .comments .evaluation .mark {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
  .comments .headline {
    color: #0B2541;
    font-size: 1.0625rem;
    margin-bottom: 21px; }
  .comments .markItem {
    background: #E7ECEF;
    border: 1px solid #939FA7;
    border-radius: 5px;
    height: 32px;
    min-width: 32px;
    padding: 0 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 0.9375rem;
    margin-bottom: 10px;
    cursor: pointer; }
  .comments .activeMark {
    background: #0A7B24;
    border: 1px solid #065F1B;
    color: white; }
  .comments .failed {
    background: #E2017B;
    border: 1px solid #E2017B; }

.StudentEvaluationPage__rightSide {
  display: flex;
  flex-direction: column;
  flex: 2 1;
  background: white;
  margin-left: 20px;
  box-shadow: 0 2px 5px 0 #d3d9de;
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .StudentEvaluationPage__rightSide .comments {
    display: flex;
    flex-direction: column; }
    .StudentEvaluationPage__rightSide .comments .comment {
      display: flex;
      border-bottom: 1px solid #D3D9DE;
      width: 100%;
      padding: 20px 20px 25px 20px;
      flex-direction: column; }
    .StudentEvaluationPage__rightSide .comments .evaluation {
      display: flex;
      padding: 22px 20px 0;
      flex-direction: column; }
    .StudentEvaluationPage__rightSide .comments .headline {
      color: #0B2541;
      font-weight: bold;
      font-size: 1.125rem; }
    .StudentEvaluationPage__rightSide .comments .withPadding {
      padding-bottom: 21px; }

.EvaluationPage {
  height: 100%;
  display: flex;
  flex-direction: column; }

@media screen and (max-width: 575px) {
  .EvaluationPage {
    height: auto; }
  .AssignmentDistributeList {
    display: flex; }
  .AssignmentDistributeItem {
    margin-right: 10px;
    margin-bottom: 5px; } }

.AssignmentDistribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 10px;
  height: 60px;
  background: #FFF;
  border: 1px solid #D3D9DE;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.13);
  padding: 14px 20px 13px 110px;
  cursor: pointer; }
  .AssignmentDistribute:hover {
    background: #D3D9DE; }

.AssignmentDistribute__block {
  display: flex;
  align-items: center;
  min-width: 0; }

.AssignmentDistribute__textBlock {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 0.9375rem; }

.AssignmentDistribute__imageContainer {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  background: #596A75;
  border-radius: 5px 0 0 5px;
  overflow: hidden; }

.AssignmentDistribute__image {
  object-fit: cover; }

.AssignmentDistribute__image_fullSize {
  width: 100%;
  height: 100%; }

.AssignmentDistribute__title {
  font-size: 1.0625rem;
  flex-shrink: 1; }

.AssignmentDistribute__subjects {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 0; }

.AssignmentDistribute__subject {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background: #E7ECEF;
  border: 1px solid #D3D9DE;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 7px 18px 8px 21px;
  margin-right: 5px;
  min-width: 0;
  font-size: 0.9375rem; }
  .AssignmentDistribute__subject:last-child {
    margin-right: 0; }

.AssignmentDistribute__subject_mobile {
  display: none; }

.AssignmentDistribute__deadline {
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 0; }

.AssignmentDistribute__deadlineText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-right: 9px; }

.AssignmentDistribute__questions {
  color: #E2017B;
  margin-right: 20px; }

.AssignmentDistribute__questions_mobile {
  display: none; }

.AssignmentDistribute__deadlineText_passed {
  color: #596A75; }

@media screen and (max-width: 870px) {
  .AssignmentDistribute {
    flex-direction: column;
    padding: 15px;
    align-items: stretch;
    height: auto; }
  .AssignmentDistribute__blockTop {
    position: relative;
    box-sizing: content-box;
    flex-direction: column;
    align-items: flex-start;
    min-height: 90px;
    max-width: none;
    padding-left: 110px;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(89, 106, 117, 0.18); }
  .AssignmentDistribute__blockBottom {
    padding-top: 15px;
    justify-content: space-between; }
  .AssignmentDistribute__title {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-y: hidden; }
  .AssignmentDistribute__deadline {
    margin-right: 0;
    order: 1; }
  .AssignmentDistribute__imageContainer {
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    border-radius: 2px; }
  .AssignmentDistribute__subjects {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    bottom: 15px; }
  .AssignmentDistribute__subject {
    flex: 1 1; }
  .AssignmentDistribute__subject_desktop {
    display: none; }
  .AssignmentDistribute__subject_mobile {
    display: block; }
  .AssignmentDistribute__questions {
    display: none; }
  .AssignmentDistribute__questions_mobile {
    font-size: 0.9375rem;
    margin-right: 0;
    display: block;
    margin-top: 5px; } }

.AssignmentDistributeList {
  padding-top: 10px;
  height: 100%;
  overflow: auto; }

.MyAccountWindow {
  overflow: hidden;
  box-sizing: content-box;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 19px;
  width: 218px;
  background: #FFF;
  position: absolute;
  top: calc(100% + 1px);
  right: 21px;
  border: 1px solid #D3D9DE;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.11);
  transition: height 200ms ease-in-out;
  z-index: 1; }

.MyAccountWindow__container {
  position: relative; }

.MyAccountWindow__item {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  cursor: pointer; }

.MyAccountWindow__itemText {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  transition: 0.3s; }
  .MyAccountWindow__itemText:hover {
    color: #E2017B; }
    .MyAccountWindow__itemText:hover span {
      color: #E2017B; }

.MyAccountWindow__title {
  word-break: break-all;
  padding-right: 20px; }

.MyAccountWindow__item_centered {
  justify-content: center; }

.MyAccountWindow__itemImage {
  margin-right: 10px; }

.MyAccountWindow__logOut {
  padding-top: 20px;
  padding-bottom: 0; }

.MyAccountWindow__list {
  padding-top: 20px; }
  .MyAccountWindow__list:first-child {
    padding-top: 0; }

.MyAccountWindow__list_separated {
  border-bottom: 1px solid #D3D9DE; }

.MyAccountWindow__list_mobile {
  display: none; }

.MyAccountWindow__itemText_selected {
  font-weight: 500;
  cursor: default; }

.MyAccountWindow__closeButton {
  width: 15.19px;
  height: 15.19px;
  position: absolute;
  top: 4px;
  right: -1px;
  cursor: pointer; }

.MyAccountWindow__backButton {
  width: 15.19px;
  height: 15.19px;
  position: absolute;
  top: 4px;
  left: -4px;
  cursor: pointer; }

.MyAccountWindow__container_animated-enter,
.MyAccountWindow__container_animated-enter-active,
.MyAccountWindow__container_animated-exit,
.MyAccountWindow__container_animated-exit-active,
.MyAccountWindow__secondaryContainer_animated-enter,
.MyAccountWindow__secondaryContainer_animated-enter-active,
.MyAccountWindow__secondaryContainer_animated-exit,
.MyAccountWindow__secondaryContainer_animated-exit-active {
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out; }

.MyAccountWindow__container_animated-enter {
  position: absolute;
  top: 19px;
  left: 19px;
  right: 19px;
  bottom: 19px;
  bottom: auto;
  transform: translateX(-100%);
  opacity: 0; }

.MyAccountWindow__container_animated-enter-active {
  position: absolute;
  top: 19px;
  left: 19px;
  right: 19px;
  bottom: 19px;
  bottom: auto;
  transform: translateX(0);
  opacity: 1; }

.MyAccountWindow__container_animated-exit {
  transform: translateX(0);
  opacity: 1; }

.MyAccountWindow__container_animated-exit-active {
  transform: translateX(-100%);
  opacity: 0; }

.MyAccountWindow__secondaryContainer_animated-enter {
  position: absolute;
  top: 19px;
  left: 19px;
  right: 19px;
  bottom: 19px;
  bottom: auto;
  transform: translateX(100%);
  opacity: 0; }

.MyAccountWindow__secondaryContainer_animated-enter-active {
  position: absolute;
  top: 19px;
  left: 19px;
  right: 19px;
  bottom: 19px;
  bottom: auto;
  transform: translateX(0);
  opacity: 1; }

.MyAccountWindow__secondaryContainer_animated-exit {
  transform: translateX(0);
  opacity: 1; }

.MyAccountWindow__secondaryContainer_animated-exit-active {
  transform: translateX(100%);
  opacity: 0; }

@media screen and (max-width: 870px) {
  .MyAccountWindow {
    width: auto;
    left: 0;
    right: 0; }
  .MyAccountWindow__list_mobile {
    display: block; } }

.AppHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 11px solid #E2017B;
  padding: 13px 15px 12px 20px;
  border-bottom: 1px solid #E4E9ED;
  background: #FFF;
  position: relative;
  z-index: 16; }
  .AppHeader__block {
    display: flex;
    align-items: center;
    min-width: 0; }
  .AppHeader__logo {
    margin-bottom: 7px; }
  .AppHeader__role {
    margin-left: 17px;
    font-family: 'Roboto Slab', serif;
    color: #404041;
    font-size: 1.0625rem;
    font-weight: 400; }
  .AppHeader__navigationItem {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    margin-left: 19px;
    cursor: pointer;
    min-width: 0; }
    .AppHeader__navigationItem a {
      transition: 0.3s;
      overflow: hidden;
      text-overflow: ellipsis; }
      .AppHeader__navigationItem a:hover {
        color: #E2017B; }
      .AppHeader__navigationItem a.permanActive {
        color: #E2017B; }
    .AppHeader__navigationItem.helpNavigation {
      margin-left: 20px;
      margin-right: 10px; }
      .AppHeader__navigationItem.helpNavigation .AppHeader__dropdown::after {
        display: none !important; }
      .AppHeader__navigationItem.helpNavigation .AppHeader__submenuWrapper {
        right: 0px; }
  .AppHeader__navigationItemText {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    transition: 0.3s; }
    .AppHeader__navigationItemText:hover {
      color: #E2017B; }

.ButtonsAddProp {
  display: flex; }
  .ButtonsAddProp .CreateButton {
    margin-left: 20px; }

.PreviewTeachingPath .ButtonsAddProp .CreateButton {
  display: none; }

.PreviewTeachingPath.OpenButton .ButtonsAddProp .CreateButton {
  display: flex; }

.mySharedbutton {
  display: flex;
  position: relative;
  margin-right: 10px; }
  .mySharedbutton__init {
    background: #0b2541;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .mySharedbutton__init img {
      filter: invert(1);
      width: 25px;
      height: 25px; }
  .mySharedbutton__list {
    position: absolute;
    top: 40px;
    padding: 10px 0px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s; }
    .mySharedbutton__list.active {
      pointer-events: all;
      opacity: 1; }
    .mySharedbutton__list a {
      display: block;
      padding: 9px;
      background: #0b2541;
      border-radius: 50%;
      margin-bottom: 5px; }
      .mySharedbutton__list a img {
        filter: invert(1);
        width: 22px;
        height: 22px; }

.AppHeader__block_mobile {
  display: none; }

.AppHeader__navigation {
  display: flex;
  align-items: center;
  min-width: 0; }

.AppHeader__navigationItemImage {
  width: 25px;
  height: 25px; }

.AppHeader__navigationItemText:hover .AppHeader__submenuWrapper {
  opacity: 1;
  pointer-events: all; }

.AppHeader__submenuWrapper {
  opacity: 0;
  pointer-events: none;
  padding-top: 21px;
  position: absolute; }

.AppHeader__submenu {
  min-width: 220px;
  background: #FFF;
  border: 1px solid #D3D9DE;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.11); }

.AppHeader__dropdown {
  align-items: center;
  display: flex; }

.AppHeader__dropdown::after {
  filter: brightness(0);
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background-image: url(/static/media/arrow-down.3bede566.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.AppHeader__dropdown:hover {
  text-decoration: underline; }

.AppHeader__dropdownItem {
  text-decoration: none;
  font-weight: 300;
  transition: background-color .315s ease-in-out, color .315s ease-in-out;
  padding: 10px 15px;
  position: relative; }

.AppHeader__dropdownItem:hover {
  background: #E7ECEF; }

.AppHeader__dropdownItem__subMenu {
  position: absolute;
  width: auto;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;
  transform: translateX(100%);
  border: 1px solid #D3D9DE;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px 0 ffffff1c;
  opacity: 0;
  pointer-events: none; }

.AppHeader__navigationItem:nth-child(2) .AppHeader__dropdownItem__subMenu {
  transform: translateX(-100%); }

.AppHeader__navigationItem:nth-child(3) .AppHeader__dropdownItem__subMenu {
  transform: translateX(-100%); }

li.AppHeader__dropdownItem__subItem {
  text-decoration: none;
  font-weight: 300;
  transition: background-color .315s ease-in-out, color .315s ease-in-out;
  padding: 10px 15px;
  position: relative; }

li.AppHeader__dropdownItem__subItem:hover {
  background: #E7ECEF; }

.AppHeader__dropdownItem:hover ul.AppHeader__dropdownItem__subMenu {
  opacity: 1;
  pointer-events: all; }

.AppHeader__settingsIconDiv {
  margin-left: 30px;
  margin-right: 45px; }

.AppHeader__userLogo {
  width: 37px !important;
  height: 37px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer; }

.AppHeader__settingsIcon {
  width: 26px !important;
  height: 26px;
  border-radius: 50%;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer; }

.AppHeader__headerOverlay {
  display: none; }

.AppHeader__btnHeaderGuidance {
  display: none !important; }

.AppHeader__button {
  width: 22px;
  height: 19px;
  padding: 0;
  background: transparent; }

.AppHeader__userMenuButton {
  padding-left: 12px; }

.AppHeader__navigation_tablet {
  display: none; }

.AppHeader__modal_animated-enter,
.AppHeader__modal_animated-enter-active,
.AppHeader__modal_animated-exit,
.AppHeader__modal_animated-exit-active {
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out; }

.AppHeader__modal_animated-enter {
  transform: scale(0.8) translateY(-20px);
  opacity: 0; }

.AppHeader__modal_animated-enter-active {
  transform: translateY(0);
  opacity: 1; }

.AppHeader__modal_animated-exit {
  transform: translateY(0);
  opacity: 1; }

.AppHeader__modal_animated-exit-active {
  transform: scale(0.8) translateY(-20px);
  opacity: 0; }

.mySharedbutton__list a.whatsapp_shared {
  display: none; }

@media screen and (max-width: 1024px) {
  .AppHeader {
    padding-top: 18px;
    padding-bottom: 7px;
    padding-right: 29px; }
  .AppHeader__navigation {
    display: none; }
  .AppHeader__navigation_tablet {
    display: flex; }
  .AppHeader__userLogo {
    margin-top: 0; } }

@media screen and (max-width: 870px) {
  .AppHeader {
    padding-top: 24px;
    padding-bottom: 22px;
    padding-right: 16px;
    padding-left: 15px; }
  .mySharedbutton__list a.whatsapp_shared {
    display: block; }
  .AppHeader__block {
    display: none; }
  .AppHeader__block_mobile {
    display: flex;
    justify-content: space-between; }
  .AppHeader__navigation_tablet {
    display: none; } }

.modalKeyboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99; }
  .modalKeyboard__background {
    background: #00000069;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .modalKeyboard__content {
    position: absolute;
    background: white;
    padding: 20px 30px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 10px;
    min-width: 330px;
    padding-top: 15px; }
  .modalKeyboard__list {
    margin-top: 25px; }
    .modalKeyboard__list:first-child {
      margin-top: 0px; }
    .modalKeyboard__list h2 {
      font-size: 18px;
      border-bottom: solid 1px #0000002e;
      padding-bottom: 10px; }
    .modalKeyboard__list li {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      line-height: 15px;
      border-bottom: solid 1px #97979729; }
      .modalKeyboard__list li p {
        margin: 8px 0px; }
  .modalKeyboard__close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    border: solid 2px;
    cursor: pointer;
    background: white; }
    .modalKeyboard__close:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 4px; }
    .modalKeyboard__close:before {
      content: '';
      position: absolute;
      width: 17px;
      height: 2px;
      background: #0b2541;
      transform: rotate(45deg);
      top: 12px;
      left: 5px; }
    .modalKeyboard__close:after {
      content: '';
      position: absolute;
      width: 17px;
      height: 2px;
      background: #0b2541;
      transform: rotate(-45deg);
      top: 12px;
      left: 5px; }

.singleListElements li {
  margin: 5px 0px; }
  .singleListElements li a {
    color: #000;
    display: block;
    text-decoration: none;
    padding: 5px 0;
    font-size: 1.125rem;
    font-weight: 400; }
    .singleListElements li a.BtnFinal {
      display: flex;
      background: #e2017b;
      border-radius: 20px;
      color: white;
      padding: 10px 20px;
      font-weight: 300; }
      .singleListElements li a.BtnFinal img {
        width: 19px;
        margin-right: 8px; }
      .singleListElements li a.BtnFinal p {
        color: white;
        margin: 0px; }

.singleElements {
  position: absolute;
  top: 80px;
  right: 20px;
  background: white;
  padding: 20px;
  box-shadow: 0 3px 5px 0 #00000024; }

.singleFlexElements ul {
  display: flex;
  align-items: center; }
  .singleFlexElements ul li {
    margin: 0px;
    margin-right: 20px; }
    .singleFlexElements ul li:last-child {
      margin-right: 0px;
      margin-left: 20px; }

@media (max-width: 1473px) {
  .copyInFlex {
    margin-left: 5px !important; }
  .copyInFlex img {
    display: none; } }

.AssignmentOverview {
  padding: 20px 24px 20px 20px;
  overflow-y: auto; }

.AssignmentOverview__header {
  font-weight: 500;
  font-size: 1.0625rem; }

.AssignmentOverview__questions {
  margin-top: 21px; }

.AssignmentOverview__questionLink_disabled {
  cursor: default; }

.Assignment__progressbar {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 10px;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .Assignment__progressbar__content {
    height: 100%;
    position: absolute;
    left: 0px;
    background: #0a7b24;
    top: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.AssignmentOverview__question {
  margin-bottom: 10px;
  transition: color 0.15s ease-in-out;
  font-size: 0.9rem;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  padding-bottom: 1px; }
  .AssignmentOverview__question.question_in {
    color: #e2017b; }
    .AssignmentOverview__question.question_in .AssignmentOverview__order {
      background: #e2017b;
      color: white;
      border-color: #e2017b; }
  .AssignmentOverview__question a:focus {
    color: #e2017b; }
    .AssignmentOverview__question a:focus .AssignmentOverview__order {
      border-color: #e2017b;
      background: #e2017b;
      color: white; }

.AssignmentOverview__question_disabled {
  cursor: default; }

.AssignmentOverview__text {
  overflow: hidden;
  text-overflow: ellipsis; }

.AssignmentOverview__order {
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  left: 0;
  font-size: 0.75rem;
  margin-right: 10px;
  border: 2px solid #0B2541;
  padding-bottom: 1px; }

.AssignmentOverview__question_answered {
  color: #0A7B24;
  border-color: #0A7B24; }

.AnswerCurrentQuestion {
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px; }

.CurrentAssignmentPage__main__center {
  max-width: 800px;
  width: 100%; }

@media screen and (max-width: 870px) {
  .AnswerCurrentQuestion {
    width: 100%; } }

@media screen and (max-width: 425px) {
  .AnswerCurrentQuestion {
    margin: 0; } }

.Submit__title {
  margin-bottom: 32px;
  font-size: 2.25rem; }

.Submit__delete {
  display: inline-block;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer; }

.Submit__button {
  display: flex;
  align-items: center;
  padding: 7.5px 27px 7.5px 9px;
  margin-top: 32px;
  color: #FFF;
  background: #0A7B24;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.0625rem; }

.Submit__description {
  font-size: 1.0625rem;
  font-weight: 300; }

.Submit__image {
  margin-right: 15px; }

.Submit__defaultCursor {
  cursor: default; }

.donwloadButton button img {
  width: 16px;
  margin-left: 5px; }

.donwloadButton button {
  display: inline-flex;
  background: transparent;
  font-weight: 700;
  align-items: center; }
  .donwloadButton button.downloading {
    cursor: progress; }
    .donwloadButton button.downloading img {
      visibility: hidden; }

.donwloadButton {
  text-align: right;
  margin-top: 40px; }

@media screen and (max-width: 425px) {
  .Submit__title {
    font-size: 1.5rem;
    margin-top: 60px;
    margin-bottom: 7px; }
  .Submit__button {
    margin-top: 60px;
    font-size: 0.875rem;
    padding-right: 25px; } }

.AnswerCover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0; }
  .AnswerCover__content {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    width: 750px;
    padding: 50px 70px; }
  .AnswerCover .authorInfo {
    text-align: center;
    margin-bottom: 20px; }
    .AnswerCover .authorInfo img {
      display: block;
      margin: auto;
      width: 48px;
      border-radius: 50%;
      margin-bottom: 10px; }
    .AnswerCover .authorInfo h4 {
      margin: 0px;
      font-size: 17px; }
  .AnswerCover .metaInfo {
    display: flex;
    align-items: center;
    justify-content: center; }
    .AnswerCover .metaInfo img {
      margin-right: 10px;
      width: 20px; }
    .AnswerCover .metaInfo p {
      margin: 0px;
      font-size: 13px;
      line-height: 17px; }
    .AnswerCover .metaInfo__deadline {
      display: flex;
      align-items: center;
      margin-right: 30px; }
    .AnswerCover .metaInfo__steps {
      display: flex;
      align-items: center; }

.AnswerCover__imageWrapper {
  align-self: stretch;
  padding-top: 67%;
  position: relative;
  margin-bottom: 32px; }

.AnswerCover__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: -1; }

.AnswerCover__teacherInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }

.AnswerCover__teacherLogo {
  width: 48px !important;
  height: 48px;
  border-radius: 50%;
  background: #E4E9ED;
  margin-bottom: 10px; }

.AnswerCover__teacherName {
  text-align: center;
  font-size: 1.0625rem;
  font-weight: 500; }

.AnswerCover__subject {
  letter-spacing: -0.01em;
  font-size: 0.9375rem;
  font-weight: 300; }

.AnswerCover__assignmentInfo {
  display: flex;
  margin-bottom: 20px; }

.AnswerCover__blockText {
  margin-top: 2px;
  font-size: 0.8125rem;
  font-weight: 500; }

.AnswerCover__infoBlock {
  display: flex;
  align-items: center;
  margin-right: 32px; }
  .AnswerCover__infoBlock:last-child {
    margin-right: 0; }

.AnswerCover__blockImage {
  margin-right: 10px; }

.AnswerCover__title {
  font-size: 2.5rem;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  margin-top: 20px;
  display: block; }

.AnswerCover__description {
  font-size: 1.0625rem;
  margin-bottom: 30px;
  text-align: center;
  display: block;
  font-weight: 300; }

.AnswerCover__div_button {
  text-align: center; }

.AnswerCover__button {
  background: #0B2541;
  color: #E7ECEF;
  font-weight: 500;
  font-size: 1.0625rem;
  padding: 9px 23px;
  border-radius: 20px; }

.AnswerCover__questionCount {
  margin-top: 20px;
  margin-bottom: 20px; }

.AnswerCover__questionCount_light {
  font-weight: 300; }

@media screen and (max-width: 1024px) and (min-width: 871px), screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .AnswerCover__imageWrapper {
    padding-top: 38%; } }

@media screen and (max-width: 425px) {
  .AnswerCover__image {
    border-radius: 0; }
  .AnswerCover__imageWrapper {
    margin-bottom: 0;
    margin-right: -15px;
    margin-left: -15px; }
  .AnswerCover__teacherInfo {
    margin-top: -36px; }
  .AnswerCover__teacherLogo {
    width: 75px !important;
    height: 75px;
    border-radius: 50%;
    border: 3px solid #FFF;
    margin-bottom: 15px; }
  .AnswerCover__assignmentInfo {
    align-self: stretch;
    margin-bottom: 24px; }
  .AnswerCover__infoBlock {
    margin-right: 0;
    padding-top: 13px;
    padding-bottom: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.0605878); }
    .AnswerCover__infoBlock:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.0605878); }
  .AnswerCover__title {
    font-size: 1.5rem; }
  .AnswerCover__assignmentInfo {
    flex-direction: column; } }

.InfoCard {
  width: 282px;
  height: 374px;
  min-height: 374px;
  box-sizing: content-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.100661);
  border-radius: 5px;
  transition: 0.5s; }
  .InfoCard button {
    padding: 0;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    position: relative;
    overflow: hidden; }
    .InfoCard button:focus {
      outline: auto;
      outline-style: auto;
      outline-offset: 2px; }
  .InfoCard .toggleReadDescription {
    margin-top: 5px; }
    .InfoCard .toggleReadDescription .toggleRead {
      display: flex;
      align-items: center; }
      .InfoCard .toggleReadDescription .toggleRead:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 3px; }
      .InfoCard .toggleReadDescription .toggleRead img {
        width: 18px;
        margin-right: 5px;
        transform: rotate(-90deg); }
      .InfoCard .toggleReadDescription .toggleRead p {
        font-size: 13px;
        font-weight: 700;
        margin: 0px; }
  .InfoCard .actionButtons {
    position: absolute;
    top: -13px;
    left: 107px;
    z-index: 2; }
    .InfoCard .actionButtons button {
      padding: 0px;
      background: transparent; }
      .InfoCard .actionButtons button:focus {
        outline: black;
        outline-style: auto; }
    .InfoCard .actionButtons img {
      width: 26px;
      height: 26px;
      cursor: pointer; }
      .InfoCard .actionButtons img:first-child {
        margin-right: 5px; }
      .InfoCard .actionButtons img:last-child {
        margin-left: 5px; }
    .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem {
      width: 36px;
      text-align: center; }
      .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem button {
        background: white;
        border: solid 1px #728089;
        border-radius: 50%;
        width: 26px;
        height: 26px; }
        .InfoCard .actionButtons .actionButtonsItem.refreshButtonsItem button img {
          width: 15px;
          height: 15px; }
  .InfoCard .cardImage {
    width: 100%;
    height: 185px;
    border-radius: 5px 5px 0 0;
    object-fit: cover; }
  .InfoCard .cardInfo {
    height: auto;
    position: relative;
    padding: 20px; }
    .InfoCard .cardInfo .cardTitle {
      flex: 1 1;
      min-width: 0; }
      .InfoCard .cardInfo .cardTitle .noTitle {
        color: #939FA7; }
      .InfoCard .cardInfo .cardTitle .isDraft {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: #596A75;
        margin-left: 6px; }
      .InfoCard .cardInfo .cardTitle p {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow-y: hidden;
        margin: 0;
        font-size: 1.05rem;
        font-weight: 500;
        margin-bottom: 5px;
        overflow: hidden; }
      .InfoCard .cardInfo .cardTitle .levelIcon {
        margin: 0; }
    .InfoCard .cardInfo .withTooltip {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      position: relative;
      align-items: center; }
      .InfoCard .cardInfo .withTooltip.AbsolutePosition {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-top: 0px; }
      .InfoCard .cardInfo .withTooltip .OptionMsj {
        position: relative;
        background: transparent;
        width: auto;
        /*&:before {
          position: absolute;
          box-shadow: 0 2px 2px #00000021;
          margin-top: 20px;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          border-radius: 4px;
          border: 1px solid #596a75;
          padding: 20px;
          cursor: default;
          background: #596a75;
          content: attr(data-msj);
          right: -2px;
          top: 18px;
          opacity: 0;
          pointer-events: none;
          z-index: 1;
          color: white;
          font-size: 1.0625rem;
        }
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #596a75;
          right: 0px;
          -webkit-transform: translate(-50%,-50%) rotate(-45deg);
          transform: translate(-50%,-50%) rotate(-45deg);
          background-color: #596a75;
          border-right: 1px solid #596a75;
          border-top: 1px solid #596a75;
          top: 38px;
          z-index: 1;
          opacity: 0;
          pointer-events: none;
        }*/ }
        .InfoCard .cardInfo .withTooltip .OptionMsj:focus {
          outline: black;
          outline-style: auto; }
        .InfoCard .cardInfo .withTooltip .OptionMsj:hover:before, .InfoCard .cardInfo .withTooltip .OptionMsj:hover:after {
          opacity: 1; }
      .InfoCard .cardInfo .withTooltip .defaultIcons {
        margin: 0;
        align-items: center; }
      .InfoCard .cardInfo .withTooltip img {
        height: 23px;
        width: 23px; }
      .InfoCard .cardInfo .withTooltip .tooltip {
        z-index: 2;
        right: -15px;
        position: absolute;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
        margin-top: 20px;
        top: 16px;
        width: -webkit-max-content;
        width: max-content; }
        .InfoCard .cardInfo .withTooltip .tooltip .left {
          border-radius: 4px;
          border: 1px solid #939FA7;
          padding: 15px;
          cursor: default;
          z-index: 2;
          background: #fff; }
          .InfoCard .cardInfo .withTooltip .tooltip .left ul li {
            cursor: pointer;
            margin: 7px; }
            .InfoCard .cardInfo .withTooltip .tooltip .left ul li a:focus {
              outline: black;
              outline-style: auto; }
          .InfoCard .cardInfo .withTooltip .tooltip .left ul .disabled {
            color: #939FA7;
            cursor: default; }
        .InfoCard .cardInfo .withTooltip .tooltip i {
          position: absolute;
          top: -6px;
          right: 20px;
          margin-top: -12px;
          width: 12px;
          height: 24px;
          transform: rotate(-90deg); }
          .InfoCard .cardInfo .withTooltip .tooltip i::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            background-color: #fff;
            border-right: 1px solid #939fa7;
            border-bottom: 1px solid #939fa7; }
    .InfoCard .cardInfo .withoutTooltip {
      display: flex;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-left: 10px;
      margin-top: 0; }
    .InfoCard .cardInfo .defaultIcons {
      height: 23px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px; }
      .InfoCard .cardInfo .defaultIcons img {
        width: 20px;
        height: 20px; }
      .InfoCard .cardInfo .defaultIcons .tpIcon {
        width: 23px;
        height: 23px;
        margin-right: 5px; }
      .InfoCard .cardInfo .defaultIcons .flexBox {
        align-items: center; }
        .InfoCard .cardInfo .defaultIcons .flexBox:focus-within {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
      .InfoCard .cardInfo .defaultIcons span {
        font-size: 14px;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        max-width: 220px; }
      .InfoCard .cardInfo .defaultIcons .levels {
        margin-right: 10px;
        font-size: 0.9375rem;
        align-items: center;
        width: -webkit-max-content;
        width: max-content; }
        .InfoCard .cardInfo .defaultIcons .levels img {
          margin: 0 7px 0 0; }
    .InfoCard .cardInfo .numberOfQuestions {
      font-size: 0.8rem;
      color: #E2017B;
      font-weight: bold; }
    .InfoCard .cardInfo .cardDescription {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow-y: hidden;
      margin-top: 5px;
      font-size: 0.9375rem;
      font-weight: 300; }
    .InfoCard .cardInfo .ContentcardDescription {
      margin-top: 5px; }
      .InfoCard .cardInfo .ContentcardDescription .cardDescription {
        margin-top: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: 0.3s; }
        .InfoCard .cardInfo .ContentcardDescription .cardDescription.active {
          max-height: 70px;
          margin-bottom: 15px; }
      .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead {
        display: inline-flex;
        align-items: center; }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead:focus {
          outline: auto;
          outline-style: auto;
          outline-offset: 2px; }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead img {
          width: 18px;
          margin-right: 5px;
          transform: rotate(-90deg); }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead.active img {
          transform: rotate(90deg); }
        .InfoCard .cardInfo .ContentcardDescription .toggleReadDescription .toggleRead p {
          font-size: 13px;
          font-weight: 700;
          margin: 0px; }
    .InfoCard .cardInfo .buttonRead {
      margin-top: 25px; }
      .InfoCard .cardInfo .buttonRead .btn {
        background: #0b2541;
        border-radius: 20px;
        color: white;
        padding: 10px;
        max-width: 170px; }
        .InfoCard .cardInfo .buttonRead .btn.btnRead {
          background: #0a7b24; }
        .InfoCard .cardInfo .buttonRead .btn:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
    .InfoCard .cardInfo .grades {
      flex-wrap: wrap;
      margin-top: 4px; }
      .InfoCard .cardInfo .grades > div {
        max-width: 31%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px;
        margin: 0 5px;
        background: #D3D9DE;
        border-radius: 4px;
        font-size: 0.8125rem;
        margin-left: 0;
        font-weight: 300; }
    .InfoCard .cardInfo .gradesLineInside div:last-child:after {
      display: none; }
    .InfoCard .cardInfo .gradesLineInside div:after {
      content: ', ';
      margin-right: 5px; }
    .InfoCard .cardInfo .gradesLineInside {
      align-items: center;
      margin-right: 5px; }
    .InfoCard .cardInfo .gradesLine p {
      margin: 0px; }
    .InfoCard .cardInfo .gradesLine {
      align-items: center;
      font-size: 14px; }
    .InfoCard .cardInfo .footerCardInfo {
      justify-content: space-between;
      align-items: center; }

.tpIcon-DOMAIN {
  cursor: pointer; }

@media screen and (max-width: 1024px) {
  .InfoCard .cardInfo .withTooltip .tooltip .left {
    background: #0B2541;
    border-color: #0B2541; }
    .InfoCard .cardInfo .withTooltip .tooltip .left ul li {
      color: #FFF; }
  .InfoCard .cardInfo .withTooltip .tooltip i::after {
    background-color: #0B2541;
    border-color: #0B2541; } }

.ReadingArticle {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 100;
  background: #FFF;
  width: 100%;
  height: 100%; }

.ReadingArticle__header {
  box-sizing: border-box;
  width: 100%;
  background-color: #FFF;
  height: 78px;
  border-top: 10px solid #E2017B;
  padding: 0 20px;
  border-bottom: 1px solid #E4E9ED;
  display: flex;
  justify-content: space-between;
  z-index: 20; }

.ReadingArticle__backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent; }
  .ReadingArticle__backButton:focus {
    outline: black;
    outline-style: auto;
    outline-offset: -2px; }

.ReadingArticle__backButtonText {
  margin: 0 7px;
  font-weight: bold; }

.ReadingArticle__levels {
  display: flex;
  align-items: center; }

.ReadingArticle__level:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 2px; }

.ReadingArticle__level {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 0 7px;
  background: #0b2541;
  color: #fff; }

.ReadingArticle__level_active {
  background: #E2007C; }

.ReadingArticle__finishButton {
  display: flex;
  align-items: center; }

.ReadingArticle__finishButton button {
  min-height: 40px; }

.ReadingArticle__content {
  display: flex;
  width: 100%;
  height: calc(100% - 78px); }

@media screen and (max-width: 870px) {
  .ReadingArticle {
    top: 65px; }
  .ReadingArticle__headerWrapper {
    background: #FFF;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  .ReadingArticle__backButtonText {
    display: none; }
  .ReadingArticle__finishButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px; } }

@media screen and (max-width: 425px) {
  .ReadingArticle__level {
    width: 30px;
    height: 30px;
    margin: 0 4px; } }

.AssignmentArticlesToReading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .AssignmentArticlesToReading .loading {
    position: relative;
    background: transparent;
    height: 100%; }

.AssignmentArticlesToReading__title {
  font-size: 2.25rem;
  margin: 40px;
  text-align: center;
  font-weight: 500; }

.AssignmentArticlesToReading__title:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 1px; }

.AssignmentArticlesToReading__articles {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 910px;
  flex-wrap: wrap; }

.AssignmentArticlesToReading__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 282px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px; }
  .AssignmentArticlesToReading__card .InfoCard {
    height: auto; }

.AssignmentArticlesToReading__defaultCursor {
  cursor: default; }

.AssignmentArticlesToReading__checkbox {
  width: 32px;
  height: 32px;
  align-self: center;
  margin: 20px; }

.AssignmentArticlesToReading .cardBorder .InfoCard {
  border: 1px solid #596A75; }

.AssignmentArticlesToReading .readArticle > :first-child {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27); }

.AssignmentArticlesToReading .article {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
  width: 100%;
  height: 100%; }
  .AssignmentArticlesToReading .article .headerArticle {
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    height: 80px;
    border-top: 10px solid #e2017b;
    padding: 0 20px;
    border-bottom: 1px solid #e4e9ed;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0; }
    .AssignmentArticlesToReading .article .headerArticle .back {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .AssignmentArticlesToReading .article .headerArticle .back span {
        margin: 0 7px;
        color: #0B2541;
        font-weight: 500;
        font-size: 0.9375rem; }
    .AssignmentArticlesToReading .article .headerArticle .finish {
      display: flex;
      align-items: center; }
  .AssignmentArticlesToReading .article .inlineArticle {
    display: flex;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    box-sizing: unset; }

@media screen and (max-width: 640px) {
  .AssignmentArticlesToReading__title {
    font-size: 1.5rem;
    margin: 16px 0 21px 0;
    font-weight: 500;
    text-align: left; } }

.breadcrumb {
  display: flex;
  border-top: 1px solid #E7ECEF;
  padding: 25px 20px;
  overflow: auto;
  width: 100%;
  margin-right: 20px;
  height: 72px;
  background: #FFF; }
  .breadcrumb.blocktype {
    pointer-events: none; }
  .breadcrumb .breadcrumbs {
    display: flex;
    font-size: 0.9375rem;
    font-weight: 300;
    width: 100%; }
    .breadcrumb .breadcrumbs li {
      cursor: pointer;
      white-space: nowrap;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .breadcrumb .breadcrumbs .activeStyle {
      font-weight: 500; }
  .breadcrumb .breadcrumbs > li + li:before {
    color: #cccccc;
    margin: 0 5px;
    content: url(/static/media/arrow-right.16924ba4.svg);
    padding: 0 5px; }

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .breadcrumb {
    position: fixed;
    bottom: 0; } }

.CurrentAssignmentPage {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background: #FFF;
  display: flex;
  flex-direction: column; }
  .CurrentAssignmentPage__mybreadcrumbs {
    position: absolute;
    top: 25px;
    z-index: 20;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%); }

.CurrentAssignmentPage__content {
  position: relative;
  height: calc(100vh - 78px); }

.CurrentAssignmentPage__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto; }

.CurrentAssignmentPage__main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 10 0;
  width: 100%;
  position: relative;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: auto; }
  .CurrentAssignmentPage__main.questionBody {
    position: relative; }
    .CurrentAssignmentPage__main.questionBody .AnswerCurrentQuestion {
      padding: 20px 50px; }
    .CurrentAssignmentPage__main.questionBody .ButtonNextNew {
      padding: 0px 50px;
      padding-bottom: 40px;
      text-align: center; }
    .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center {
      background: white;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      min-width: 750px;
      padding: 0px;
      height: auto;
      min-height: 400px;
      max-width: 1010px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: auto; }
      .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar {
        position: relative;
        height: auto;
        width: 100%;
        background: transparent;
        display: block; }
        .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 10px;
          background: #e7ecef;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px; }
        .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview {
          padding: 0px; }
          .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview__questions {
            margin-top: 0px;
            display: flex;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between; }
            .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview__questions .AssignmentOverview__text {
              overflow: auto;
              text-overflow: initial;
              margin-bottom: 0px;
              padding: 0px; }
            .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview__questions .AssignmentOverview__order {
              position: relative;
              top: 0px;
              transform: none;
              background: #979797;
              color: white;
              margin: 0px;
              border: 2px solid #979797;
              margin-top: 15px; }
              .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview__questions .AssignmentOverview__order:before {
                content: '';
                height: 6px;
                width: 6px;
                position: absolute;
                top: -15px;
                background: #0a7b24;
                border-radius: 50%;
                left: 50%;
                margin-left: -3px; }
            .CurrentAssignmentPage__main.questionBody .CurrentAssignmentPage__main__center .CurrentAssignmentPage__navBar .AssignmentOverview__questions .question_in .AssignmentOverview__order {
              background: #0A7B24;
              color: white;
              border-color: #0A7B24; }
    .CurrentAssignmentPage__main.questionBody .QuestionPreview__background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      pointer-events: none;
      z-index: -1;
      left: 0;
      top: 0; }

.CurrentAssignmentPage__breadcrumbs {
  flex: 1 0;
  padding-left: 230px;
  position: fixed;
  bottom: 0;
  background-color: #FFF;
  width: 100%; }

.CurrentAssignmentPage__navigation {
  display: none;
  height: 50px;
  border-bottom: 1px solid #E4E9ED; }

.CurrentAssignmentPage__button {
  background: transparent;
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 10px 0 10px 2px;
  border-right: 1px solid #E4E9ED;
  cursor: pointer;
  background: #FFF; }

.CurrentAssignmentPage__button_disabled {
  color: rgba(11, 37, 65, 0.5); }

.CurrentAssignmentPage__button_right {
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 2px; }

.CurrentAssignmentPage__buttonImage {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  margin-left: 14px;
  margin-right: 14px; }

.CurrentAssignmentPage__navBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #E7ECEF;
  width: 230px;
  z-index: 1; }

.CurrentAssignmentPage__overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 78px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: none; }

.CurrentAssignmentPage__notification {
  display: inline-block;
  position: fixed;
  font-size: 1.0625rem;
  right: 20px;
  top: 33px;
  z-index: 4; }
  .CurrentAssignmentPage__notification div {
    color: #596A75; }

@media screen and (max-width: 1280px) {
  .CurrentAssignmentPage__main {
    /* padding-left: $assignment-navigation-width; */ } }

@media screen and (max-width: 870px) {
  .CurrentAssignmentPage__main {
    padding-top: 0;
    padding-left: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
  .CurrentAssignmentPage__navigation {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    z-index: 1; }
  .CurrentAssignmentPage__navBar {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out; }
  .CurrentAssignmentPage__navBar_visible {
    transform: translateX(0);
    opacity: 1;
    z-index: 10; }
  .CurrentAssignmentPage__overlay {
    display: block;
    top: 92px; }
  .CurrentAssignmentPage__content {
    height: calc(100vh - 92px); }
  .CurrentAssignmentPage__notification {
    display: none; } }

.CurrentAssignmentPage__mybreadcrumbs {
  position: absolute;
  top: -55px;
  z-index: 20;
  align-items: center;
  display: flex;
  background: white;
  left: 50%;
  transform: translateX(-50%); }
  .CurrentAssignmentPage__mybreadcrumbs .breadcrumb {
    padding: 10px;
    height: auto;
    margin: 0px;
    border-top: none; }
    .CurrentAssignmentPage__mybreadcrumbs .breadcrumb .breadcrumbs {
      align-items: center; }
      .CurrentAssignmentPage__mybreadcrumbs .breadcrumb .breadcrumbs li {
        font-size: 15px; }
  .CurrentAssignmentPage__mybreadcrumbs .CurrentAssignmentPage__button {
    padding: 0px;
    border-right: none; }
    .CurrentAssignmentPage__mybreadcrumbs .CurrentAssignmentPage__button img {
      margin: 0px;
      width: 20px !important;
      height: 20px; }

.ButtonNextNew {
  padding: 0px 15px; }
  .ButtonNextNew button {
    padding: 10px;
    border-radius: 25px;
    color: white;
    background: #0b2541;
    min-width: 200px;
    margin-top: 20px; }
    .ButtonNextNew button:disabled {
      opacity: 0.4; }
    .ButtonNextNew button:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }

.currenpathLoading {
  top: 0px !important;
  z-index: 17 !important; }

@media screen and (max-width: 1645px) {
  .CurrentAssignmentPage__mybreadcrumbs {
    max-width: 480px; } }

@media screen and (max-width: 1500px) {
  .ButtonsAddProp .CreateButton {
    margin-left: 10px !important; } }

@media screen and (max-width: 1350px) {
  .CurrentAssignmentPage__mybreadcrumbs {
    display: none; } }

@media screen and (max-width: 425px) {
  .CurrentAssignmentPage__main {
    padding-top: 0; } }

.modalContentTGAssig {
  position: fixed;
  left: 50%;
  top: calc(40% - 0.59em);
  transform: translate(-50%, -28%);
  width: 700px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  z-index: 15;
  display: none;
  /* word-break: break-all; */ }
  .modalContentTGAssig__header {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding: 25px 10px 20px 50px; }
    .modalContentTGAssig__header h1 {
      text-transform: uppercase;
      color: #e2017b;
      font-size: 1rem;
      clear: both;
      margin-bottom: 3px; }
    .modalContentTGAssig__header span {
      text-decoration: none !important;
      text-transform: none !important;
      color: #000 !important;
      font-size: 1rem !important;
      clear: both; }
    .modalContentTGAssig__header__close {
      position: absolute;
      left: 98%;
      top: -15px;
      cursor: pointer;
      border-radius: 50%;
      background: transparent;
      padding: 0px; }
      .modalContentTGAssig__header__close svg {
        display: block;
        height: 30px;
        width: 30px; }
      .modalContentTGAssig__header__close .roundedCircle {
        fill: white; }
      .modalContentTGAssig__header__close:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 2px; }
      .modalContentTGAssig__header__close:hover .roundedCircle {
        fill: #d3d9de; }
  .modalContentTGAssig__body {
    position: relative;
    flex: 1 1 auto;
    padding: 25px 30px 0px 50px;
    max-height: 400px;
    overflow-y: auto; }
    .modalContentTGAssig__body__row {
      padding: 0px 0px; }
      .modalContentTGAssig__body__row.first {
        padding: 15px 0px; }
      .modalContentTGAssig__body__row h4 {
        display: flex;
        margin: 5px 0px;
        align-items: center;
        margin-bottom: 20px; }
    .modalContentTGAssig__body .line {
      border-top: 1px solid #e9ecef;
      padding: 20px 0px; }
    .modalContentTGAssig__body .DescriptionEditor {
      width: 100% !important;
      font-size: 14px !important; }
    .modalContentTGAssig__body .nestedOrderNumber {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 31px;
      height: 31px;
      min-height: 31px;
      background: #e2017b;
      border-radius: 50%;
      color: #ffffff;
      font-size: 1.0625rem;
      margin: 0 12px 0 0;
      padding: 0px 10px;
      border: 2px solid #e2017b;
      float: left; }
  .modalContentTGAssig__footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 25px 46px;
    border-top: 1px solid #e9ecef; }
    .modalContentTGAssig__footer__aligLeft {
      display: flex; }
      .modalContentTGAssig__footer__aligLeft button {
        width: auto;
        padding: 0 23px;
        margin-top: auto;
        min-width: inherit; }
    .modalContentTGAssig__footer__aligRight {
      margin-top: 9px;
      margin-right: 25px; }
      .modalContentTGAssig__footer__aligRight button {
        display: flex;
        background: transparent; }
        .modalContentTGAssig__footer__aligRight button:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
        .modalContentTGAssig__footer__aligRight button img {
          margin-left: 5px; }
      .modalContentTGAssig__footer__aligRight button.downloading {
        cursor: progress; }
        .modalContentTGAssig__footer__aligRight button.downloading img {
          visibility: hidden; }

.modalContentTGAssig.open {
  display: block;
  z-index: 19; }

.horizontalLine {
  width: 135px;
  display: block;
  margin: 35px auto 30px auto;
  border-bottom: 1px solid #d3d9de; }
  .horizontalLine.withOutButton {
    margin-top: 5px; }

.modalContentTGAssigBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4; }

.modalContentTGAssigBackground.hide {
  display: none; }

.expansion {
  display: flex;
  font-weight: 300;
  font-style: italic;
  overflow: hidden;
  align-items: baseline;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #8e8e8e48;
  opacity: 0.6; }

.full {
  max-height: 42px;
  padding-bottom: 10px; }

.paragraph {
  text-overflow: ellipsis;
  min-width: 95%; }

.toggleRead {
  display: flex;
  align-items: center; }
  .toggleRead img {
    color: #8e8e8e;
    width: 20px;
    margin-right: 5px;
    transform: rotate(-90deg);
    transition: 0.5s; }
  .toggleRead.active img {
    color: #8e8e8e;
    transform: rotate(90deg); }
  .toggleRead p {
    font-size: 13px;
    font-weight: 700;
    margin: 0px;
    color: #0b2541; }

.image {
  color: #8e8e8e; }

.divHidden {
  display: none; }

.detailsModal button {
  display: flex;
  align-items: center; }
  .detailsModal button img {
    width: 23px;
    margin-right: 15px; }

.modalContentTG__body__item {
  margin-bottom: 25px; }
  .modalContentTG__body__item h3 {
    display: flex;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px; }
    .modalContentTG__body__item h3 img {
      width: 18px;
      margin-right: 10px;
      display: block; }
  .modalContentTG__body__item ul {
    padding-left: 28px; }
    .modalContentTG__body__item ul.modalContentTG__body__list {
      display: flex;
      flex-wrap: wrap; }
      .modalContentTG__body__item ul.modalContentTG__body__list li {
        background-color: #d3d9de;
        border-radius: 4px;
        color: #0b2541;
        font-size: 13px;
        font-weight: 300;
        padding: 4px;
        margin: 5px 5px 5px 0px; }
    .modalContentTG__body__item ul.modalContentTG__body__listGoals li {
      margin-bottom: 10px; }
      .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle {
        font-size: 15px; }
        .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle .description {
          margin-top: 12px;
          margin-bottom: 20px; }
          .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle .description ul {
            padding: 0px; }
            .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle .description ul .goalsItem {
              display: flex;
              padding-bottom: 12px;
              border-bottom: solid 1px #0000002b; }
              .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle .description ul .goalsItem__grade {
                width: 70px;
                flex: none;
                margin-right: 10px;
                font-weight: 700; }
        .modalContentTG__body__item ul.modalContentTG__body__listGoals li .subjectTitle.subjectTitleCont .description ul .goalsItem__grade {
          width: 105px; }
      .modalContentTG__body__item ul.modalContentTG__body__listGoals li:last-child .subjectTitle {
        border-bottom: none; }
      .modalContentTG__body__item ul.modalContentTG__body__listGoals li .grade {
        font-size: 14px;
        font-weight: 700; }
      .modalContentTG__body__item ul.modalContentTG__body__listGoals li .description {
        font-size: 15px;
        font-weight: 300;
        line-height: 22px; }

.TeachingPathDistributeList {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  width: auto;
  overflow: auto;
  height: 100%;
  margin-top: 10px;
  justify-content: start;
  padding-top: 5px;
  transition: 0.3s; }
  .TeachingPathDistributeList .TeachingPathDistributeItemSkeleton {
    width: 280px;
    margin-right: 10px;
    height: 350px; }
  .TeachingPathDistributeList .TeachingPathDistributeItem {
    width: calc(20% - 15px);
    min-height: 265px;
    margin-bottom: 20px;
    margin-right: 14px; }
    .TeachingPathDistributeList .TeachingPathDistributeItem .InfoCard {
      width: 100%;
      min-height: auto;
      height: 265px; }
      .TeachingPathDistributeList .TeachingPathDistributeItem .InfoCard button {
        height: 175px !important; }
      .TeachingPathDistributeList .TeachingPathDistributeItem .InfoCard .cardInfo {
        min-height: 76px; }

@media screen and (max-width: 870px) {
  .TeachingPathDistributeList .TeachingPathDistributeItem {
    width: calc(33.33% - 15px); } }

@media screen and (max-width: 640px) {
  .TeachingPathDistributeList .TeachingPathDistributeItem {
    width: 100%; } }

.cover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex; }
  .cover__content {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    width: 750px;
    padding: 50px 70px; }
  .cover .mainImage {
    width: 600px;
    height: 45%;
    max-height: 326px;
    align-self: center; }
  .cover .authorInfo {
    text-align: center;
    margin-bottom: 20px; }
    .cover .authorInfo img {
      display: block;
      margin: auto;
      width: 48px;
      border-radius: 50%;
      margin-bottom: 10px; }
    .cover .authorInfo h4 {
      margin: 0px;
      font-size: 17px; }
  .cover .metaInfo {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cover .metaInfo img {
      margin-right: 10px;
      width: 20px; }
    .cover .metaInfo p {
      margin: 0px;
      font-size: 13px;
      line-height: 17px; }
    .cover .metaInfo__deadline {
      display: flex;
      align-items: center;
      margin-right: 30px; }
    .cover .metaInfo__steps {
      display: flex;
      align-items: center; }
  .cover .infoTeacher {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .cover .infoTeacher img {
      margin-bottom: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100%; }
    .cover .infoTeacher .subject {
      font-size: 0.9375rem;
      font-weight: 300; }
  .cover .assignmentInfo {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px; }
    .cover .assignmentInfo .extraInfo {
      display: flex;
      align-items: flex-end;
      font-size: 0.8125rem;
      margin: 0 16px; }
      .cover .assignmentInfo .extraInfo img {
        width: 18px;
        height: 18px;
        margin-right: 10px; }
  .cover .assignmentTitle {
    font-size: 2.5rem;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    display: block; }
  .cover .assignmentDescription {
    font-size: 1.0625rem;
    margin-bottom: 30px;
    display: flex;
    align-self: center;
    padding: 10px 20px;
    text-align: center;
    font-weight: 300;
    justify-content: center; }
  .cover .startButton {
    min-width: 200px;
    align-self: center;
    text-align: center;
    margin-bottom: 20px; }
    .cover .startButton button {
      font-weight: 500;
      min-width: 200px; }

@media (max-width: 870px) {
  .cover .assignmentTitle {
    font-size: 1.5rem; } }


.SubmitTeachingPath {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative; }
  .SubmitTeachingPath__content {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    width: 750px;
    padding: 0px;
    height: auto;
    padding: 50px 70px; }
  .SubmitTeachingPath__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    z-index: -1;
    left: 0;
    top: 0; }

.SubmitTeachingPath__title {
  font-size: 2.25rem;
  margin-bottom: 20px; }

.SubmitTeachingPath__description {
  font-weight: 300; }

.SubmitTeachingPath__deleteButton {
  background-color: transparent;
  text-decoration: underline;
  margin-top: 10px; }

.SubmitTeachingPath__button {
  margin-top: 20px;
  border-radius: 30px;
  background: #379b43;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 20px;
  align-items: center;
  color: #fff;
  font-size: 1.0625rem;
  border: 1px solid #379b43;
  display: flex;
  justify-content: center; }

.SubmitTeachingPath__buttonImage {
  margin-right: 10px; }

.submitButton:disabled {
  color: white;
  background-color: #939FA7;
  border: 1px solid #939FA7;
  cursor: default; }

.passageTeachingPath {
  display: flex;
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  z-index: 20; }
  .passageTeachingPath .passageTeachingPathBreadCrumbs {
    position: absolute;
    top: 0;
    background: #e7ecef;
    width: 100%;
    height: 11px;
    z-index: 99; }
    .passageTeachingPath .passageTeachingPathBreadCrumbs ul {
      display: flex;
      align-items: stretch;
      height: 100%; }
      .passageTeachingPath .passageTeachingPathBreadCrumbs ul li {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:before {
          content: '';
          height: 6px;
          width: 6px;
          background: #E2017B;
          border-radius: 50%;
          display: block; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:after {
          content: '';
          position: absolute;
          width: 0;
          height: 100%;
          background: #E2017B;
          left: 0; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li.used:after {
          width: 100%; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li.used.rounded:after {
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:last-child:before {
          display: none; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:last-child:after {
          border-radius: 0px !important; }
  .passageTeachingPath .teachingPathNewBreadCrumbs {
    position: absolute;
    top: 25px;
    z-index: 20;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%); }
    .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb {
      padding: 10px;
      height: auto;
      margin: 0px;
      border-top: none; }
      .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb .breadcrumbs {
        align-items: center; }
        .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb .breadcrumbs li {
          font-size: 15px; }
    .passageTeachingPath .teachingPathNewBreadCrumbs button {
      background: transparent; }
      .passageTeachingPath .teachingPathNewBreadCrumbs button:disabled {
        opacity: 0.4;
        pointer-events: none; }
      .passageTeachingPath .teachingPathNewBreadCrumbs button.navigationNextButton img {
        transform: rotate(180deg); }
      .passageTeachingPath .teachingPathNewBreadCrumbs button img {
        width: 20px; }
  .passageTeachingPath .teachingPathNavigate {
    display: flex;
    flex-direction: column;
    background: #E7ECEF;
    width: 230px;
    min-width: 230px;
    justify-content: space-between; }
    .passageTeachingPath .teachingPathNavigate .stepOverview {
      display: flex;
      padding: 20px;
      flex-direction: column; }
      .passageTeachingPath .teachingPathNavigate .stepOverview span {
        text-overflow: ellipsis;
        overflow: hidden; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .overview {
        font-size: 0.9375rem;
        color: #E2017B;
        text-transform: uppercase;
        margin-bottom: 10px; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .overview span {
          word-break: break-word; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .titleTeachingPath {
        margin: 0px;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper {
        display: flex;
        padding: 10px 0 0;
        align-items: center; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .questionNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          border: 2px solid #0B2541;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          margin-right: 10px; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .questionName {
          font-size: 0.9375rem; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .selectedItem-circle {
          color: #0A7B24;
          border: 2px solid #0A7B24; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .selectedItem {
          color: #0A7B24; }
    .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath {
      display: flex;
      background: #D3D9DE;
      padding: 20px;
      flex-direction: column; }
      .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationButton {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer; }
        .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationButton span {
          margin-left: 10px;
          font-size: 0.9375rem; }
      .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: transparent; }
        .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton span {
          margin-left: 10px;
          font-size: 0.9375rem;
          color: #E2017B; }
  .passageTeachingPath .teachingPathWrapper {
    display: flex;
    height: calc(100% - 78px); }
    .passageTeachingPath .teachingPathWrapper .wrapperTeachingPath {
      width: 100%; }
      .passageTeachingPath .teachingPathWrapper .wrapperTeachingPath .contentTeachingPath {
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex; }

.sharedFloatingButton {
  position: absolute;
  background: #0b2541;
  width: 40px;
  height: 40px;
  z-index: 99;
  border-radius: 50%;
  right: 20px;
  bottom: 50px; }
  .sharedFloatingButton a {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center; }
    .sharedFloatingButton a:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }
    .sharedFloatingButton a img {
      display: block;
      filter: invert(1);
      width: 17px;
      height: 17px;
      margin-left: -1px; }

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath {
    position: fixed;
    bottom: 0;
    width: 230px; } }

.modalContentTG {
  position: fixed;
  left: 50%;
  top: calc(40% - 0.59em);
  transform: translate(-50%, -28%);
  width: 700px;
  max-width: 90%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  z-index: 23;
  display: none; }
  .modalContentTG__header {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding: 25px 10px 20px 50px; }
    .modalContentTG__header h1 {
      text-transform: uppercase;
      color: #e2017b;
      font-size: 1rem;
      clear: both;
      margin-bottom: 3px; }
    .modalContentTG__header span {
      text-decoration: none !important;
      text-transform: none !important;
      color: #000 !important;
      font-size: 1rem !important;
      clear: both; }
    .modalContentTG__header__close {
      position: absolute;
      left: 98%;
      top: -15px;
      cursor: pointer;
      background: transparent;
      padding: 0px; }
      .modalContentTG__header__close svg {
        display: block;
        height: 30px;
        width: 30px; }
      .modalContentTG__header__close:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 2px; }
  .modalContentTG__body {
    position: relative;
    flex: 1 1 auto;
    padding: 25px 30px 0px 50px;
    max-height: 400px;
    overflow-y: auto; }
    .modalContentTG__body__row {
      padding: 0px 0px; }
      .modalContentTG__body__row.first {
        padding: 15px 0px; }
      .modalContentTG__body__row h4 {
        display: flex;
        margin: 5px 0px;
        align-items: center;
        margin-bottom: 20px; }
      .modalContentTG__body__row .contentAssigments {
        margin: 20px 0px;
        line-height: 30px; }
        .modalContentTG__body__row .contentAssigments a {
          font-weight: 100;
          display: flex;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; }
        .modalContentTG__body__row .contentAssigments img {
          margin: -2px 0px 0px 8px; }
    .modalContentTG__body .line {
      border-top: 1px solid #e9ecef;
      padding: 20px 0px; }
    .modalContentTG__body .DescriptionEditor {
      width: 100% !important;
      font-size: 14px !important; }
    .modalContentTG__body .nestedOrderNumber {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 31px;
      height: 31px;
      min-height: 31px;
      background: #E2017B;
      border-radius: 50%;
      color: #ffffff;
      font-size: 1.0625rem;
      margin: 0 12px 0 0;
      padding: 0px 10px;
      float: left; }
  .modalContentTG__footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 25px 46px;
    border-top: 1px solid #e9ecef; }
    .modalContentTG__footer__aligLeft {
      display: flex; }
      .modalContentTG__footer__aligLeft button {
        width: auto;
        padding: 0 23px;
        margin-top: auto;
        min-width: inherit; }
    .modalContentTG__footer__aligRight {
      margin-top: 9px;
      margin-right: 25px; }
      .modalContentTG__footer__aligRight button {
        display: flex;
        background: transparent; }
        .modalContentTG__footer__aligRight button:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px; }
        .modalContentTG__footer__aligRight button img {
          margin-left: 5px; }
      .modalContentTG__footer__aligRight button.downloading {
        cursor: progress; }
        .modalContentTG__footer__aligRight button.downloading img {
          visibility: hidden; }

.modalContentTG.open {
  display: block; }

.horizontalLine {
  width: 135px;
  display: block;
  margin: 35px auto 30px auto;
  border-bottom: 1px solid #d3d9de; }
  .horizontalLine.withOutButton {
    margin-top: 5px; }

.btnTeacherguide {
  margin-top: 19px; }
  .btnTeacherguide .buttonTeacherGuide {
    line-height: 24px; }
    .btnTeacherguide .buttonTeacherGuide img {
      margin-right: 8px;
      display: initial;
      vertical-align: bottom; }

.modalContentTGBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4; }

.modalContentTGBackground.hide {
  display: none; }

.Sidebar {
  width: 211px;
  position: relative;
  background: #FFF;
  overflow-y: auto; }

.Sidebar__list {
  width: 100%; }

.Sidebar__listItem {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #E7ECEF;
  padding-top: 26px;
  padding-bottom: 26px;
  transition: 0.3s; }
  .Sidebar__listItem:hover {
    background: #dedede; }

.Sidebar__listItem_active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 10px;
  width: 4px;
  background: #E2017B; }

.Sidebar__icon {
  margin-left: 21px; }

.Sidebar__text {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-left: 19px;
  align-self: center;
  font-size: 1.0625rem;
  font-weight: 500; }

.Sidebar__toggleButton {
  display: none; }

.Sidebar__overlay {
  content: " ";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 78px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

@media screen and (max-width: 1024px) {
  .Sidebar {
    width: 70px; }
  .Sidebar__list {
    padding-top: 10px; }
  .Sidebar__text {
    display: none; }
  .Sidebar__listItem {
    padding: 15px 0px;
    border-bottom: 0; }
  .Sidebar__listItem_active::after {
    top: 7px;
    bottom: 7px; }
  .Sidebar__icon {
    width: 25px;
    margin-left: 19px; }
  .Sidebar__text {
    margin-left: 20px; }
  .Sidebar_expanded {
    width: 211px;
    border-right: 0; }
  .Sidebar__toggleButton {
    align-self: flex-start;
    display: block;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #D3D9DE;
    background: transparent;
    margin-left: 8.5px;
    margin-bottom: 19px; } }

@media screen and (max-width: 870px) {
  .Sidebar__burger {
    width: 22px;
    height: 22px;
    background: url(/static/media/sidebar.3250c66c.svg) center center no-repeat;
    background-size: 20px;
    position: absolute;
    top: 35px;
    left: 15px;
    z-index: 9999; } }

.Submitted {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 78px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 78px);
  background: #FFF;
  z-index: 15; }

.Submitted__container {
  width: 630px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px; }

.Submitted__image {
  margin-bottom: 42px;
  margin-left: 1px; }

.Submitted__title {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
  font-size: 2.25rem; }

.Submitted__description {
  margin-bottom: 37px;
  text-align: center;
  font-weight: 300;
  font-size: 1.0625rem; }

.Submitted__button {
  background: #0B2541;
  border-radius: 20px;
  position: relative;
  color: #FFF;
  padding: 9px 18px 9px 47px;
  margin-left: 1px;
  font-weight: 500;
  font-size: 1.0625rem; }

.Submitted__revert {
  margin-top: 24px;
  text-decoration: underline;
  cursor: pointer; }

.Submitted__buttonImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px; }

@media screen and (max-width: 870px) {
  .Submitted__title {
    margin-bottom: 17px;
    font-size: 1.5rem; }
  .Submitted__description {
    margin-bottom: 60px; } }

.header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 20;
  background-color: #ffffff;
  height: 80px;
  border-top: 10px solid #e2017b;
  padding: 0 20px;
  border-bottom: 1px solid #e4e9ed; }
  .header .assignmentTabs {
    height: 100%;
    margin-left: 99px;
    position: relative; }
  .header .back-button {
    cursor: pointer; }
    .header .back-button img {
      margin-right: 10px; }
  .header .link {
    text-transform: uppercase;
    height: 100%;
    position: relative;
    margin: 0 20px;
    color: #939fa7;
    font-weight: 500;
    font-size: 0.9375rem; }
  .header .disabled-link {
    pointer-events: none; }
  .header .activeRoute {
    color: #0B2541; }
    .header .activeRoute::after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #e2017b;
      bottom: 0;
      left: 0;
      width: 100%; }
  .header .doneBox span {
    color: #596A75;
    margin-right: 20px;
    font-size: 0.9375rem; }

.dark {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 15;
  background: rgba(54, 57, 71, 0.3); }

@media screen and (min-width: 1400px) {
  .NewAssignment .header .assignmentTabs {
    right: -123px; } }

@media screen and (max-width: 1024px) {
  .header .link {
    font-size: 0.75rem !important; }
  .header .doneBox span {
    font-size: 0.75rem !important; }
  .header .doneBox .CreateButton {
    font-size: 0.8125rem; } }

.NewAssignment .AssignmentTitle {
  border-bottom: 1px solid #d3d9de;
  background-color: #E7ECEF; }
  .NewAssignment .AssignmentTitle .assignmentTitleWrapper {
    padding: 20px; }
    .NewAssignment .AssignmentTitle .assignmentTitleWrapper span {
      text-transform: uppercase;
      color: #e2017b; }
    .NewAssignment .AssignmentTitle .assignmentTitleWrapper .newAssignmentTitleInput {
      padding: 10px 0 0 0;
      font-size: 2.25rem;
      border: none;
      resize: none;
      outline: none;
      background: #E7ECEF;
      font-weight: 500;
      height: 58px; }
      .NewAssignment .AssignmentTitle .assignmentTitleWrapper .newAssignmentTitleInput::placeholder {
        color: #939FA7; }
    .NewAssignment .AssignmentTitle .assignmentTitleWrapper .newAssignmentDescriptionInput {
      margin-top: 20px;
      border: none;
      resize: none;
      outline: none;
      background: #E7ECEF;
      padding: 0; }
      .NewAssignment .AssignmentTitle .assignmentTitleWrapper .newAssignmentDescriptionInput::placeholder {
        color: #939FA7; }
    .NewAssignment .AssignmentTitle .assignmentTitleWrapper .withPlusIcon {
      padding-left: 20px;
      background: url(/static/media/plus.1b001181.svg) no-repeat 0 6px; }
    .NewAssignment .AssignmentTitle .assignmentTitleWrapper .assignmentIsNotUnique {
      color: #FF0000;
      text-transform: none; }

.NewAssignment .AddQuestion {
  background: #E7ECEF !important;
  padding: 40px 20px !important; }
  .NewAssignment .AddQuestion .addQuestionPhase {
    margin-bottom: 20px; }
  .NewAssignment .AddQuestion .questionTypeBox {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #596A75 !important;
    height: 100px;
    width: 49%;
    cursor: pointer;
    text-align: left;
    box-shadow: 0 2px 4px 0 #d3d9de;
    transition: 0.3s; }
    .NewAssignment .AddQuestion .questionTypeBox:hover {
      border: 1px solid #e2017b !important; }
    .NewAssignment .AddQuestion .questionTypeBox:first-child {
      margin-bottom: 20px; }
    .NewAssignment .AddQuestion .questionTypeBox img {
      margin-right: 20px;
      width: 30px;
      height: 30px; }
    .NewAssignment .AddQuestion .questionTypeBox .questionWrapper .questionType {
      margin-bottom: 5px; }
    .NewAssignment .AddQuestion .questionTypeBox .questionWrapper:last-child {
      font-weight: 300; }
    .NewAssignment .AddQuestion .questionTypeBox .questionWrapper .questionTypeDescription {
      font-size: 0.9375rem; }

.NewAssignment .questionTitle * {
  color: #939fa7; }

.NewAssignment .questionTitle .questionIcon {
  margin-right: 10px; }

.NewAssignment .newTextQuestionInput {
  border: none;
  resize: none;
  outline: none;
  font-size: 1.375rem;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-weight: 500; }
  .NewAssignment .newTextQuestionInput::placeholder {
    color: #939FA7; }
  .NewAssignment .newTextQuestionInput .questionControlPanel {
    padding: 0 20px;
    margin-top: 15px; }

.NewAssignment .textExtra {
  align-items: flex-start;
  margin-bottom: 20px; }
  .NewAssignment .textExtra textarea {
    resize: none;
    border: none;
    width: 100%;
    margin-left: 20px; }

.NewTextQuestion {
  cursor: move;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #d3d9de; }
  .NewTextQuestion .newTextQuestionInput {
    border: none;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 20px; }
    .NewTextQuestion .newTextQuestionInput::placeholder {
      color: #939FA7; }
  .NewTextQuestion .extra img {
    margin-right: 10px;
    cursor: pointer; }

.NewMultipleChoiceQuestion {
  cursor: move;
  background: #fff;
  padding: 20px 0;
  border-bottom: 1px solid #d3d9de; }
  .NewMultipleChoiceQuestion .newTextQuestionInput {
    border: none;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 20px; }
    .NewMultipleChoiceQuestion .newTextQuestionInput::placeholder {
      color: #939FA7; }
  .NewMultipleChoiceQuestion .options {
    margin-top: 20px;
    padding: 0 20px; }
  .NewMultipleChoiceQuestion .extra img {
    margin-right: 10px;
    cursor: pointer; }

.attached-files {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%; }
  .attached-files img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    padding: 2px; }

@charset "UTF-8";
.NewAssignmentPreviewPagination {
  width: 100%;
  height: 5%;
  margin-bottom: 20px;
  margin-top: 20px; }
  .NewAssignmentPreviewPagination .controls {
    display: none; }
  .NewAssignmentPreviewPagination .linkPageWrapper {
    width: 33px;
    height: 33px;
    margin-right: 10px;
    background: #E7ECEF;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #bdc3c8; }
    .NewAssignmentPreviewPagination .linkPageWrapper.activePage {
      background: #0b2541;
      color: #fff;
      border: none;
      width: -webkit-max-content;
      width: max-content; }
  .NewAssignmentPreviewPagination .linkPage {
    width: 100%;
    height: 100%;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 300; }
    .NewAssignmentPreviewPagination .linkPage:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }
    .NewAssignmentPreviewPagination .linkPage.activePage {
      justify-content: flex-end;
      padding: 0 10px;
      position: relative; }
  .NewAssignmentPreviewPagination .en::before {
    content: "Question";
    margin-right: 4px; }
  .NewAssignmentPreviewPagination .no::before {
    content: "Spørsmål";
    margin-right: 4px; }

.NewAssignmentPreview {
  position: relative;
  border-left: 1px solid #d3d9de;
  height: 100%; }
  .NewAssignmentPreview .questionPreviewContainer {
    height: calc(100% - 60px);
    width: 100%; }
    .NewAssignmentPreview .questionPreviewContainer .previewHeadPhrase {
      height: 5%;
      width: 100%;
      margin-top: 20px;
      color: #e2017b;
      text-transform: uppercase;
      padding: 0 20px; }
    .NewAssignmentPreview .questionPreviewContainer .previewQuestion {
      display: flex;
      height: calc(100% - 60px);
      overflow-y: auto;
      margin-top: 20px;
      margin-bottom: 20px; }
  .NewAssignmentPreview .previewQuestion {
    width: 100%; }
    .NewAssignmentPreview .previewQuestion .imagesWrapper {
      margin-top: 20px; }
  .NewAssignmentPreview .nothingPhrase {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.375rem; }
  .NewAssignmentPreview .previewPhrase {
    width: 350px;
    text-align: center;
    font-weight: 300; }
  .NewAssignmentPreview .paginationPanel {
    display: flex;
    width: 100%;
    height: 64px;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid #D3D9DE;
    z-index: 2;
    background: white; }

.RelatedArticlesPreview {
  display: flex;
  height: calc(100% - 80px);
  position: fixed;
  width: 100%;
  z-index: 1;
  padding: 0;
  top: 80px;
  left: 0;
  background: #e7ecef; }
  .RelatedArticlesPreview__content {
    display: flex;
    width: 100%; }
  .RelatedArticlesPreview__left {
    padding: 20px 25px;
    flex: none;
    width: 500px;
    height: 100%;
    overflow-y: scroll; }
  .RelatedArticlesPreview__right {
    width: calc(100% - 500px);
    flex: none;
    border-left: 2px solid #d2d2d2; }
  .RelatedArticlesPreview .wrapperArticles {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .RelatedArticlesPreview .wrapperArticles .content {
      display: flex;
      width: 100%;
      height: calc(100% - 64px);
      padding: 20px 20px 0 20px;
      font-weight: bold;
      flex-direction: column;
      border-bottom: 0; }
      .RelatedArticlesPreview .wrapperArticles .content .title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 1rem;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content; }
        .RelatedArticlesPreview .wrapperArticles .content .title span {
          color: #e2017b;
          text-transform: uppercase; }
        .RelatedArticlesPreview .wrapperArticles .content .title img {
          width: 29px;
          cursor: pointer;
          align-self: flex-start; }
      .RelatedArticlesPreview .wrapperArticles .content.loadingdata .itemFilter__core {
        opacity: 0.5;
        pointer-events: none; }
      .RelatedArticlesPreview .wrapperArticles .content.loadingdata .itemFlexFilter {
        pointer-events: none; }
      .RelatedArticlesPreview .wrapperArticles .content.loadingdata .css-1wrxaxw-Control {
        opacity: 0.4;
        pointer-events: none; }
      .RelatedArticlesPreview .wrapperArticles .content .SearchFilter {
        box-shadow: 0 0 7px -2px #bababa; }
        .RelatedArticlesPreview .wrapperArticles .content .SearchFilter .selector {
          border: 1px solid #D3D9DE;
          height: auto; }
      .RelatedArticlesPreview .wrapperArticles .content .cards {
        display: flex;
        width: calc(100% + 9px);
        flex-wrap: wrap;
        margin: 20px 0 10px -10px;
        height: calc(100vh - 350px);
        overflow-y: scroll; }
        .RelatedArticlesPreview .wrapperArticles .content .cards .noResults {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          margin-top: 20%;
          font-size: 1.5rem;
          justify-self: center; }
    .RelatedArticlesPreview .wrapperArticles .selectedArticles {
      display: flex;
      height: 64px;
      background: #FFF;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px; }
      .RelatedArticlesPreview .wrapperArticles .selectedArticles .counter {
        display: flex;
        background: #E7ECEF;
        margin: 10px 20px;
        border: 1px solid #939FA7;
        border-radius: 5px;
        align-items: center;
        padding: 7px 12px;
        height: 33px;
        font-size: 0.9375rem;
        font-weight: 300; }

.NewAssignment .main {
  z-index: 21; }

.NewAssignment .articlesListHeader {
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 #d3d9de;
  border: 1px solid #D3D9DE;
  padding-left: 20px;
  padding-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px; }
  .NewAssignment .articlesListHeader div {
    font-size: 1rem;
    font-weight: 500;
    color: #e2017b;
    font-weight: 500;
    cursor: pointer; }
    .NewAssignment .articlesListHeader div button {
      background: transparent; }
  .NewAssignment .articlesListHeader__left {
    display: flex;
    align-items: center; }
    .NewAssignment .articlesListHeader__left p {
      margin: 0px;
      margin-right: 25px;
      color: #e2017b; }
    .NewAssignment .articlesListHeader__left a {
      color: #0b2541; }
  .NewAssignment .articlesListHeader img {
    cursor: pointer;
    width: 33px;
    height: 45px;
    position: relative;
    top: 1px; }

.RelatedArticlesCard {
  display: flex;
  width: calc(100% / 5 - 20px);
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 0 13px -4px #575757;
  margin: 5px 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.5s;
  max-height: 306px; }
  .RelatedArticlesCard:hover {
    box-shadow: 0 8px 10px #00000061; }
  .RelatedArticlesCard .image {
    display: flex;
    background: #94a0a8;
    width: 100%;
    height: 180px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative; }
    .RelatedArticlesCard .image img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      object-fit: cover; }
    .RelatedArticlesCard .image .check {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      width: 36px;
      background: transparent;
      padding: 0px;
      right: 5px;
      top: 7px; }
      .RelatedArticlesCard .image .check:focus {
        outline: solid;
        outline-style: auto;
        outline-offset: 2px; }
      .RelatedArticlesCard .image .check .checkImg {
        width: 36px;
        height: 36px;
        z-index: 1;
        display: block; }
  .RelatedArticlesCard .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 15px;
    background: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: calc(100% - 180px); }
    .RelatedArticlesCard .info .titleText {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      font-size: 1.0625rem; }
    .RelatedArticlesCard .info .cardDescription {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow-y: hidden;
      margin: 5px 0 0;
      font-size: 0.9375rem; }
    .RelatedArticlesCard .info .levels {
      margin-top: 10px;
      padding: 10px 0 7px 0;
      border-top: 1px solid #D3D9DE;
      font-size: 0.8125rem; }
      .RelatedArticlesCard .info .levels img {
        margin-right: 7px; }
    .RelatedArticlesCard .info .grades {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 0 0;
      min-height: 33px; }
      .RelatedArticlesCard .info .grades span {
        max-width: 33%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #d4dadf;
        border-radius: 4px;
        font-size: 0.875rem;
        padding: 5px 7px;
        margin: 0 5px 5px 0;
        font-weight: 300; }

.NewAssignmentPreview .RelatedArticlesCard {
  width: calc(100% / 5 - 20px); }

.RelatedArticlesCard.selectedArticle {
  position: relative; }

.RelatedArticlesCard.selectedArticle:before {
  content: '';
  position: absolute;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  pointer-events: none;
  border: solid 1px;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 6px; }

@media screen and (max-width: 1024px) {
  .info .grades span {
    max-width: 100% !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; } }

@media screen and (max-width: 1569px) {
  /*.RelatedArticlesCard .info .titleText {    
    word-break: break-word;
    word-break: break-all;
  }*/
  .RelatedArticlesPreview .wrapperArticles .content .cards :nth-child(3n) {
    margin-right: 10px !important; }
  .RelatedArticlesCard {
    width: calc(100% / 4 - 20px); }
  .NewAssignmentPreview .RelatedArticlesCard {
    width: calc(100% / 4 - 20px); } }

@media screen and (max-width: 1369px) {
  .RelatedArticlesCard {
    width: calc(100% / 3 - 20px); } }

@media screen and (max-width: 960px) {
  .RelatedArticlesCard {
    width: calc(100% / 2 - 20px); } }

.MoreOptions {
  cursor: pointer;
  position: relative; }
  .MoreOptions button {
    padding: 0px;
    background: transparent;
    margin-left: 10px; }
    .MoreOptions button:focus {
      outline: black;
      outline-style: auto; }
  .MoreOptions .tooltip .left {
    top: -10px;
    right: 35px;
    position: absolute;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #596A75;
    box-shadow: 0 2px 4px 0 #d3d9de;
    padding: 20px;
    cursor: default; }
    .MoreOptions .tooltip .left ul {
      width: -webkit-max-content;
      width: max-content; }
      .MoreOptions .tooltip .left ul li {
        margin-bottom: 10px;
        justify-content: flex-end;
        cursor: pointer;
        align-items: center; }
        .MoreOptions .tooltip .left ul li a {
          width: 100%;
          justify-content: space-between;
          align-items: center; }
          .MoreOptions .tooltip .left ul li a:focus {
            outline: black;
            outline-style: auto;
            outline-offset: 2px; }
        .MoreOptions .tooltip .left ul li span {
          font-size: 0.9375rem;
          font-weight: 500; }
        .MoreOptions .tooltip .left ul li img {
          margin-left: 13px;
          width: 12px;
          height: 14px; }
        .MoreOptions .tooltip .left ul li:last-child {
          margin-bottom: 0; }
    .MoreOptions .tooltip .left i {
      position: absolute;
      top: 20px;
      left: 100%;
      margin-top: -12px;
      width: 12px;
      height: 24px;
      overflow: hidden; }
      .MoreOptions .tooltip .left i::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background-color: #fff;
        border: 1px solid #596A75; }

.content-block-dragged {
  position: relative;
  border: 1px dashed #D3D9DE;
  border-radius: 4px;
  background-color: #FFF; }
  .content-block-dragged::after {
    position: absolute;
    top: 21px;
    right: 48px;
    content: '';
    display: block;
    background: url(/static/media/drag-active.e60ca149.svg);
    background-size: contain;
    width: 25px;
    height: 25px; }

.absQuestionMoreOptions {
  position: absolute;
  top: 45px;
  right: 20px; }

.NewTextQuestion {
  position: relative; }

.NewMultipleChoiceQuestion {
  position: relative; }

.NewAssignment .AddQuestion {
  background: #E7ECEF;
  padding: 20px; }
  .NewAssignment .AddQuestion .addQuestionPhase {
    margin-bottom: 20px; }
  .NewAssignment .AddQuestion .questionTypeBox {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #d3d9de;
    height: 100px;
    width: 49%;
    cursor: pointer;
    text-align: left; }
    .NewAssignment .AddQuestion .questionTypeBox:first-child {
      margin-bottom: 20px; }
    .NewAssignment .AddQuestion .questionTypeBox:focus {
      outline: auto;
      outline-offset: 4px; }
    .NewAssignment .AddQuestion .questionTypeBox img {
      margin-right: 20px; }
    .NewAssignment .AddQuestion .questionTypeBox .questionType {
      margin-bottom: 5px; }

.NewAssignment .questionTitle {
  padding: 20px 20px  0; }
  .NewAssignment .questionTitle * {
    color: #596A75; }
  .NewAssignment .questionTitle .questionIcon {
    margin-right: 10px;
    padding: 0; }
  .NewAssignment .questionTitle .questionTitleText {
    padding: 0;
    word-break: break-all; }
  .NewAssignment .questionTitle .questionTitleText-hasError {
    color: #E2017B; }

.NewAssignment .newTextQuestionInput {
  border: none;
  resize: none;
  outline: none;
  padding: 0 20px;
  font-size: 1.375rem;
  margin-top: 20px;
  margin-bottom: 0;
  background-color: #E7ECEF; }
  .NewAssignment .newTextQuestionInput::placeholder {
    color: #939FA7; }

.NewAssignment .questionControlPanel {
  padding: 0 20px;
  margin-top: 20px; }

.NewAssignment .textExtra {
  align-items: flex-start;
  margin-bottom: 20px; }
  .NewAssignment .textExtra textarea {
    resize: none;
    border: none;
    width: 100%;
    margin-left: 20px; }

.NewTextQuestion {
  cursor: move;
  background-color: #E7ECEF;
  padding: 20px 0 40px;
  border-bottom: 1px solid #d3d9de; }
  .NewTextQuestion .newTextQuestionInput {
    border: none;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0 20px; }
    .NewTextQuestion .newTextQuestionInput::placeholder {
      color: #939FA7; }
  .NewTextQuestion .questionControlPanel {
    padding: 0 20px;
    margin-top: 20px; }
  .NewTextQuestion .attached-files {
    margin-top: 0;
    cursor: pointer; }

.hasError {
  border: 1px solid #e2017b !important;
  border-radius: 5px; }

.NewMultipleChoiceQuestion {
  cursor: move;
  background: #E7ECEF;
  border-bottom: 1px solid #d3d9de; }
  .NewMultipleChoiceQuestion .newTextQuestionInput {
    border: none;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 0; }
    .NewMultipleChoiceQuestion .newTextQuestionInput::placeholder {
      color: #939FA7; }
  .NewMultipleChoiceQuestion .options {
    margin-top: 20px;
    padding: 0 20px; }
  .NewMultipleChoiceQuestion .functionalButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px; }
    .NewMultipleChoiceQuestion .functionalButtons .newAnswerButtons {
      width: -webkit-max-content;
      width: max-content; }

.content-block-dragged.attached-files::after {
  top: 10px; }

.attached-files {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  cursor: pointer; }
  .attached-files .imageContent {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 60px);
    min-height: 60px;
    align-items: center; }
    .attached-files .imageContent .questionImageThumbnail {
      margin: 0px 10px 10px 0; }
    .attached-files .imageContent .placeholderImage {
      color: #6c6d7e;
      font-weight: 300; }
    .attached-files .imageContent .videoContent {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center; }
      .attached-files .imageContent .videoContent .playButton {
        height: 19px;
        width: 19px;
        position: absolute; }
      .attached-files .imageContent .videoContent video {
        width: 80px;
        margin: 2px;
        height: 60px;
        object-fit: cover; }
  .attached-files img {
    padding: 0px; }
  .attached-files .blockActions {
    display: none;
    align-self: flex-start; }
    .attached-files .blockActions button {
      padding: 0px;
      background: transparent;
      margin-left: 10px; }
      .attached-files .blockActions button:focus {
        outline: black;
        outline-style: auto; }
    .attached-files .blockActions img {
      width: 20px;
      height: 20px;
      cursor: pointer; }
  .attached-files .activeBlock {
    display: flex; }
  .attached-files:hover .blockActions {
    display: flex; }
  .attached-files .hovered {
    display: flex; }

.textDecorator {
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding: 19px 20px 1px 20px; }
  .textDecorator .textContent {
    width: calc(100% - 60px);
    font-weight: 300; }
  .textDecorator .ql-blank::before {
    font-size: 1.0625rem; }
  .textDecorator .blockActions {
    display: none;
    align-self: flex-start; }
    .textDecorator .blockActions button {
      padding: 0px;
      background: transparent;
      margin-left: 10px; }
      .textDecorator .blockActions button:focus {
        outline: black;
        outline-style: auto; }
    .textDecorator .blockActions img {
      width: 20px;
      height: 20px;
      cursor: pointer; }
  .textDecorator .activeBlock {
    display: flex; }
  .textDecorator:hover .blockActions {
    display: flex; }
  .textDecorator .hovered {
    display: flex; }

.activeBlock {
  background: #E7ECEF; }

.extra .extra-button {
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  background-color: #D3D9DE;
  border-radius: 5px;
  padding: 4px 6px;
  max-height: 28px;
  min-height: 28px;
  height: auto;
  text-align: center; }
  .extra .extra-button img {
    display: flex;
    align-self: center; }

.extra button:hover {
  background-color: #ccc;
  border-radius: 5px; }

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #596A75;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 10px;
  background: #D3D9DE;
  z-index: 7;
  cursor: move;
  height: 45px; }
  .option input {
    border: none;
    background: none;
    font-size: 1.0625rem;
    width: calc(100% - 100px);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500; }
    .option input::placeholder {
      color: #596A75; }
  .option .statusBox {
    display: flex; }
    .option .statusBox img {
      cursor: pointer; }
    .option .statusBox .checkStatus {
      width: 30px;
      height: 30px; }
  .option.isRight {
    border: 1px solid #0A7B24; }
  .option.hasErrorOption {
    border: 1px solid #E2017B; }
  .option:last-child {
    margin-bottom: 0; }

.evaluationOption {
  padding: 7px 20px !important; }
  .evaluationOption .evaluationStatus img {
    margin-right: 0 !important; }
  .evaluationOption .inputRight {
    color: #0A7B24; }
  .evaluationOption .inputWrong {
    color: #E2017B; }

.isWrong {
  border: 1px solid #E2017B !important; }

.option .statusBox button {
  padding: 0px;
  background: transparent; }
  .option .statusBox button.status {
    margin-right: 20px; }
  .option .statusBox button:focus {
    outline: auto !important; }

.imageOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D3D9DE;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 10px;
  background: #D3D9DE;
  z-index: 7;
  cursor: move;
  overflow: hidden; }
  .imageOption button {
    background: transparent;
    padding: 0px; }
    .imageOption button:focus {
      outline: auto; }
  .imageOption input {
    border: none;
    background: none;
    font-size: 1.0625rem;
    width: calc(100% - 100px);
    padding: 10px 0 10px 10px;
    height: 43px;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .imageOption input::placeholder {
      color: #596A75; }
  .imageOption .handleImage {
    padding: 10px;
    cursor: pointer;
    width: 38px;
    height: 38px; }
  .imageOption .miniImage {
    height: 43px;
    width: 51px;
    object-fit: cover; }
  .imageOption .statusBox {
    display: flex;
    padding-right: 20px; }
    .imageOption .statusBox button.status {
      margin-right: 20px; }
    .imageOption .statusBox img {
      cursor: pointer; }
    .imageOption .statusBox .checkStatus {
      width: 30px; }
  .imageOption.isRight {
    border: 1px solid #0A7B24; }
  .imageOption.hasErrorOption {
    border: 1px solid #E2017B; }
  .imageOption:last-child {
    margin-bottom: 0; }

.PublishingTitle {
  border-bottom: 1px solid #d3d9de;
  background-color: #ffffff; }
  .PublishingTitle .entityTitleWrapper {
    padding: 20px; }
    .PublishingTitle .entityTitleWrapper span {
      text-transform: uppercase;
      color: #e2017b; }
    .PublishingTitle .entityTitleWrapper .entityTitleInput {
      padding: 10px 0 0 0;
      font-size: 2.25rem;
      border: none;
      resize: none;
      outline: none;
      height: 57px; }
      .PublishingTitle .entityTitleWrapper .entityTitleInput::placeholder {
        color: #939FA7; }
    .PublishingTitle .entityTitleWrapper .entityDescriptionInput {
      margin-top: 20px;
      border: none;
      resize: none;
      outline: none;
      font-size: 1rem;
      padding: 0; }
      .PublishingTitle .entityTitleWrapper .entityDescriptionInput::placeholder {
        color: #939FA7; }
    .PublishingTitle .entityTitleWrapper .entityIsNotUnique {
      color: #FF0000;
      text-transform: none; }

.TagInput {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-weight: lighter;
  cursor: text; }
  .TagInput .tagWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: inherit; }
    .TagInput .tagWrapper .tag {
      position: relative;
      display: flex;
      height: auto;
      align-items: center;
      padding: 3px 5px 3px 2px;
      margin-right: 3px;
      margin-bottom: 4px;
      background: #FFF;
      border-radius: 20px;
      cursor: default;
      border: 1px solid #596A75; }
      .TagInput .tagWrapper .tag .title {
        margin-right: 5px;
        color: #0B2541;
        margin-bottom: 1px;
        font-size: 14px;
        font-weight: 300; }
      .TagInput .tagWrapper .tag img {
        cursor: pointer;
        width: 18px;
        height: 18px; }
      .TagInput .tagWrapper .tag:last-child {
        margin-right: 0; }
  .TagInput .unselected {
    transition: 0.3s;
    font-size: 0.9375rem;
    padding: 0 5px;
    cursor: pointer;
    height: -webkit-max-content;
    height: max-content;
    background: #e8edef;
    color: #0B2541;
    font-weight: 300; }
    .TagInput .unselected:hover {
      font-weight: bold; }
  .TagInput .selected {
    color: #0B2541;
    transition: 0.3s;
    font-size: 0.9375rem;
    padding: 0 5px;
    height: -webkit-max-content;
    height: max-content;
    background: #e8edef; }
  .TagInput .shoosen {
    background: #a3cefc;
    cursor: default; }
    .TagInput .shoosen:hover {
      background: #a3cefc; }
  .TagInput input {
    border: none;
    font-size: 1rem;
    margin-bottom: 4px;
    background: none; }
  .TagInput .TagsWindow {
    z-index: 1;
    display: flex;
    width: calc(100% + 2px);
    max-height: 135px;
    overflow-y: scroll;
    position: absolute;
    top: calc(100% - 5px);
    left: -1px;
    background: #fff;
    padding: 13px 70px 11px 13px;
    border: 1px solid #596A75;
    border-top: none;
    border-radius: 0 0 8px 8px; }
    .TagInput .TagsWindow .tag {
      text-align: left; }
      .TagInput .TagsWindow .tag:focus {
        background: #cecece; }
  .TagInput .listTags {
    flex-direction: column;
    flex-wrap: nowrap;
    background: #e8edef; }

.hideInput input {
  display: none !important; }

.TagInput input::placeholder {
  font-size: 14px;
  color: #0b2541; }

.TagInput input {
  font-size: 14px;
  color: #0b2541; }

.searchInput {
  padding-bottom: 10px !important; }
  .searchInput input {
    height: 32px; }

.centerMin {
  text-align: center;
  font-size: 14px; }

.PublishingActions {
  background-color: #ffffff; }
  .PublishingActions .infoContainer__top {
    padding: 22px 20px 35px 20px;
    border-bottom: 1px solid #d3d9de; }
  .PublishingActions .infoContainer__bottom {
    padding: 25px 20px 45px 20px;
    border-bottom: 1px solid #d3d9de; }
  .PublishingActions .infoContainer__secondTitle h2 {
    margin: 0px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    color: #e2017b; }
  .PublishingActions .infoContainer__secondTitle p {
    font-size: 16px;
    max-width: 600px;
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: 300; }
  .PublishingActions .infoContainer__filters {
    display: flex;
    flex-wrap: wrap; }
    .PublishingActions .infoContainer__filters .itemsFlex {
      margin-bottom: 10px;
      margin-right: 10px;
      max-width: 48%;
      min-width: 350px; }
      .PublishingActions .infoContainer__filters .itemsFlex .SearchFilter__select {
        margin-right: 0px;
        margin-bottom: 0px;
        width: 100%;
        height: auto;
        padding: 12px 10px;
        padding-right: 30px; }
  .PublishingActions .infoContainer__body {
    margin: 20px 0px; }
    .PublishingActions .infoContainer__body__title {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .PublishingActions .infoContainer__body__title img {
        width: 22px;
        height: 22px;
        margin-right: 5px; }
      .PublishingActions .infoContainer__body__title h3 {
        margin: 0px;
        font-size: 16px; }
    .PublishingActions .infoContainer__body__table .itemTablesTd {
      flex: 1 1;
      font-size: 14px; }
      .PublishingActions .infoContainer__body__table .itemTablesTd.icons {
        width: 20px;
        flex: none;
        margin-right: 10px; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.icons button {
          padding: 0px;
          background: transparent;
          display: block; }
          .PublishingActions .infoContainer__body__table .itemTablesTd.icons button img {
            display: block;
            width: 20px; }
            .PublishingActions .infoContainer__body__table .itemTablesTd.icons button img.checkImgFalse {
              display: none; }
          .PublishingActions .infoContainer__body__table .itemTablesTd.icons button.active img.checkImg {
            display: none; }
          .PublishingActions .infoContainer__body__table .itemTablesTd.icons button.active img.checkImgFalse {
            display: block; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.icons a {
          display: block; }
          .PublishingActions .infoContainer__body__table .itemTablesTd.icons a img {
            display: block;
            width: 20px; }
      .PublishingActions .infoContainer__body__table .itemTablesTd.grade {
        max-width: 115px;
        margin-right: 15px; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.grade span:after {
          content: ', '; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.grade span:last-child:after {
          display: none; }
      .PublishingActions .infoContainer__body__table .itemTablesTd.core {
        margin-right: 15px; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.core span:after {
          content: ', '; }
        .PublishingActions .infoContainer__body__table .itemTablesTd.core span:last-child:after {
          display: none; }
      .PublishingActions .infoContainer__body__table .itemTablesTd.subjects {
        max-width: 150px; }
    .PublishingActions .infoContainer__body__table .itemTablesHeader .itemTablesTh {
      display: flex;
      margin-bottom: 20px; }
    .PublishingActions .infoContainer__body__table .itemTablesBody {
      padding-bottom: 80px; }
      .PublishingActions .infoContainer__body__table .itemTablesBody .itemTablesTr {
        display: flex;
        border-bottom: solid 1px #d3d9de;
        padding-bottom: 10px;
        padding-top: 10px; }
  .PublishingActions .infoContainer__hidden {
    display: none; }
  .PublishingActions .infoContainer .title {
    font-size: 1.0625rem;
    padding-left: 10px; }
  .PublishingActions .infoContainer .filterBy {
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 30px 7px 5px;
    border: 1px solid #596A75;
    border-radius: 5px;
    background: #E7ECEF url(/static/media/search-bold.1a3658bf.svg) no-repeat right 8px top 14px; }
  .PublishingActions .infoContainer .darkTheme .tagWrapper .tag {
    background: #0B2541;
    border-color: #0A243F; }
    .PublishingActions .infoContainer .darkTheme .tagWrapper .tag button {
      background: transparent;
      padding: 0px; }
      .PublishingActions .infoContainer .darkTheme .tagWrapper .tag button:focus {
        outline: auto; }
    .PublishingActions .infoContainer .darkTheme .tagWrapper .tag span {
      color: white; }
    .PublishingActions .infoContainer .darkTheme .tagWrapper .tag img {
      color: white; }
  .PublishingActions .infoContainer .studentLevelButtons {
    width: 100%;
    height: 52px;
    margin-top: 8px; }
    .PublishingActions .infoContainer .studentLevelButtons button {
      display: flex;
      background-color: #FFF;
      justify-content: center;
      align-items: center;
      width: calc(100% / 3);
      border: 1px solid #596A75;
      font-weight: 300; }
      .PublishingActions .infoContainer .studentLevelButtons button:focus {
        outline: auto;
        outline-offset: -4px; }
      .PublishingActions .infoContainer .studentLevelButtons button:first-child {
        border-radius: 5px 0 0 5px; }
      .PublishingActions .infoContainer .studentLevelButtons button:last-child {
        border-radius: 0 5px 5px 0; }
      .PublishingActions .infoContainer .studentLevelButtons button.active {
        background-color: #0B2541;
        border: 1px solid #0B2541;
        color: #ffffff; }
        .PublishingActions .infoContainer .studentLevelButtons button.active img {
          filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(300deg) brightness(107%) contrast(101%); }
      .PublishingActions .infoContainer .studentLevelButtons button img {
        margin-right: 10px; }
  .PublishingActions .infoContainer .visibility .flex-align {
    align-items: center; }
  .PublishingActions .infoContainer .visibility p {
    font-size: 16px;
    max-width: 550px;
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: 300; }
  .PublishingActions .infoContainer .visibilityButtons {
    width: 100%;
    height: 52px;
    margin-top: 7px;
    max-width: 350px; }
    .PublishingActions .infoContainer .visibilityButtons button {
      background-color: #E7ECEF;
      border: 1px solid #596A75;
      font-weight: 300; }
      .PublishingActions .infoContainer .visibilityButtons button:focus {
        outline: auto;
        outline-offset: -4px; }
      .PublishingActions .infoContainer .visibilityButtons button:first-child {
        border-radius: 5px 0 0 5px; }
      .PublishingActions .infoContainer .visibilityButtons button:last-child {
        border-radius: 0 5px 5px 0; }
      .PublishingActions .infoContainer .visibilityButtons button.active {
        background-color: #0B2541;
        border: 1px solid #0B2541;
        color: #ffffff; }
        .PublishingActions .infoContainer .visibilityButtons button.active img {
          filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(300deg) brightness(107%) contrast(101%); }
      .PublishingActions .infoContainer .visibilityButtons button img {
        margin-right: 10px; }

.css-1wa3eu0-placeholder {
  color: #0b2541 !important; }

@media screen and (max-width: 1024px) {
  .PublishingActions {
    height: calc(100% - 78px); }
    .PublishingActions .infoContainer .studentLevelButtons button img {
      width: 15px; } }

.Header {
  display: flex;
  flex-direction: column; }
  .Header .withPicture {
    min-height: 244px;
    display: flex;
    width: 100%;
    padding: 20px;
    background: #0B2541; }
    .Header .withPicture .headerBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .Header .withPicture .teachingPathTitle {
      font-size: 2.25rem;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      word-break: break-all; }
    .Header .withPicture .teacher {
      margin-top: 20px;
      z-index: 2; }
      .Header .withPicture .teacher img {
        width: 48px;
        border-radius: 24px;
        border: 1px solid #ffffff; }
      .Header .withPicture .teacher .teacherInfo {
        margin-left: 17px;
        font-size: 1.0625rem; }
        .Header .withPicture .teacher .teacherInfo div {
          color: #ffffff; }
          .Header .withPicture .teacher .teacherInfo div span {
            color: #ffffff; }
  .Header .teachingPathInfo {
    min-height: 67px;
    display: flex;
    border-bottom: 1px solid #d3d9de; }
    .Header .teachingPathInfo div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8125rem;
      font-weight: 500;
      margin-left: 32px; }
      .Header .teachingPathInfo div img {
        margin-right: 7px;
        width: 19px;
        height: 19px; }
      .Header .teachingPathInfo div:first-child {
        margin-left: 0; }

.QuestionsOverview {
  width: 100%;
  padding: 20px;
  overflow: auto;
  height: 100%; }
  .QuestionsOverview .questions {
    margin-bottom: 40px; }
    .QuestionsOverview .questions .question {
      margin-bottom: 10px; }
      .QuestionsOverview .questions .question p {
        width: calc(100% - 25px);
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis; }
      .QuestionsOverview .questions .question:last-child {
        font-weight: 500; }
      .QuestionsOverview .questions .question .questionNumber {
        font-weight: 500;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #000;
        font-size: 0.625rem; }
  .QuestionsOverview .title {
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 1.0625rem; }
  .QuestionsOverview .gradesSubjectsContainer {
    margin-bottom: 40px; }
    .QuestionsOverview .gradesSubjectsContainer .gradesSubjects {
      width: 100%;
      flex-wrap: wrap; }
      .QuestionsOverview .gradesSubjectsContainer .gradesSubjects .gradeSubject {
        display: inline-flex;
        padding: 7px 16px 7px 17px;
        font-size: 0.8125rem;
        margin: 0 10px 10px 0;
        background: #0B2541;
        border: 1px solid #071E37;
        border-radius: 25px;
        color: #ffffff; }

.PublishingContent {
  position: relative;
  margin-left: 60%;
  width: 40%;
  height: 100%;
  background: #ffffff; }
  .PublishingContent .teachingPathContent {
    height: calc(100% - 311px); }

.PublishingPage {
  margin-top: 77px;
  width: 100%;
  height: calc(100% - 80px);
  background: #ffffff; }
  .PublishingPage .publishingInfo {
    position: fixed;
    overflow: hidden;
    background: #E7ECEF;
    padding: 0;
    overflow: auto;
    height: 100%;
    border-right: 1px solid #d3d9de;
    width: 60%; }

.DistributionTitle {
  background-color: #ffffff;
  border-bottom: 1px solid #d3d9de;
  padding: 16px 19px; }
  .DistributionTitle span {
    text-transform: uppercase;
    color: #e2017b; }
  .DistributionTitle .distributeTitle {
    padding-top: 10px;
    font-size: 2.25rem;
    font-weight: 500; }
  .DistributionTitle .distributeDescription {
    margin-top: 20px; }

.DistributionActions {
  background-color: #ffffff;
  padding: 30px 20px 40px 20px;
  border-bottom: 1px solid #d3d9de; }
  .DistributionActions div p {
    font-size: 1.0625rem;
    padding-left: 10px; }
  .DistributionActions div button {
    width: 55%;
    margin-top: 10px;
    padding: 15px 20px;
    border-radius: 25px;
    background-color: #D3D9DE;
    font-weight: 500; }
    .DistributionActions div button:disabled {
      color: #939FA7;
      background-color: #D3D9DE;
      cursor: default; }
  .DistributionActions .distributeLink {
    width: 100%;
    height: 54px;
    margin-top: 3px;
    padding: 10px 20px;
    background: #E7ECEF;
    border: 1px solid #596A75;
    border-radius: 5px; }
    .DistributionActions .distributeLink div {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .DistributionActions .distributeLink img {
      margin-left: 15px;
      width: 20px;
      cursor: pointer; }

.Calendar {
  position: absolute !important;
  top: 20px;
  z-index: 100;
  margin-top: 10px;
  width: 352px !important;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding-bottom: 10px; }
  .Calendar .react-datepicker__navigation {
    width: 14px;
    height: 14px;
    top: 16px;
    border: none; }
  .Calendar .react-datepicker__navigation--previous {
    background: url(/static/media/calendar-navigation-left.fd043ed1.svg) no-repeat;
    left: 30px; }
  .Calendar .react-datepicker__navigation--next {
    background: url(/static/media/calendar-navigation-right.e948e2df.svg) no-repeat;
    right: 30px; }
  .Calendar .react-datepicker__month-container {
    width: 350px; }
    .Calendar .react-datepicker__month-container .react-datepicker__header {
      border: 0;
      padding: 10px 0;
      background-color: #ffffff; }
      .Calendar .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
        display: inline-flex;
        justify-content: center;
        padding: 5px 0;
        margin-bottom: 10px;
        width: calc(100% - 20px);
        background: #D3D9DE;
        border-radius: 5px; }
      .Calendar .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
        padding: 0; }
        .Calendar .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 1.0625rem;
          font-weight: 600;
          margin: 0;
          width: 50px;
          height: 50px; }
    .Calendar .react-datepicker__month-container .react-datepicker__month {
      margin: 0; }
      .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
        width: 350px;
        margin-top: 5px; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled {
          color: #CCCCCC; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 1.0625rem;
          font-weight: 500;
          width: 50px;
          height: 50px;
          margin: 0;
          border-radius: 0; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--in-selecting-range {
          background: #E7ECEF;
          color: black; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selecting-range-end {
          background-color: #939FA7 !important; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--in-range {
          background: #D3D9DE;
          color: black; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .deadlineDayWrapper {
          height: 50px;
          width: 50px; }
        .Calendar .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
          color: #ffffff;
          background: #2D9A46;
          overflow: hidden; }

.studentsList {
  margin: 0 20px;
  border: 1px solid #939FA7;
  border-radius: 5px; }
  .studentsList .includeExclude {
    display: inline-flex;
    justify-content: flex-end; }
  .studentsList .listHeader {
    padding: 10px 20px;
    font-size: 0.8125rem;
    color: #939FA7;
    border: 1px solid #939FA7;
    border-bottom: none;
    border-top: none; }
    .studentsList .listHeader div {
      color: #596A75; }
    .studentsList .listHeader .level {
      width: 37px; }
    .studentsList .listHeader .name {
      margin-left: 20px; }
  .studentsList .studentsListContainer {
    background-color: #E7ECEF;
    padding: 0 20px 20px 20px;
    border-radius: 0 0 5px 5px;
    border: 1px solid #939FA7; }
    .studentsList .studentsListContainer .student {
      z-index: 100;
      padding: 20px 0 0 0;
      align-items: center;
      justify-content: space-between; }
      .studentsList .studentsListContainer .student .level {
        width: 37px; }
        .studentsList .studentsListContainer .student .level img {
          margin-right: 7px; }
        .studentsList .studentsListContainer .student .level.disabled {
          text-decoration: line-through;
          color: #939FA7; }
      .studentsList .studentsListContainer .student .name {
        margin-left: 20px; }
        .studentsList .studentsListContainer .student .name span {
          text-decoration: line-through;
          color: #596A75; }
      .studentsList .studentsListContainer .student div {
        color: #0B2541; }
      .studentsList .studentsListContainer .student img {
        width: 20px;
        height: 20px; }
      .studentsList .studentsListContainer .student .deadline {
        cursor: pointer;
        position: relative;
        font-size: 0.875rem; }
        .studentsList .studentsListContainer .student .deadline img {
          margin-left: 10px; }
        .studentsList .studentsListContainer .student .deadline .Calendar {
          right: 0; }
        .studentsList .studentsListContainer .student .deadline.endDateFromOutside {
          color: #939FA7;
          font-weight: 500;
          font-size: 0.875rem;
          align-items: center; }
        .studentsList .studentsListContainer .student .deadline.disabled {
          cursor: default; }
      .studentsList .studentsListContainer .student .includeExclude {
        cursor: pointer;
        margin-left: 10px; }
        .studentsList .studentsListContainer .student .includeExclude button {
          background: transparent;
          padding: 0px; }
          .studentsList .studentsListContainer .student .includeExclude button:focus {
            outline: auto;
            outline-offset: 2px; }
  .studentsList .whiteBackground {
    background: white; }

.myClassesBorder {
  border-top: none;
  border-radius: 0 0 5px 5px; }
  .myClassesBorder .student:last-child {
    border-bottom: none;
    border-radius: 0 0 5px 5px; }

@media screen and (max-width: 1280px) {
  .studentsList .studentsListContainer .student .level {
    font-size: 0.9375rem; }
  .studentsList .studentsListContainer .student .name {
    font-size: 0.75rem; }
  .studentsList .studentsListContainer .student .deadline {
    font-size: 0.75rem; } }

@media screen and (max-width: 575px) {
  .StudentsList {
    height: auto; }
    .StudentsList__list {
      width: calc(100% + 19px);
      display: flex; }
    .StudentsList__listItem {
      margin-bottom: 5px;
      margin-top: 0px;
      width: calc(100% - 20px);
      flex: none;
      margin-right: 10px; } }

.MyClasses {
  max-height: calc(100% - 245px); }
  .MyClasses .classesContainer {
    overflow: auto; }
    .MyClasses .classesContainer .myClassContainer {
      margin: 0 20px 16px 18px; }
      .MyClasses .classesContainer .myClassContainer:first-child {
        margin-top: 0; }
      .MyClasses .classesContainer .myClassContainer .myClassInfo {
        background-color: #ffffff;
        border: 1px solid #939FA7;
        border-radius: 7px;
        padding: 0 20px; }
        .MyClasses .classesContainer .myClassContainer .myClassInfo .title {
          padding: 20px 0; }
        .MyClasses .classesContainer .myClassContainer .myClassInfo .studentsNumber {
          text-align: right;
          padding: 20px 0;
          color: #E2017B; }
        .MyClasses .classesContainer .myClassContainer .myClassInfo .deadline {
          position: relative;
          display: flex;
          justify-content: flex-end;
          margin-left: 20px;
          padding: 20px 0;
          cursor: pointer;
          font-weight: 500; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .deadline img {
            margin-left: 10px; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .deadline .Calendar {
            right: 0;
            top: 40px; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .deadline.endDateFromOutside {
            color: #939FA7;
            font-size: 0.9375rem; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .deadline.disabled {
            cursor: default; }
        .MyClasses .classesContainer .myClassContainer .myClassInfo .includeExclude {
          cursor: pointer;
          justify-content: flex-end;
          padding: 20px 0;
          margin-left: 20px;
          color: #939FA7; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .includeExclude button {
            background: transparent;
            padding: 0px; }
            .MyClasses .classesContainer .myClassContainer .myClassInfo .includeExclude button:focus {
              outline: auto;
              outline-offset: 2px; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .includeExclude img {
            width: 20px;
            height: 20px; }
        .MyClasses .classesContainer .myClassContainer .myClassInfo .check {
          cursor: pointer;
          justify-content: flex-end;
          margin-left: 20px;
          padding: 10px 0; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .check button {
            background: transparent;
            padding: 0px; }
            .MyClasses .classesContainer .myClassContainer .myClassInfo .check button:focus {
              outline: auto;
              outline-offset: 2px; }
          .MyClasses .classesContainer .myClassContainer .myClassInfo .check img {
            width: 30px; }

.StudentsAssigning {
  height: 100%;
  background-color: #ffffff; }
  .StudentsAssigning .dueDate {
    margin: 19px 18px 20px 20px; }
    .StudentsAssigning .dueDate .title {
      text-transform: uppercase;
      color: #e2017b;
      margin-bottom: 25px; }
    .StudentsAssigning .dueDate .deadlineInfo {
      position: relative;
      font-size: 0.9375rem; }
      .StudentsAssigning .dueDate .deadlineInfo img {
        margin-right: 10px;
        width: 18px;
        height: 18px; }
      .StudentsAssigning .dueDate .deadlineInfo span, .StudentsAssigning .dueDate .deadlineInfo button {
        text-decoration: underline;
        cursor: pointer;
        background: transparent;
        padding: 0px; }
        .StudentsAssigning .dueDate .deadlineInfo span:focus, .StudentsAssigning .dueDate .deadlineInfo button:focus {
          outline: auto; }
      .StudentsAssigning .dueDate .deadlineInfo .Calendar {
        left: 0; }
  .StudentsAssigning .assignToClass {
    text-transform: uppercase;
    color: #e2017b;
    margin: 40px 20px 0 20px; }
  .StudentsAssigning .searchClassesContainer {
    display: flex;
    margin: 18px;
    border: 1px solid #939FA7;
    border-radius: 7px; }
    .StudentsAssigning .searchClassesContainer .searchClasses {
      height: 60px;
      width: 100%;
      padding: 20px;
      font-size: 0.9375rem;
      border: 0;
      border-radius: 7px; }
      .StudentsAssigning .searchClassesContainer .searchClasses::placeholder {
        font-weight: 300;
        color: #6E7C85; }
    .StudentsAssigning .searchClassesContainer img {
      width: 20px;
      margin-right: 20px; }

.DistributionContent {
  position: relative;
  width: 50%;
  height: 100%;
  border-left: 1px solid #d3d9de;
  background: #E7ECEF; }

.summary {
  display: flex;
  flex-direction: column;
  background: #E7ECEF; }
  .summary .titleSummary {
    text-transform: uppercase;
    color: #e2017b;
    font-weight: 500;
    margin: 37px 20px 6px 20px; }
  .summary .headerSummary {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 5px 20px; }
    .summary .headerSummary .studentsImg {
      margin-right: 10px; }
    .summary .headerSummary .date {
      display: flex;
      align-items: center; }
  .summary .noStudents {
    padding: 0 20px;
    font-size: 1rem; }

.DistributionPage {
  margin-top: 80px;
  width: 100%;
  background: #ffffff;
  height: calc(100% - 80px); }
  .DistributionPage .distributionInfo {
    background: #E7ECEF;
    padding: 0;
    overflow: auto;
    height: 100%; }

.content {
  padding: 40px 20px;
  border-bottom: 1px solid #d3d9de;
  background: #E7ECEF; }
  .content .settingsWrapper {
    display: flex;
    flex-direction: column; }
    .content .settingsWrapper .settingsImage {
      display: flex;
      align-items: center; }
      .content .settingsWrapper .settingsImage img {
        height: 28px;
        width: 28px; }
      .content .settingsWrapper .settingsImage span {
        padding: 0 10px;
        color: #0B2541; }
    .content .settingsWrapper .specify {
      font-size: 0.9375rem;
      font-weight: 300;
      margin: 10px 0 20px; }
  .content .articlesWrapper .relatedArticleContent {
    padding: 0 0 16px 0; }
  .content .articlesWrapper button {
    font-size: 1.0625rem; }
  .content .articlesWrapper .tag button {
    background: transparent;
    padding: 0px; }
    .content .articlesWrapper .tag button:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }

.NewAssignment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 4; }
  .NewAssignment .main {
    margin-top: 80px;
    height: calc(100% - 80px); }
  .NewAssignment .addAssignmentFlow {
    overflow: auto;
    height: 100%; }
  .NewAssignment .backdrop {
    background: #0B2541;
    position: absolute;
    height: 100%;
    width: 50%;
    opacity: 0.35; }

.loading {
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100vh - 78px);
  z-index: 10;
  background: #E7ECEF;
  top: 78px;
  left: 0;
  justify-content: center;
  align-items: center; }

.ArrowControls {
  display: flex;
  flex-direction: column;
  background: #D3D9DE;
  padding: 20px 19px 20px 19px; }

.ArrowControls__button {
  margin-bottom: 8px;
  background: transparent;
  position: relative;
  padding-bottom: 7px;
  padding-left: 40px;
  padding-top: 6px;
  text-align: left;
  font-size: 0.9375rem; }
  .ArrowControls__button:disabled {
    background: transparent;
    cursor: default; }

.ArrowControls__buttonsBlock {
  margin-bottom: 11px; }

.ArrowControls__button_exit {
  color: #E2017B;
  margin-top: 0;
  margin-bottom: 0; }

.ArrowControls__image_previous {
  transform: rotateZ(180deg);
  margin-right: 2px; }

.ArrowControls__image_next {
  margin-left: 1px; }

.ArrowControls__imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  left: 0;
  border: 2px solid #0B2541; }

.ArrowControls__imageContainer_exit {
  border: 2px solid #E2017B; }

.ArrowControls__tooltip {
  background: #FFF;
  min-width: 260px;
  display: none;
  opacity: 0;
  position: absolute;
  bottom: 38px;
  left: 1px;
  box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.13);
  border: 1px solid #596A75;
  border-radius: 4px;
  padding: 17px 15px 18px 15px; }
  .ArrowControls__tooltip:after {
    content: '';
    display: block;
    position: absolute;
    border-right: 1px solid #596A75;
    border-bottom: 1px solid #596A75;
    background: #FFF;
    left: 75px;
    top: 51px;
    width: 7px;
    height: 7px;
    transform: rotateZ(45deg); }

.ArrowControls__tooltipImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px; }

.ArrowControls__tooltipText {
  white-space: nowrap; }

.ArrowControls__button:hover .ArrowControls__tooltip {
  display: block;
  opacity: 1; }

@media screen and (max-width: 870px) {
  .ArrowControls__button {
    display: none; }
  .ArrowControls__button_exit {
    display: block; } }

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .ArrowControls {
    position: fixed;
    bottom: 0;
    width: 230px; } }

.cover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex; }
  .cover__content {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    width: 750px;
    padding: 50px 70px; }
  .cover .mainImage {
    width: 600px;
    height: 45%;
    max-height: 326px;
    align-self: center; }
  .cover .authorInfo {
    text-align: center;
    margin-bottom: 20px; }
    .cover .authorInfo img {
      display: block;
      margin: auto;
      width: 48px;
      border-radius: 50%;
      margin-bottom: 10px; }
    .cover .authorInfo h4 {
      margin: 0px;
      font-size: 17px; }
  .cover .metaInfo {
    display: flex;
    align-items: center;
    justify-content: center; }
    .cover .metaInfo img {
      margin-right: 10px;
      width: 20px; }
    .cover .metaInfo p {
      margin: 0px;
      font-size: 13px;
      line-height: 17px; }
    .cover .metaInfo__deadline {
      display: flex;
      align-items: center;
      margin-right: 30px; }
    .cover .metaInfo__steps {
      display: flex;
      align-items: center; }
  .cover .infoTeacher {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .cover .infoTeacher img {
      margin-bottom: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100%; }
    .cover .infoTeacher .subject {
      font-size: 0.9375rem;
      font-weight: 300; }
  .cover .assignmentInfo {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px; }
    .cover .assignmentInfo .extraInfo {
      display: flex;
      align-items: flex-end;
      font-size: 0.8125rem;
      margin: 0 16px; }
      .cover .assignmentInfo .extraInfo img {
        width: 18px;
        height: 18px;
        margin-right: 10px; }
  .cover .assignmentTitle {
    font-size: 2.5rem;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    display: block; }
  .cover .assignmentDescription {
    font-size: 1.0625rem;
    margin-bottom: 30px;
    display: flex;
    align-self: center;
    padding: 10px 20px;
    text-align: center;
    font-weight: 300;
    justify-content: center; }
  .cover .startButton {
    min-width: 200px;
    align-self: center;
    text-align: center;
    margin-bottom: 20px; }
    .cover .startButton button {
      font-weight: 500;
      min-width: 200px; }

@media (max-width: 870px) {
  .cover .assignmentTitle {
    font-size: 1.5rem; } }

.articleTeachingPath {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .articleTeachingPath .chooseOne {
    color: #E2017B;
    text-transform: uppercase;
    font-weight: bold; }
  .articleTeachingPath .title {
    font-weight: bold;
    font-size: 1.875rem;
    margin: 20px 0; }
    .articleTeachingPath .title:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 4px; }
  .articleTeachingPath .cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: inherit;
    overflow: auto; }
    .articleTeachingPath .cards .passedStyle .InfoCard {
      box-shadow: 0 0 7px 0 #0A7B24; }
    .articleTeachingPath .cards .InfoCard {
      margin: 10px; }

.visibleArticle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
  width: 100%;
  height: 100%; }
  .visibleArticle .headerArticle {
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    height: 80px;
    border-top: 10px solid #e2017b;
    padding: 0 20px;
    border-bottom: 1px solid #e4e9ed;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0; }
    .visibleArticle .headerArticle .back {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .visibleArticle .headerArticle .back span {
        margin: 0 7px;
        color: #939fa7;
        font-weight: bold; }
    .visibleArticle .headerArticle .finish {
      display: flex;
      align-items: center; }
  .visibleArticle .inlineArticle {
    display: flex;
    width: 100%;
    height: 100vh;
    padding-top: 80px; }

.articleTeachingPath {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100%;
  padding-top: 20px; }
  .articleTeachingPath .chooseOne {
    color: #E2017B;
    text-transform: uppercase;
    font-weight: 500; }
  .articleTeachingPath .title {
    font-weight: 500;
    font-size: 2.25rem;
    margin: 20px 0; }
  .articleTeachingPath .cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: inherit;
    overflow: auto;
    max-height: calc(100% - 80px);
    overflow-y: scroll;
    max-width: 950px;
    margin-bottom: 20px;
    justify-content: center; }
    .articleTeachingPath .cards .passedStyle .InfoCard {
      box-shadow: 0 0 7px 0 #0A7B24; }
    .articleTeachingPath .cards .InfoCard {
      margin: 10px; }

.articleTeachingPath {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .articleTeachingPath .chooseOne {
    color: #E2017B;
    text-transform: uppercase;
    font-weight: bold; }
  .articleTeachingPath .title {
    font-weight: bold;
    font-size: 1.875rem;
    margin: 20px 0; }
  .articleTeachingPath .cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: inherit;
    overflow: auto; }
    .articleTeachingPath .cards .passedStyle .InfoCard {
      box-shadow: 0 0 7px 0 #0A7B24; }
    .articleTeachingPath .cards .InfoCard {
      margin: 10px; }

.visibleArticle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
  width: 100%;
  height: 100%; }
  .visibleArticle .headerArticle {
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    height: 80px;
    border-top: 10px solid #e2017b;
    padding: 0 20px;
    border-bottom: 1px solid #e4e9ed;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0; }
    .visibleArticle .headerArticle .back {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .visibleArticle .headerArticle .back span {
        margin: 0 7px;
        color: #939fa7;
        font-weight: bold; }
    .visibleArticle .headerArticle .finish {
      display: flex;
      align-items: center; }
  .visibleArticle .inlineArticle {
    display: flex;
    width: 100%;
    height: 100vh;
    padding-top: 80px; }

.passageTeachingPath {
  display: flex;
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  z-index: 20; }
  .passageTeachingPath .passageTeachingPathBreadCrumbs {
    position: absolute;
    top: 0;
    background: #e7ecef;
    width: 100%;
    height: 11px;
    z-index: 16; }
    .passageTeachingPath .passageTeachingPathBreadCrumbs ul {
      display: flex;
      align-items: stretch;
      height: 100%; }
      .passageTeachingPath .passageTeachingPathBreadCrumbs ul li {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:before {
          content: '';
          height: 6px;
          width: 6px;
          background: #E2017B;
          border-radius: 50%;
          display: block; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:after {
          content: '';
          position: absolute;
          width: 0;
          height: 100%;
          background: #E2017B;
          left: 0; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li.used:after {
          width: 100%; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li.used.rounded:after {
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:last-child:before {
          display: none; }
        .passageTeachingPath .passageTeachingPathBreadCrumbs ul li:last-child:after {
          border-radius: 0px !important; }
  .passageTeachingPath .teachingPathNewBreadCrumbs {
    position: absolute;
    top: 25px;
    z-index: 20;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%); }
    .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb {
      padding: 10px;
      height: auto;
      margin: 0px;
      border-top: none; }
      .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb .breadcrumbs {
        align-items: center; }
        .passageTeachingPath .teachingPathNewBreadCrumbs .breadcrumb .breadcrumbs li {
          font-size: 15px; }
    .passageTeachingPath .teachingPathNewBreadCrumbs button {
      background: transparent; }
      .passageTeachingPath .teachingPathNewBreadCrumbs button.navigationNextButton img {
        transform: rotate(180deg); }
      .passageTeachingPath .teachingPathNewBreadCrumbs button img {
        width: 20px; }
  .passageTeachingPath .teachingPathNavigate {
    display: flex;
    flex-direction: column;
    background: #E7ECEF;
    width: 230px;
    min-width: 230px;
    justify-content: space-between; }
    .passageTeachingPath .teachingPathNavigate .stepOverview {
      display: flex;
      padding: 20px;
      flex-direction: column; }
      .passageTeachingPath .teachingPathNavigate .stepOverview span {
        text-overflow: ellipsis;
        overflow: hidden; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .overview {
        font-size: 0.9375rem;
        color: #E2017B;
        text-transform: uppercase;
        margin-bottom: 10px; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .overview span {
          word-break: break-word; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .titleTeachingPath {
        margin: 0px;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden; }
      .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper {
        display: flex;
        padding: 10px 0 0;
        align-items: center; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .questionNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          border: 2px solid #0B2541;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          margin-right: 10px; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .questionName {
          font-size: 0.9375rem; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .selectedItem-circle {
          color: #0A7B24;
          border: 2px solid #0A7B24; }
        .passageTeachingPath .teachingPathNavigate .stepOverview .questionWrapper .selectedItem {
          color: #0A7B24; }
    .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath {
      display: flex;
      background: #D3D9DE;
      padding: 20px;
      flex-direction: column; }
      .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationButton {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer; }
        .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationButton span {
          margin-left: 10px;
          font-size: 0.9375rem; }
      .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: transparent; }
        .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton span {
          margin-left: 10px;
          font-size: 0.9375rem;
          color: #E2017B; }
  .passageTeachingPath .teachingPathWrapper {
    display: flex;
    height: calc(100% - 78px); }
    .passageTeachingPath .teachingPathWrapper .wrapperTeachingPath {
      width: 100%; }
      .passageTeachingPath .teachingPathWrapper .wrapperTeachingPath .contentTeachingPath {
        height: 100%;
        width: 100%; }

.sharedFloatingButton {
  position: absolute;
  background: #0b2541;
  width: 40px;
  height: 40px;
  z-index: 99;
  border-radius: 50%;
  right: 20px;
  bottom: 50px; }
  .sharedFloatingButton a {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center; }
    .sharedFloatingButton a:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px; }
    .sharedFloatingButton a img {
      display: block;
      filter: invert(1);
      width: 17px;
      height: 17px;
      margin-left: -1px; }

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation: landscape) {
  .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath {
    position: fixed;
    bottom: 0;
    width: 230px; } }

.TeachingPathTitle {
  margin-top: 20px;
  width: 100%; }
  .TeachingPathTitle .titleWrapper {
    width: 50%;
    padding: 20px;
    align-items: center; }
    .TeachingPathTitle .titleWrapper span {
      text-transform: uppercase;
      color: #e2017b;
      font-size: 1rem; }
    .TeachingPathTitle .titleWrapper .titleInput {
      width: 100%;
      text-align: center;
      padding: 0;
      margin-top: 10px;
      background-color: #E7ECEF;
      font-size: 2.25rem;
      border: none;
      resize: none;
      outline: none;
      overflow: hidden;
      min-height: 47px; }
      .TeachingPathTitle .titleWrapper .titleInput::placeholder {
        color: #939FA7; }
    .TeachingPathTitle .titleWrapper .descriptionInput {
      position: relative;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      background-color: #E7ECEF;
      border: none;
      resize: none;
      outline: none;
      overflow: hidden;
      font-size: 1.0625rem;
      font-weight: 300;
      padding: 1px;
      min-height: 24px; }
      .TeachingPathTitle .titleWrapper .descriptionInput::placeholder {
        color: #939FA7; }
  .TeachingPathTitle .horizontalLine {
    width: 135px;
    margin-top: 40px;
    border-bottom: 1px solid #d3d9de; }

@media screen and (max-width: 1024px) {
  .TeachingPathTitle {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; } }

@media screen and (max-width: 870px) {
  .TeachingPathTitle .titleWrapper {
    width: auto; } }

.header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 20;
  background-color: #ffffff;
  height: 80px;
  border-top: 10px solid #e2017b;
  padding: 0 20px;
  border-bottom: 1px solid #e4e9ed; }
  .header .assignmentTabs {
    height: 100%;
    margin-left: 99px;
    position: relative; }
  .header .back-button {
    cursor: pointer; }
    .header .back-button img {
      margin-right: 10px; }
  .header .link {
    text-transform: uppercase;
    height: 100%;
    position: relative;
    margin: 0 20px;
    color: #939fa7;
    font-weight: 500;
    font-size: 0.9375rem; }
  .header .disabled-link {
    pointer-events: none; }
  .header .activeRoute {
    color: #0B2541; }
    .header .activeRoute::after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #e2017b;
      bottom: 0;
      left: 0;
      width: 100%; }
  .header .doneBox span {
    color: #596A75;
    margin-right: 20px;
    font-size: 0.9375rem; }
  .header .CreateButton {
    margin: 0 5px; }

@media screen and (min-width: 1400px) {
  .header .assignmentTabs {
    right: -123px; } }

@media screen and (max-width: 1024px), screen and (max-width: 1080px) {
  .header .link {
    font-size: 0.75rem !important; }
  .header .doneBox span {
    font-size: 0.75rem !important; }
  .header .doneBox .CreateButton {
    font-size: 0.9375rem; } }

.AssignmentsList {
  position: relative;
  height: calc(100% - 60px);
  background: #E7ECEF; }
  .AssignmentsList .assignmentsListHeader {
    width: calc(100% - 40px);
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    border: 1px solid #D3D9DE;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px; }
    .AssignmentsList .assignmentsListHeader div {
      font-size: 1rem;
      font-weight: 500;
      color: #e2017b;
      font-weight: 500;
      cursor: pointer; }
      .AssignmentsList .assignmentsListHeader div button {
        background: transparent;
        margin-right: 20px;
        padding: 0px; }
        .AssignmentsList .assignmentsListHeader div button:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 3px; }
        .AssignmentsList .assignmentsListHeader div button.selectedTab {
          color: #e2017b; }
    .AssignmentsList .assignmentsListHeader__left {
      display: flex;
      align-items: center; }
      .AssignmentsList .assignmentsListHeader__left p {
        margin: 0px;
        margin-right: 25px;
        color: #e2017b; }
      .AssignmentsList .assignmentsListHeader__left a {
        color: #0b2541; }
        .AssignmentsList .assignmentsListHeader__left a:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 3px; }
    .AssignmentsList .assignmentsListHeader__right button {
      margin-right: 0px !important; }
    .AssignmentsList .assignmentsListHeader img {
      cursor: pointer;
      width: 33px;
      height: 45px;
      position: relative;
      top: 1px; }
  .AssignmentsList .SearchFilter {
    margin-left: 20px;
    margin-right: 20px; }
  .AssignmentsList .assignmentsListContainer {
    margin-top: 20px;
    padding: 0 20px 0 20px;
    overflow: auto; }
    .AssignmentsList .assignmentsListContainer .skeletonAssignmentItem, .AssignmentsList .assignmentsListContainer .assignmentItem {
      height: 60px;
      min-height: 60px;
      align-items: center;
      padding-right: 20px;
      margin: 0 0 20px 0;
      border-radius: 5px;
      font-size: 1.0625rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13); }
    .AssignmentsList .assignmentsListContainer .assignmentItem {
      background: #FFF; }
      .AssignmentsList .assignmentsListContainer .assignmentItem.selectedItem {
        position: relative; }
        .AssignmentsList .assignmentsListContainer .assignmentItem.selectedItem:before {
          content: '';
          position: absolute;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          pointer-events: none;
          border-radius: 5px;
          border: solid 2px;
          left: 0px;
          top: 0px; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemImg {
        width: 90px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px 0 0 5px; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemLikes {
        height: 34px;
        width: 60px;
        padding: 10px;
        margin: 10px 0 10px 20px;
        background: #E7ECEF;
        border-radius: 5px;
        font-size: 0.75rem; }
        .AssignmentsList .assignmentsListContainer .assignmentItem .itemLikes img {
          margin-right: 10px; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemTitle {
        margin-left: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemSubject {
        margin-left: 20px;
        background: #E7ECEF;
        border: 1px solid #D3D9DE;
        border-radius: 25px;
        padding: 10px 15px;
        font-size: 0.9375rem;
        font-weight: 300; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemNumberOfQuestions {
        margin-left: 20px;
        color: #E2017B;
        font-size: 0.9375rem;
        width: -webkit-max-content;
        width: max-content; }
      .AssignmentsList .assignmentsListContainer .assignmentItem .itemIsSelected {
        margin-left: 20px; }
        .AssignmentsList .assignmentsListContainer .assignmentItem .itemIsSelected img {
          height: 30px;
          width: 30px;
          cursor: pointer; }
  .AssignmentsList .noResults {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin-top: 20%;
    font-size: 1.5rem; }
  .AssignmentsList .MyListPagination {
    margin-bottom: 10px; }
  .AssignmentsList .assignmentsListFooter {
    position: fixed;
    bottom: 0;
    width: calc(100% - 500px);
    height: 64px;
    padding: 15px 20px;
    background: #FFF;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .AssignmentsList .assignmentsListFooter .assignmentCounter {
      height: 30px;
      padding: 10px;
      font-size: 0.9375rem;
      background: #FFFFFF;
      border: 1px solid #D3D9DE;
      border-radius: 5px; }

@media screen and (max-width: 1024px) {
  .AssignmentsList .assignmentsListFooter {
    width: calc(100% - 400px); } }

@media screen and (device-width: 810px) and (device-height: 1080px) {
  .AssignmentsList .assignmentsListFooter {
    width: calc(100% - 300px); } }

.ArticlesList {
  position: relative;
  height: calc(100% - 60px);
  background: #E7ECEF; }
  .ArticlesList .articlesListHeader {
    width: calc(100% - 40px);
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    border: 1px solid #D3D9DE;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px; }
    .ArticlesList .articlesListHeader div {
      font-size: 1rem;
      font-weight: 500;
      color: #e2017b;
      font-weight: 500;
      cursor: pointer; }
      .ArticlesList .articlesListHeader div button {
        background: transparent; }
    .ArticlesList .articlesListHeader__left {
      display: flex;
      align-items: center; }
      .ArticlesList .articlesListHeader__left p {
        margin: 0px;
        margin-right: 25px;
        color: #e2017b; }
      .ArticlesList .articlesListHeader__left a {
        color: #0b2541; }
    .ArticlesList .articlesListHeader img {
      cursor: pointer;
      width: 33px;
      height: 45px;
      position: relative;
      top: 1px; }
  .ArticlesList .SearchFilter {
    margin-left: 20px;
    margin-right: 20px; }
  .ArticlesList .articlesListContainer {
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 10px 0 10px;
    overflow: auto; }
    .ArticlesList .articlesListContainer .RelatedArticlesCard {
      margin: 10px 13px 10px 0;
      margin-right: 10px;
      margin-left: 10px;
      max-height: 306px; }
    .ArticlesList .articlesListContainer .noResults {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 20%; }
  .ArticlesList .articlesListFooter {
    position: absolute;
    bottom: -60px;
    width: 100%;
    height: 60px;
    padding: 15px 20px;
    background: #FFF;
    font-weight: 300;
    justify-content: space-between; }
    .ArticlesList .articlesListFooter .selectedArticles {
      height: 30px;
      padding: 10px;
      font-size: 0.9375rem;
      background: #FFFFFF;
      border: 1px solid #D3D9DE;
      border-radius: 5px; }

.ArticlesList .articlesListHeader__left a:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px; }

.back-buttonAbs {
  display: flex;
  align-items: center;
  position: fixed;
  top: 31px;
  z-index: 99999999;
  left: 20px;
  font-size: 15px;
  background: white;
  padding: 0px; }
  .back-buttonAbs img {
    margin-right: 10px; }

.addItemModalBackground {
  position: fixed;
  overflow: auto;
  justify-content: flex-end;
  width: 100vw;
  height: calc(100% - 80px);
  top: 80px;
  background: rgba(11, 37, 65, 0.2171);
  z-index: 100; }
  .addItemModalBackground .addItemModal {
    width: 100%;
    height: 100%;
    background: #ffffff; }
    .addItemModalBackground .addItemModal.background_2 {
      background: transparent; }
    .addItemModalBackground .addItemModal__content {
      display: flex;
      background: #e7ecef;
      height: 100%; }
    .addItemModalBackground .addItemModal__left {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 20px;
      padding-bottom: 20px;
      flex: none;
      width: 500px;
      height: 100%;
      overflow-y: scroll; }
    .addItemModalBackground .addItemModal__right {
      width: calc(100% - 500px);
      flex: none;
      border-left: solid 2px #d2d2d2; }
      .addItemModalBackground .addItemModal__right.loadingdata .itemFilter__core {
        opacity: 0.5;
        pointer-events: none; }
      .addItemModalBackground .addItemModal__right.loadingdata .itemFlexFilter {
        pointer-events: none; }
      .addItemModalBackground .addItemModal__right.loadingdata .css-1wrxaxw-Control {
        opacity: 0.4;
        pointer-events: none; }

.defaultContentModal p {
  font-size: 16px;
  line-height: 22px; }

.defaultContentModal h2 {
  margin: 10px 0px;
  font-size: 22px;
  color: #e2017b;
  font-weight: 400; }

.defaultContentModal__content h3 {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 20px; }

.defaultContentModal__content p {
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 15px; }

.defaultContentModal__content .CreateButton {
  display: block;
  padding: 9px 20px;
  max-width: 150px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  font-weight: 200; }

.defaultContentModal__expand .expandContent {
  margin-bottom: 25px;
  position: relative;
  cursor: pointer; }
  .defaultContentModal__expand .expandContent:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-bottom: solid 1px #0b2541;
    border-right: solid 1px #0b2541;
    transform: rotate(45deg);
    position: relative;
    top: -4px;
    margin-left: 10px;
    transition: 0.3s; }
  .defaultContentModal__expand .expandContent.active:after {
    transform: rotate(225deg);
    top: 1px; }

.defaultContentModal__inside .listItemInside {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px; }
  .defaultContentModal__inside .listItemInside .lisItemInsideIcon {
    margin-right: 5px; }
    .defaultContentModal__inside .listItemInside .lisItemInsideIcon img {
      height: 22px; }
  .defaultContentModal__inside .listItemInside .lisItemInsideText h5 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 16px; }
  .defaultContentModal__inside .listItemInside .lisItemInsideText .grades, .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects {
    display: flex;
    flex-wrap: wrap; }
    .defaultContentModal__inside .listItemInside .lisItemInsideText .grades span, .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects span {
      background: #d2d2d2;
      font-size: 14px;
      padding: 6px 5px;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px; }
  .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects {
    font-size: 14px;
    color: black; }
    .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects li {
      color: black;
      margin-bottom: 5px;
      position: relative;
      padding-left: 10px; }
      .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects li:before {
        content: '';
        margin-right: 5px;
        width: 5px;
        height: 1px;
        background: black;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0px;
        top: 9px; }
      .defaultContentModal__inside .listItemInside .lisItemInsideText .subjects li::first-letter {
        text-transform: uppercase; }
  .defaultContentModal__inside .listItemInside .lisItemInsideText .greepContentList {
    font-size: 14px;
    color: black; }
    .defaultContentModal__inside .listItemInside .lisItemInsideText .greepContentList li {
      color: black;
      margin-bottom: 5px;
      position: relative;
      padding-left: 10px; }
      .defaultContentModal__inside .listItemInside .lisItemInsideText .greepContentList li:before {
        content: '';
        margin-right: 5px;
        width: 5px;
        height: 1px;
        background: black;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0px;
        top: 9px; }
      .defaultContentModal__inside .listItemInside .lisItemInsideText .greepContentList li::first-letter {
        text-transform: uppercase; }
  .defaultContentModal__inside .listItemInside.listItemGreep h5 {
    position: relative;
    cursor: pointer; }
    .defaultContentModal__inside .listItemInside.listItemGreep h5:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-bottom: solid 1px #0b2541;
      border-right: solid 1px #0b2541;
      transform: rotate(45deg);
      position: relative;
      top: -4px;
      margin-left: 10px;
      transition: 0.3s; }
  .defaultContentModal__inside .listItemInside.listItemGreep .greepContentList {
    display: none; }
  .defaultContentModal__inside .listItemInside.listItemGreep.active h5:after {
    transform: rotate(225deg);
    top: 1px; }
  .defaultContentModal__inside .listItemInside.listItemGreep.active .greepContentList {
    display: block; }

@media screen and (max-width: 1280px) {
  .addItemModalBackground .addItemModal {
    width: 100%; }
    .addItemModalBackground .addItemModal__left {
      width: 400px; }
    .addItemModalBackground .addItemModal__right {
      width: calc(100% - 400px); } }

@media screen and (max-width: 1024px) {
  .addItemModalBackground .addItemModal {
    width: 100%; }
    .addItemModalBackground .addItemModal__left {
      width: 400px; }
    .addItemModalBackground .addItemModal__right {
      width: calc(100% - 400px); } }

@media screen and (device-width: 810px) and (device-height: 1080px) {
  .addItemModalBackground .addItemModal {
    width: 100%; }
    .addItemModalBackground .addItemModal__left {
      width: 300px; }
    .addItemModalBackground .addItemModal__right {
      width: calc(100% - 300px); } }

.AddingButtons {
  cursor: pointer;
  width: 248px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09487);
  border-radius: 5px; }
  .AddingButtons .addingButton {
    display: flex;
    height: 62px;
    align-items: center;
    background: transparent; }
    .AddingButtons .addingButton button {
      padding: 0px;
      width: 100%;
      display: flex;
      height: 62px;
      align-items: center;
      background: transparent;
      font-size: 1.0625rem;
      padding: 10px 20px; }
      .AddingButtons .addingButton button:focus {
        outline: solid;
        outline-style: auto; }
    .AddingButtons .addingButton img {
      margin-right: 20px;
      width: 38px;
      height: 38px; }
    .AddingButtons .addingButton:first-child {
      border-radius: 5px 5px 0 0; }
    .AddingButtons .addingButton:last-child {
      border-radius: 0 0 5px 5px; }
    .AddingButtons .addingButton:hover {
      background: #939FA7;
      color: #ffffff; }
      .AddingButtons .addingButton:hover button {
        color: #ffffff; }
      .AddingButtons .addingButton:hover img {
        filter: brightness(0) invert(1); }

.modalDomain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modalDomain__background {
    background: #0b254175;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%; }
  .modalDomain__content {
    position: relative;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    width: calc(100% - 40px);
    max-width: 450px; }
  .modalDomain__form {
    display: flex;
    align-items: stretch; }
  .modalDomain__input {
    display: flex;
    flex: 1 1;
    align-items: center; }
    .modalDomain__input img {
      width: 28px;
      margin-left: 15px;
      margin-right: 5px; }
    .modalDomain__input input {
      border: none;
      padding: 20px 5px;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box; }
  .modalDomain__button .btn {
    height: 100%;
    background: #0b2541;
    color: white;
    padding: 20px; }
    .modalDomain__button .btn:disabled {
      background: #bdbdbd; }
    .modalDomain__button .btn:focus {
      outline: white;
      outline-style: auto;
      outline-offset: -1px; }

.numberAndActions {
  width: 100px;
  cursor: default;
  margin-bottom: -3px;
  z-index: 3;
  min-height: 31px; }
  .numberAndActions.firstNumber {
    margin-bottom: 20px; }
  .numberAndActions button {
    padding: 0px; }
    .numberAndActions button:focus {
      outline: black;
      outline-style: auto; }
  .numberAndActions .nestedOrderNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    min-height: 31px;
    background: #E2017B;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.0625rem;
    margin: 0 10px; }
  .numberAndActions img {
    width: 25px;
    height: 25px;
    cursor: pointer; }

.main {
  position: absolute;
  min-width: 100%; }
  .main .teachingPathItemsContainer {
    position: relative;
    min-width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; }
    .main .teachingPathItemsContainer.rootContainer {
      padding-bottom: 50px;
      padding-top: 17px; }
      .main .teachingPathItemsContainer.rootContainer::before {
        content: none; }
    .main .teachingPathItemsContainer::before {
      content: "";
      width: 100%;
      height: 2px;
      background: #D3D9DE;
      position: absolute;
      top: -35px; }
    .main .teachingPathItemsContainer.first::before {
      width: 50%;
      transform: translateX(50%); }
    .main .teachingPathItemsContainer.last::before {
      width: 50%;
      transform: translateX(-50%); }
    .main .teachingPathItemsContainer.solo::before {
      width: 50%; }
    .main .teachingPathItemsContainer.contentNone::before {
      content: none; }
    .main .teachingPathItemsContainer.mergeLineBeforeButton::after {
      content: "";
      width: 50%;
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 377px;
      transform: translateX(50%); }
    .main .teachingPathItemsContainer.mergeLineFullWidth::after {
      content: "";
      width: 100%;
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 377px;
      transform: translateX(0); }
    .main .teachingPathItemsContainer.mergeLineAfterButton::after {
      content: "";
      width: 50%;
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 377px;
      transform: translateX(-50%); }
    .main .teachingPathItemsContainer .teachingPathItemsTitleDiv {
      position: relative;
      z-index: 2; }
    .main .teachingPathItemsContainer .teachingPathItemsTitle {
      margin: 0 0 17px 0;
      min-height: 34px;
      text-align: center;
      background-color: #ffffff;
      font-size: 1.4375rem;
      border: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0724978);
      border-radius: 4px;
      resize: none;
      outline: none;
      z-index: 2;
      padding: 2px 0; }
      .main .teachingPathItemsContainer .teachingPathItemsTitle::placeholder {
        font-weight: 300; }
    .main .teachingPathItemsContainer .unmergeImg {
      margin-top: 15px;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;
      background: url(/static/media/unmerge.0f708e5f.svg);
      background-size: cover; }
      .main .teachingPathItemsContainer .unmergeImg:hover {
        background: url(/static/media/unmerged-hovered.7f4a0235.svg);
        background-size: cover; }
      .main .teachingPathItemsContainer .unmergeImg:focus {
        outline: black;
        outline-style: auto; }
    .main .teachingPathItemsContainer .nestedOrderNumberContainer {
      position: relative;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
      z-index: 4; }
      .main .teachingPathItemsContainer .nestedOrderNumberContainer.withoutUnmergeButton {
        margin-top: 55px; }
      .main .teachingPathItemsContainer .nestedOrderNumberContainer .topVerticalLine {
        height: 25px;
        min-height: 25px;
        left: 49px;
        top: -22px;
        z-index: 1; }
    .main .teachingPathItemsContainer .infoCardsContainer {
      flex-direction: row;
      position: relative;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content; }
      .main .teachingPathItemsContainer .infoCardsContainer .infoCardContainer {
        position: relative; }
        .main .teachingPathItemsContainer .infoCardsContainer .infoCardContainer::before {
          width: 100%;
          content: "";
          height: 2px;
          background: #D3D9DE;
          position: absolute;
          top: -35px; }
        .main .teachingPathItemsContainer .infoCardsContainer .infoCardContainer.first::before {
          width: 50%;
          transform: translateX(100%); }
        .main .teachingPathItemsContainer .infoCardsContainer .infoCardContainer.last::before {
          width: 50%;
          transform: translateX(0); }
        .main .teachingPathItemsContainer .infoCardsContainer .infoCardContainer.solo::before {
          width: 0%; }
    .main .teachingPathItemsContainer .teachingPathItems {
      margin-top: 40px; }
    .main .teachingPathItemsContainer .teachingPathButtons {
      position: relative; }
      .main .teachingPathItemsContainer .teachingPathButtons.withUnmergeButton {
        margin-top: 15px; }
      .main .teachingPathItemsContainer .teachingPathButtons.withPadding {
        margin-top: 70px; }
      .main .teachingPathItemsContainer .teachingPathButtons.withoutPadding {
        margin-top: 15px !important; }
    .main .teachingPathItemsContainer .InfoCard {
      margin: 0 20px; }
    .main .teachingPathItemsContainer .childrenContainer {
      position: relative;
      flex-direction: row; }
    .main .teachingPathItemsContainer .mergePanel {
      display: flex; }
      .main .teachingPathItemsContainer .mergePanel .mergeTooltip, .main .teachingPathItemsContainer .mergePanel .unmergeTooltip {
        display: none;
        background: #596A75;
        padding: 8px 20px;
        position: absolute;
        top: 311px;
        color: white;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        z-index: 5; }
        .main .teachingPathItemsContainer .mergePanel .mergeTooltip.enLocale, .main .teachingPathItemsContainer .mergePanel .enLocale.unmergeTooltip {
          left: -63px; }
        .main .teachingPathItemsContainer .mergePanel .mergeTooltip.nbLocale, .main .teachingPathItemsContainer .mergePanel .nbLocale.unmergeTooltip {
          left: -88px; }
        .main .teachingPathItemsContainer .mergePanel .mergeTooltip.nnLocale, .main .teachingPathItemsContainer .mergePanel .nnLocale.unmergeTooltip {
          left: -81px; }
      .main .teachingPathItemsContainer .mergePanel .unmergeTooltip.enLocale {
        left: inherit;
        margin-left: -48px; }
      .main .teachingPathItemsContainer .mergePanel .unmergeTooltip.nbLocale {
        left: inherit;
        margin-left: -41px; }
      .main .teachingPathItemsContainer .mergePanel .unmergeTooltip.nnLocale {
        left: inherit;
        margin-left: -41px; }
      .main .teachingPathItemsContainer .mergePanel .mergeButton {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 358px;
        left: -20px;
        cursor: pointer;
        z-index: 3;
        background: url(/static/media/merge.27436a1c.svg);
        background-size: cover; }
        .main .teachingPathItemsContainer .mergePanel .mergeButton:focus {
          outline: black;
          outline-style: auto; }
      .main .teachingPathItemsContainer .mergePanel:hover .mergeButton {
        background: url(/static/media/merge-hovered.50c5cea5.svg);
        background-size: cover; }
      .main .teachingPathItemsContainer .mergePanel:hover .mergeTooltip, .main .teachingPathItemsContainer .mergePanel:hover .unmergeTooltip {
        display: flex; }
        .main .teachingPathItemsContainer .mergePanel:hover .mergeTooltip:after, .main .teachingPathItemsContainer .mergePanel:hover .unmergeTooltip:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          bottom: -12px;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: #596A75; }
      .main .teachingPathItemsContainer .mergePanel:hover .unmergeTooltip {
        display: flex; }
        .main .teachingPathItemsContainer .mergePanel:hover .unmergeTooltip:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          bottom: -12px;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: #596A75; }
  .main .topVerticalLine {
    border-left: 2px solid #D3D9DE;
    height: 35px;
    min-height: 35px;
    position: absolute;
    top: -35px;
    z-index: 1; }
  .main .bottomVerticalLine {
    border-left: 2px solid #D3D9DE;
    height: 35px;
    position: absolute;
    bottom: -35px;
    z-index: 1; }
  .main .bottomHorizontalLine {
    border-bottom: 2px solid #D3D9DE;
    left: 160px;
    position: absolute;
    z-index: 1;
    bottom: -35px; }
  .main .exitButton {
    display: flex;
    width: 100%;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0; }
    .main .exitButton .exitTeachingPath {
      padding: 20px;
      background: #E7ECEF;
      border-radius: 4px; }
      .main .exitButton .exitTeachingPath span {
        margin-left: 10px;
        color: #E2017B; }

.EditTeachingPath {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E7ECEF;
  overflow: auto;
  z-index: 4; }
  .EditTeachingPath .AppHeader {
    position: fixed;
    width: 100%;
    z-index: 20; }
  .EditTeachingPath .main {
    margin-top: 80px;
    height: calc(100% - 80px); }

.ConfirmationPage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 78px;
  height: calc(100% - 78px);
  width: 100%;
  background: #ffffff;
  z-index: 15; }

.ConfirmationPage_thumb {
  width: 78px;
  height: 78px;
  margin-bottom: 42px; }

.ConfirmationPage_title {
  font-size: 2.25rem;
  margin-bottom: 24px; }

.ConfirmationPage_body {
  font-weight: 300;
  margin-bottom: 37px; }

.ConfirmationPage_edit {
  cursor: pointer;
  text-decoration: underline; }

.ConfirmationPage_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 25px;
  flex-direction: row;
  padding: 0 15px 0 10px; }
  .ConfirmationPage_button img {
    width: 33px;
    margin-right: 10px; }

.test {
  border: 1px solid red; }

* {
  outline: none;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #0b2541; }

html {
  height: 100%; }

body {
  margin: 0;
  height: 100%; }

img {
  display: block; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul li {
    margin: 0;
    padding: 0; }

a {
  color: inherit;
  text-decoration: none;
  outline: none; }

button {
  border: none;
  font-size: 1rem;
  outline: none;
  cursor: pointer; }

.z10 {
  z-index: 10; }

select {
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 0 10px;
  -webkit-appearance: none;
          appearance: none; }
  select option {
    width: 100%; }

textarea {
  outline: none;
  font-size: inherit; }
  textarea::placeholder {
    color: #939FA7; }

input, select, option {
  outline: none; }

.flexBox {
  display: flex; }

.spaceBetween {
  justify-content: space-between; }

.justifyCenter {
  justify-content: center; }

.justifyStart {
  justify-content: flex-start; }

.justifyEnd {
  justify-content: flex-end; }

.alignCenter {
  align-items: center; }

.dirColumn {
  flex-direction: column; }

.dirRow {
  flex-direction: row; }

.flexWrap {
  flex-wrap: wrap; }

.h100 {
  height: 100%; }

.w50 {
  width: 50%; }

.w100 {
  width: 100%; }

.roboto {
  font-family: 'Roboto Slab', serif; }

.tc1 {
  color: #0B2541; }

.tc2 {
  color: #404041; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 500; }

.fw500 {
  font-weight: 500; }

.fw500 {
  font-weight: 500; }

.fs12 {
  font-size: 0.75rem; }

.fs15 {
  font-size: 0.9375rem; }

.fs17 {
  font-size: 1.0625rem; }

.fs22 {
  font-size: 1.375rem; }

.fs36 {
  font-size: 2.25rem; }

.mr20 {
  margin-right: 20px; }

.mb20 {
  margin-bottom: 20px; }

.ffRoboto400 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400; }

.relative {
  position: relative; }

.cursorPointer {
  cursor: pointer; }

.w100 {
  width: 100%; }

.upp {
  text-transform: uppercase; }

.lightItem {
  background-color: white !important; }

.fs13 {
  font-size: 0.8125rem; }

#root {
  height: 100%; }

.App {
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  font-size: 1.0625rem; }
  .App.zoom100 {
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0; }
  .App.zoom150 {
    zoom: 1.1;
    -moz-transform: scale(1.1);
    -moz-transform-origin: 0 0; }
  .App.zoom200 {
    zoom: 1.2;
    -moz-transform: scale(1.2);
    -moz-transform-origin: 0 0; }

.App__sidebar {
  flex-shrink: 0; }

.App__view {
  height: calc(100% - 78px);
  display: flex; }
  .App__view.isFirstTimeVisiting {
    height: calc(100% - 211px); }
    .App__view.isFirstTimeVisiting .fixedsModal .FiltersModal {
      top: 211px;
      height: calc(100% - 211px); }

.App__content {
  padding: 19px;
  background: #E7ECEF;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-weight: 500; }

::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: #d3d9de;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
  background: #adb9c2; }

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word; }

.App__notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  width: auto;
  top: 90px;
  right: 20px;
  z-index: 100; }

.isMaintenance .ConfirmationPage_container {
  top: 105px; }

.isMaintenance .StudentOverview__content {
  margin-top: 40px;
  height: calc(100% - 100px); }

.isMaintenance .teachingPathsList .dark .evaluationInfo {
  height: calc(100% - 180px);
  top: 180px; }

.isMaintenance .teachingPathsList .dark {
  top: 115px;
  height: calc(100% - 115px); }

.isMaintenance .assignmentList .darkStudentList {
  top: 105px;
  height: calc(100% - 105px); }

.isMaintenance .assignmentList .darkStudentList .evaluationInfo {
  top: 180px;
  height: calc(100% - 180px); }

.isMaintenance .Submitted {
  top: 105px; }

.isMaintenance .loading {
  top: 105px;
  height: calc(100vh - 105px); }

@media screen and (max-height: 1024px) {
  ::-webkit-scrollbar {
    width: 0; } }

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 0; }
  .App__content {
    background: #E7ECEF;
    transition: margin-left 200ms ease-in-out, margin-right 200ms ease-in-out; }
  .App__content_shifted {
    margin-left: 211px;
    margin-right: -211px; } }

@media screen and (max-width: 1024px) and (min-width: 871px) {
  .App__content {
    margin-left: 66px; }
  .App__content_shifted {
    margin-left: 211px;
    margin-right: -211px; } }

@media screen and (max-width: 870px) {
  .App__content {
    padding-top: 0; } }

@media screen and (max-width: 425px) {
  .App__notifications {
    left: 20px; } }

.fw300 {
  font-weight: 300; }

.fw500 {
  font-weight: 500; }

.fw500 {
  font-weight: 500; }

.fs15 {
  font-size: 0.9375rem; }

.fs17 {
  font-size: 1.0625rem; }

.fs24 {
  font-size: 1.5rem; }

.fs30 {
  font-size: 1.875rem; }

.fs36 {
  font-size: 2.25rem; }

.fs60 {
  font-size: 3.75rem; }

.disabledArea {
  pointer-events: none;
  opacity: 0.5; }

.overHidden {
  overflow: hidden; }

.hidden {
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  opacity: 0; }

.AppHeader__submenuWrapper:focus-within {
  opacity: 1;
  pointer-events: all; }

.generalTitle {
  font-size: 1.625rem;
  margin: 0px;
  margin-bottom: 20px; }

@media screen and (max-width: 574px) {
  .generalTitle {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center; } }

.AppHeader__block a:focus {
  outline: solid; }

.MyAccountWindow__itemText:focus {
  text-decoration: underline; }

.AppHeader__navigationItem a:focus {
  border-bottom: solid 1px black;
  display: block; }

.SliderWidget__button:focus {
  outline: solid;
  outline-offset: 5px;
  outline-width: thin;
  outline-color: black;
  outline-style: auto; }

.SliderWidget__switchButton:focus {
  outline: solid; }

.Sidebar__listItem:focus {
  background: #f1f1f1;
  border-bottom: solid 2px black; }

.TabNavigation ul li .sidebarLink:focus {
  outline: solid; }

.CreateButton:focus {
  outline: solid;
  outline-color: black;
  outline-style: auto;
  outline-offset: 2px; }

.SearchFilter__select:focus {
  outline: solid;
  outline-offset: 5px;
  outline-style: auto; }

.ListWidget__text:focus {
  outline: solid; }

.MyList a:focus img, .AssignmentDistributeList a:focus img, .StudentListItem:focus img, .StudentsList__list a:focus img, .MyList a.StudentAssignmentsListItem:focus {
  outline: solid;
  outline-color: white;
  outline-width: thin; }

.MyList a:focus li.AssignmentListItem, .AssignmentDistributeList a:focus li, .StudentListItem:focus, .AssignmentDistributeList a:focus div, .StudentsList__list a:focus li, .MyList a.StudentAssignmentsListItem:focus {
  background: #e4e4e4 !important; }

.SubmitTeachingPath__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px; }

.Submitted__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px; }

.AnswerCover__button:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px; }

.AppHeader__dropdown:focus ~ .AppHeader__submenuWrapper {
  opacity: 1;
  pointer-events: all; }

.passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath .navigationExitButton:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 2px; }

.AppHeader__dropdownItem__subMenu:focus-within {
  opacity: 1;
  pointer-events: all; }

.addItemModal button:focus {
  outline: black !important;
  outline-style: auto !important;
  outline-offset: -4px; }

.AssignmentsList .assignmentsListHeader button {
  background: transparent; }

.AssignmentsList .assignmentsListHeader button:focus {
  outline: black !important;
  outline-style: auto !important;
  outline-offset: -4px; }

.AssignmentsList .assignmentsListContainer .assignmentItem:focus {
  background: #d8d8d8;
  outline: auto !important;
  outline-offset: -1px; }

.RelatedArticlesPreview .title button:focus {
  outline: auto;
  outline-style: auto !important;
  outline-offset: 2px; }

.extra .extra-button:focus {
  outline: auto !important;
  outline-offset: 2px; }

.AssignmentAnswer:focus {
  background: #d4d4d4; }

.checking .controlPanel .save:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 2px; }

.checking .mainContainer .comments .markItem:focus {
  outline: black;
  outline-style: auto; }

.StudentAssignments__search:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 4px; }

.StudentAssignments__select:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 4px; }

.maintenance .maintenance__close img {
  border: solid 2px black;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  filter: brightness(0) invert(1);
  margin-right: 5px; }

.maintenance .maintenance__close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer; }

.maintenance .maintenance__content {
  max-width: 450px;
  margin: auto;
  color: white;
  font-weight: 400; }

.maintenance p {
  margin: 0px;
  color: white; }

.maintenance {
  background: #e2017b;
  text-align: center;
  padding-top: 15px;
  position: relative;
  z-index: 2; }

.App.isMaintenance {
  height: auto; }

.css-2b097c-container:focus-within .css-1vlg5zl {
  outline: black;
  outline-style: auto;
  outline-offset: 4px; }

.css-2b097c-container:focus-within .css-1dije2o-Control {
  outline: black;
  outline-style: auto;
  outline-offset: 4px; }

.minimalLoading {
  display: flex; }
  .minimalLoading span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #898989;
    margin-right: 5px;
    animation: clouds 1.1s infinite ease-in-out; }
    .minimalLoading span:nth-child(2) {
      animation-delay: 0.16s; }
    .minimalLoading span:nth-child(3) {
      animation-delay: 0.32s; }

@keyframes clouds {
  0%,
  80%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.messageContainer {
  width: 100%;
  background-color: #065f1b;
  display: flex;
  text-align: left;
  opacity: 0.95;
  padding: 18px;
  justify-content: center;
  z-index: 17; }

.titleMessage {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px; }

.paragraphMessage {
  color: white;
  font-size: 15px;
  line-height: 20px;
  font-weight: 200; }

.message .message__close img {
  border: solid 2px black;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  filter: brightness(0) invert(1);
  margin-right: 5px; }

.message .message__close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  background: transparent; }
  .message .message__close:focus {
    outline: black;
    outline-style: auto;
    outline-offset: 2px; }

.message p {
  margin: 0px;
  color: white;
  min-width: 104px; }

.message {
  background: none;
  text-align: center;
  padding-top: 15px;
  position: relative;
  z-index: 2;
  padding-left: 20px; }

.closeText {
  min-width: 90px;
  font-size: 15px;
  font-weight: 200; }

.margined {
  max-width: 600px;
  margin-right: 10%; }

.linkElement {
  color: white;
  text-decoration: underline; }

