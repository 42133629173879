@import "../../../../style/index";

.AnswerCurrentQuestion {
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.CurrentAssignmentPage__main__center {
  max-width: 800px;
  width: 100%;
}
@media screen and (max-width: $screen-dimension-tablet) {
  .AnswerCurrentQuestion {
    width: 100%;
  }
}

@media screen and (max-width: $screen-dimension-mobile-425) {
  .AnswerCurrentQuestion {
    margin: 0;
  }
}
